import React from 'react';
import classNames from 'classnames';

import { Link } from '@ets-global/b2c-website-ui';

import Flag from '../../Base/Flag/Flag';
import { paths } from '../../../paths';
import LocaleSetter from './LocaleSetter';
import { useRouter } from '../../../hooks';

const SelectLanguage = ({ country = true, className }) => {
  const { countryIso2 } = useRouter();

  return (
    <div className={classNames('header__top-language', className)}>
      {country && (
        <>
          <div className={'header__top-link'}>
            <Link data-cypress={'header.change-country'} to={paths.COUNTRY}>
              <Flag country={countryIso2.toLowerCase()} />
            </Link>
          </div>

          <span className={'header__top-separator'} />
        </>
      )}
      <LocaleSetter />
    </div>
  );
};

export default React.memo(SelectLanguage, () => true);
