import React from 'react';
import { fromJS, Map } from 'immutable';
import PropTypes from 'prop-types';

import SessionRegistrationSideBarSkeleton from '../../components/SessionRegistration/SideBar/SideBarSkeleton';
import SessionRegistrationSideBar from '../../components/SessionRegistration/SideBar';
import purchaseLinesGrouper from '../../helpers/purchaseLinesGrouper';
import { ARTICLE_FAMILIES } from '../../constants';

const SessionsRegistrationSideBarContainer = ({ session, purchase, productsAndServices, loading }) => {
  if (loading) {
    return <SessionRegistrationSideBarSkeleton />;
  }

  const addProductAndService = (id, quantity, productsAndServices) => {
    if (productsAndServices[id]) {
      quantity += productsAndServices[id].quantity;
    }

    productsAndServices[id] = {
      quantity,
    };
  };

  const getDataFromPurchaseLine = (purchaseLine) => {
    const articleName = purchaseLine.get('articleName'),
      quantity = purchaseLine.get('quantity'),
      price = purchaseLine.get('price'),
      articleFamily = purchaseLine.get('articleFamily'),
      priceValue = price.get('value') * quantity;

    return {
      name: articleFamily === ARTICLE_FAMILIES.TEST_TYPE ? session.get('testTypeFamily').get('name') : articleName,
      quantity,
      articleFamily,
      price: {
        currency: price.get('currency'),
        value: priceValue,
      },
    };
  };

  let candidates = fromJS([]);
  let otherProducts = [];
  let discountProducts = [];

  if (purchase !== null) {
    candidates = fromJS(purchase.candidates);
    const purchaseLines = fromJS(purchase.purchaseLines || []);
    const { otherArticles, discounts } = purchaseLinesGrouper(purchaseLines);

    otherProducts = otherArticles.map((purchaseLine) => getDataFromPurchaseLine(purchaseLine));
    discountProducts = discounts.map((purchaseLine) => getDataFromPurchaseLine(purchaseLine));
  }

  let candidatesProductsAndServices = [];
  candidates.forEach((candidate) => {
    candidate.get('productsAndServices').map((ps) => {
      addProductAndService(ps.get('id'), ps.get('quantity'), candidatesProductsAndServices);
    });
  });

  const includedPS = fromJS(productsAndServices.includedProductsAndServices);
  let cartLines = [];

  cartLines.push({
    name: session.get('testTypeFamily').get('name'),
    quantity: candidates.size,
    price: {
      currency: session.get('price').get('currency'),
      value: session.get('price').get('value'),
      discountedPrice:
        !purchase && Number.isFinite(session.get('price').get('discountedPrice'))
          ? session.get('price').get('discountedPrice')
          : null,
    },
  });

  includedPS.forEach((includedProduct) => {
    cartLines.push({
      name: includedProduct.get('translationKey'),
      quantity: candidates.size,
      price: 'session-registration.products-and-services.certificateInclude.free',
    });
  });

  const allProductsAndServices = productsAndServices.certificateProductsAndServices
    .concat(productsAndServices.correctionProductsAndServices)
    .concat(productsAndServices.discountProductsAndServices);

  for (let key in candidatesProductsAndServices) {
    let ps = allProductsAndServices.filter((elem) => Number(elem.id) === Number(key))[0];
    if (ps) {
      cartLines.push({
        name: ps.translationKey,
        quantity: candidatesProductsAndServices[key].quantity,
        price: {
          currency: ps.price.currency,
          value: ps.price.value * candidatesProductsAndServices[key].quantity,
        },
      });
    }
  }

  cartLines = cartLines.concat([...otherProducts, ...discountProducts]).concat();

  return (
    <SessionRegistrationSideBar
      session={session}
      cartLines={cartLines}
      totalAmount={purchase ? purchase.totalWithTaxes : 0}
    />
  );
};

SessionsRegistrationSideBarContainer.propTypes = {
  session: PropTypes.instanceOf(Map).isRequired,
  purchase: PropTypes.shape({
    purchaseLines: PropTypes.arrayOf(
      PropTypes.shape({
        isDiscount: PropTypes.bool,
        isVoucher: PropTypes.bool,
        unitPrice: PropTypes.number,
        quantity: PropTypes.number,
        currency: PropTypes.string,
        articleName: PropTypes.string,
        articleFamily: PropTypes.string,
        price: PropTypes.shape({
          value: PropTypes.number,
          currency: PropTypes.string,
          tax: PropTypes.number,
          valueTaxFree: PropTypes.number,
        }),
      }),
    ),
  }),
};

export default SessionsRegistrationSideBarContainer;
