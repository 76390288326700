import './styles.scss';

import React from 'react';

export const Desktop = ({ children, ...props }) => (
  <section {...props} className={'responsive-block responsive-block--desktop'}>
    {children}
  </section>
);
export const Tablet = ({ children, ...props }) => (
  <section {...props} className={'responsive-block responsive-block--tablet'}>
    {children}
  </section>
);
export const Mobile = ({ children, ...props }) => (
  <section {...props} className={'responsive-block responsive-block--mobile'}>
    {children}
  </section>
);
