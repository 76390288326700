import React from 'react';
import PropTypes from 'prop-types';

import PrepToolsCarousel from '../../components/PrepToolsCarousel';
import PrepToolsInCart from './index';

const PrepToolsList = ({ prepTools, registrationId, purchase, itemsDisplay, className, purchaseRefetch }) => {
  return (
    <PrepToolsInCart registrationId={registrationId} purchaseRefetch={purchaseRefetch}>
      {({ handleSubmit, isPrepToolInVoucher }) => (
        <PrepToolsCarousel
          prepTools={prepTools}
          handleSubmit={handleSubmit}
          purchase={purchase}
          itemsDisplay={itemsDisplay}
          className={className}
          isPrepToolInVoucher={isPrepToolInVoucher}
        />
      )}
    </PrepToolsInCart>
  );
};

PrepToolsList.propTypes = {
  prepTools: PropTypes.object.isRequired,
  registrationId: PropTypes.string,
  purchase: PropTypes.object,
  itemsDisplay: PropTypes.number,
  className: PropTypes.string,
  purchaseRefetch: PropTypes.func.isRequired,
};

export default PrepToolsList;
