import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { Button } from '@ets-global/b2c-website-ui';
import UploadCandidatePictureBase from './Base';
import { paths } from '../../paths';

const UploadCandidatePictureSuccess = ({ withActions = false }) => {
  const { t } = useTranslation();
  const actions = withActions ? (
    <Button to={paths.HOME}>
      <Trans>upload-candidate-picture.success.link-go-to-site</Trans>
    </Button>
  ) : (
    ''
  );

  return (
    <UploadCandidatePictureBase title={t('upload-candidate-picture.success.title')} actions={actions}>
      <p className={'upload-candidate-picture__success'}>
        <Trans>upload-candidate-picture.success.message</Trans>
      </p>
    </UploadCandidatePictureBase>
  );
};

export default UploadCandidatePictureSuccess;
