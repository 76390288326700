import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { UPLOAD_CANDIDATE_PICTURE } from '../../graphql/mutations';
import HowUploadCandidatePicture from '../../components/HowUploadCandidatePicture';
import UploadCandidatePicture from '../../components/UploadCandidatePicture';
import { useDataLayer, useRouter } from '../../hooks';

const UploadCandidatePictureAndTutorial = ({ sessionId, token, onUploadFinished }) => {
  const { currentRoute } = useRouter();
  const { pushEventPictureAdded } = useDataLayer();
  const [tutorialStep, setTutorialStep] = useState(true);
  const [uploadStep, setUploadStep] = useState(false);
  const [uploadCandidatePicture, { data, loading, error }] = useMutation(UPLOAD_CANDIDATE_PICTURE, {
    onCompleted: (data) => {
      if (
        onUploadFinished &&
        data &&
        data.candidatePictureResult &&
        data.candidatePictureResult.__typename === 'CandidatePicture'
      ) {
        pushEventPictureAdded(currentRoute.url);
        onUploadFinished(data);
      }
    },
  });

  const onTutorialIsTerminate = (isTerminate) => {
    setTutorialStep(!isTerminate);
    setUploadStep(isTerminate);
  };
  const onConfirm = (candidatePicture) =>
    uploadCandidatePicture({
      variables: {
        file: candidatePicture.split(',')[1],
        sessionId,
        token,
      },
    });
  const candidatePictureInvalid =
    data && data.candidatePictureResult && data.candidatePictureResult.__typename === 'CandidatePictureIsInvalid';

  return (
    <>
      {tutorialStep && !uploadStep && <HowUploadCandidatePicture onTutorialIsTerminate={onTutorialIsTerminate} />}
      {!tutorialStep && uploadStep && (
        <>
          {loading ? (
            <p>
              <Trans>upload-candidate-picture.upload.loading-message</Trans>
            </p>
          ) : (
            <UploadCandidatePicture
              onConfirm={onConfirm}
              hasError={!!error || candidatePictureInvalid}
              errors={candidatePictureInvalid ? data.candidatePictureResult.errors : null}
              isUploaded={
                !!data && !!data.candidatePictureResult && data.candidatePictureResult.__typename === 'CandidatePicture'
              }
              withActions={!onUploadFinished}
            />
          )}
        </>
      )}
    </>
  );
};

UploadCandidatePictureAndTutorial.propTypes = {
  sessionId: PropTypes.number.isRequired,
  token: PropTypes.string,
  onUploadFinished: PropTypes.func,
};

export default UploadCandidatePictureAndTutorial;
