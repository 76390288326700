import { gql } from '@apollo/client';

export const APPLY_CODE = gql`
  mutation APPLY_CODE($registrationId: String!, $discount: String!) {
    applyCode(registrationId: $registrationId, discount: $discount) {
      ... on DiscountApplied {
        message
      }
      ... on DiscountCanNotBeApplied {
        reason
        parameters
      }
    }
  }
`;
