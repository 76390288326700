import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import classNames from 'classnames';

import { Icon } from '@ets-global/b2c-website-ui';

import './styles.scss';

import { TEST_TYPE_FAMILY_COLOR, TestTypeFormat, TestTypeProgram } from '../../../constants';
import InjectHtml from '../../InjectHtml';
import { useTranslation } from 'react-i18next';

const TestTypeFamilyTitle = ({
  component: C = 'p',
  color,
  title,
  testTypeProgram,
  testTypeFormat,
  testTypeFormatTitle,
  withIconSeparateLine = false,
  dataCypress,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const programLabel = testTypeProgram === TestTypeProgram.PP ? `public-program` : `institutional-program`;
  const allowedProps = pick(props, Object.keys(TestTypeFamilyTitle._propTypes));

  return (
    <C
      style={{ color: color ? TEST_TYPE_FAMILY_COLOR[color] : null }}
      className={classNames('test-type-family-title', className)}
      data-cypress={dataCypress}
      {...allowedProps}
    >
      <InjectHtml html={title} />
      {withIconSeparateLine ? (
        <p className="test-type-family-title__icons-row">
          <span>
            {testTypeProgram === TestTypeProgram.PP && <Icon name="public-program" />}
            {t(`session-search.side-bar.choice.program.${programLabel}`)}
          </span>
          <span>
            <Icon name={testTypeFormat.toLowerCase()} />
            {testTypeFormatTitle}
          </span>
        </p>
      ) : (
        <>
          {testTypeProgram === TestTypeProgram.PP && <Icon name="public-program" />}
          {testTypeFormat && <Icon name={testTypeFormat === TestTypeFormat.CBT ? 'cbt' : 'pbt'} />}
        </>
      )}
    </C>
  );
};

TestTypeFamilyTitle._propTypes = {
  component: PropTypes.string.isRequired,
  color: PropTypes.string,
  title: PropTypes.string,
  testTypeProgram: PropTypes.oneOf([TestTypeProgram.PP, TestTypeProgram.IP]),
  testTypeFormat: PropTypes.oneOf([TestTypeFormat.PBT, TestTypeFormat.CBT]),
  testTypeFormatTitle: PropTypes.string,
  withIconSeparateLine: PropTypes.bool,
  className: PropTypes.string,
  role: PropTypes.string,
  onClick: PropTypes.func,
  dataCypress: PropTypes.string,
};

TestTypeFamilyTitle.propTypes = TestTypeFamilyTitle._propTypes;

export default TestTypeFamilyTitle;
