import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import { bool } from 'prop-types';
import classNames from 'classnames';

import { Icon } from '@ets-global/b2c-website-ui';

const UploadCandidatePictureUploadInput = ({ errorType, errorSize }) => {
  return (
    <Fragment>
      <p>
        <Trans>upload-candidate-picture.upload.input.message</Trans>
      </p>
      {(errorType || errorSize) && (
        <p className={'photo-upload__dropzone-error'}>
          <Trans>upload-candidate-picture.upload.input.error</Trans>
        </p>
      )}
      <p className={classNames('photo-upload__dropzone-format', { 'photo-upload__dropzone-error': errorType })}>
        <Trans>upload-candidate-picture.upload.input.format-accepted</Trans>
      </p>
      <p className={classNames('photo-upload__dropzone-size', { 'photo-upload__dropzone-error': errorSize })}>
        <Trans>upload-candidate-picture.upload.input.max-size</Trans>
      </p>
      <div className={'photo-upload__dropzone-upload'}>
        <Icon name={'upload'} />
      </div>
    </Fragment>
  );
};

UploadCandidatePictureUploadInput.propTypes = {
  errorType: bool,
  errorSize: bool,
};

UploadCandidatePictureUploadInput.defaultProps = {
  errorType: false,
  errorSize: false,
};

export default UploadCandidatePictureUploadInput;
