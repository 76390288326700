import React from 'react';
import PropTypes from 'prop-types';

const JsonLD = ({ json }) => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{ __html: JSON.stringify({ '@context': 'https://schema.org', ...json }) }}
  />
);

JsonLD.propTypes = {
  json: PropTypes.object,
};

export default JsonLD;
