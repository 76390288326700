import { func, object } from 'prop-types';

import GraphQLErrorsFormatter from './graphQLErrorsFormatter';

const errorsHandler = ({ error, actions, newNotification, t, pushEventFormError }) => {
  if (error.graphQLErrors) {
    const graphQLFormErrors = GraphQLErrorsFormatter(error.graphQLErrors);

    if (Object.keys(graphQLFormErrors.formErrors).length > 0) {
      const errorMessage = Object.values(graphQLFormErrors.formErrors).join('.\n');
      if (pushEventFormError) {
        pushEventFormError(errorMessage);
      }

      if (actions) {
        actions.setErrors(graphQLFormErrors.formErrors);

        return;
      }

      newNotification({
        content: errorMessage,
        type: 'error',
      });

      return;
    }

    if (graphQLFormErrors.exceptions.length > 0) {
      const errorMessage = graphQLFormErrors.exceptions.join('.\n');
      newNotification({
        content: errorMessage,
        type: 'error',
      });
      if (pushEventFormError) {
        pushEventFormError(errorMessage);
      }

      return;
    }
  }

  if (error.message) {
    newNotification({
      content: error.message,
      type: 'error',
    });

    return;
  }

  if (error.__typename !== undefined) {
    if (error.__typename === 'DiscountCanNotBeApplied') {
      if (actions) {
        actions.setErrors({
          discount: t(`purchase-summary.discount.error.${error.reason}`, error.parameters),
        });
      }

      return;
    }

    if (error.__typename === 'PurchaseCanNotBeConfirmed') {
      const messages = error.errors.map((error) => t(`purchase-summary.error.${error.reason}`, error.parameters));

      newNotification({
        content: messages.join(', '),
        type: 'error',
      });

      return;
    }

    if (error.__typename === 'PurchaseCannotBeCreated') {
      const messages = error.errors.map((error) => t(`purchase-summary.error.${error.reason}`));

      newNotification({
        content: messages.join(', '),
        type: 'error',
      });

      return;
    }
  }

  newNotification({
    content: t('common.error-server-message'),
    type: 'error',
  });
};

errorsHandler.prototype = {
  error: object.isRequired,
  newNotification: func.isRequired,
  t: func.isRequired,
  actions: object,
  pushEventFormError: func,
};

export default errorsHandler;
