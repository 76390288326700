import { paths } from '../../../paths';

export default [
  {
    path: paths.ACCOUNT_INCOMING_APPOINTMENTS_SESSIONS,
    label: 'account.incoming-open-sessions.title',
  },
  {
    path: paths.ACCOUNT_PAST_APPOINTMENTS_SESSIONS,
    label: 'account.passed-open-sessions.title',
  },
  {
    path: paths.ACCOUNT_PURCHASES,
    label: 'account.billing.title',
  },
  {
    path: paths.ACCOUNT_ADDRESSES,
    label: 'account.addresses.title',
  },
  {
    path: paths.ACCOUNT_INFORMATION,
    label: 'account.settings.title',
  },
];
