import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

import Button from '@ets-global/b2c-website-ui/Button';
import Link from '@ets-global/b2c-website-ui/Link';

import { Title } from '../Base/Title';
import Subtitle from '../Base/Title/Subtitle';
import { TestimonialBlock } from '../EditorialContent';
import { PrismicRichText, Separator } from '../Base';
import Picture from '../Element/Picture';
import { paths } from '../../paths';

import './styles.scss';

const ProfileBlock = ({ slug, title, marketingHook, description, images = [], testimonials = [] }) => (
  <section className="container">
    <Separator />
    <div className="who-are-you">
      <div className="container">
        <div className="who-are-you__heading">
          <Subtitle modifier={'blue'}>{marketingHook}</Subtitle>
          <Title>{title}</Title>
          {Array.isArray(description) ? (
            <PrismicRichText className={'small-text'} richText={description} />
          ) : (
            <div className={classNames('rte', 'small-text')}>{description}</div>
          )}
          {slug && (
            <div className="wrapper-buttons">
              <Button to={paths.PROFILE} params={{ slug }}>
                <Trans>profile-block.read-more</Trans>
              </Button>
            </div>
          )}
        </div>

        <Picture className="who-are-you__image" images={images} />

        <div className="who-are-you__testimonies">
          {testimonials.map((testimonial, key) => {
            const mobile = testimonial.images.find((image) => image.source === 'MOBILE');
            const image = testimonial.smallImage ? testimonial.smallImage : mobile;

            return (
              <TestimonialBlock
                className={classNames({ 'testimony--small': testimonials.length > 3 })}
                key={key}
                title={testimonial.quote}
                author={testimonial.author}
                image={image}
              />
            );
          })}
        </div>

        <div className="who-are-you__more">
          <Link icon={'arrow'} to={paths.BLOG} search={`editorialContentType=TESTIMONIAL`}>
            <Trans>profile-block.testimonies</Trans>
          </Link>
        </div>
      </div>
    </div>
  </section>
);

ProfileBlock.propType = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  marketingHook: PropTypes.string.isRequired,
  description: PropTypes.oneOf([PropTypes.array, PropTypes.string]).isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
  ),
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      title: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          source: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          alt: PropTypes.string,
        }),
      ),
    }),
  ),
};

export default ProfileBlock;
