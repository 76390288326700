import { GLOBAL_DOCUMENTS } from '../graphql/queries';
import { useRouter } from './index';
import useQuery from './useQuery';

const useGlobalDocuments = () => {
  const { lang, countryEmoIso2 } = useRouter();
  const {
    data: { globalDocuments = {} },
  } = useQuery(GLOBAL_DOCUMENTS, {
    variables: { country: countryEmoIso2, lang },
  });

  return { globalDocuments };
};

export default useGlobalDocuments;
