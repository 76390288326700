import React from 'react';
import { Trans } from 'react-i18next';

import SkeletonItem from '../../SkeletonItem';

const SessionRegistrationSideBarSkeleton = () => {
  return (
    <div className={'checkout-summary'}>
      <div className={'checkout-summary__accordion'}>
        <h2 className={'checkout-summary-layout__title'}>
          <Trans>session-registration.side-bar.title</Trans>
        </h2>
        <p className={'checkout-summary-layout__subtitle checkout-summary-layout__subtitle--no-mobile'}>
          <Trans>session-registration.side-bar.subtitle</Trans>
        </p>
        <div className={'checkout-summary__content'}>
          <SkeletonItem style={{ width: '80%', height: '25px' }} />
          <div className={'checkout-summary__session'} style={{ display: 'flex', flexDirection: 'column' }}>
            <SkeletonItem style={{ width: '50%', height: '22px', marginTop: '16px' }} />

            <SkeletonItem style={{ width: '40%', height: '70px', marginTop: '16px' }} />
          </div>
          <SkeletonItem
            className={'skeleton-item--blue'}
            style={{ width: '100%', height: '136px', marginTop: '50px' }}
          />

          <div className={'checkout-summary__table'}>
            <div className={'checkout-summary__table-row'}>
              <div className={'checkout-summary__table-column checkout-summary__table-column--first'}>
                <SkeletonItem style={{ width: '60%', height: '21px' }} />
              </div>
            </div>
          </div>
          <SkeletonItem className={'checkout-summary__table-footer'} style={{ height: '56px' }} />
        </div>
      </div>
    </div>
  );
};

export default SessionRegistrationSideBarSkeleton;
