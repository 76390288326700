import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

import { Icon } from '@ets-global/b2c-website-ui';

import { GoogleStaticMap } from '../../Google';
import { addressToString } from '../../../helpers/Formatter/StringFormatter';
import { formatTimeToHMM } from '../../../helpers/Formatter/TimeFormatter';
import { NumberByLocale } from '../../Element';
import { useDateToString } from '../../Element/DateToString';
import CheckoutRow from './CheckoutRow';
import TestTypeFamilyTitle from '../../Title/TestTypeFamilyTitle';
import SessionJsonLD from '../../JsonLD/SessionJsonLD';
import { Address } from '../../Base';
import LocationTitle from '../../Base/Title/LocationTitle';

import './styles.scss';
import { TestTypeFormat } from '../../../constants';

const SessionRegistrationSideBar = ({ session, cartLines = [], totalAmount = 0 }) => {
  const DateToString = useDateToString();
  const [checkoutSummaryDisplay, isCheckoutSummaryDisplayed] = useState(false);
  const currentFormatContent =
    session.getIn(['testType', 'format']) === TestTypeFormat.CBT ? 'cbtContent' : 'pbtContent';
  const currentFormatTitle = session
    .getIn(['testTypeFamily', 'testTypes'])
    .find((testType) => testType.get('id') === session.getIn(['testType', 'id']))
    .getIn([`${currentFormatContent}`, 'title']);

  const toggleDisplay = () => {
    isCheckoutSummaryDisplayed(!checkoutSummaryDisplay);
  };

  return (
    <div className={classNames('checkout-summary', { 'checkout-summary--active': checkoutSummaryDisplay })}>
      <SessionJsonLD session={session} />
      <div className="checkout-summary__accordion">
        <button className="checkout-summary__toggler" onClick={toggleDisplay}>
          <h2 className="checkout-summary-layout__title">
            <Trans>session-registration.side-bar.title</Trans>
          </h2>
          <p className="checkout-summary-layout__subtitle checkout-summary-layout__subtitle--no-mobile">
            <Trans>session-registration.side-bar.subtitle</Trans>
          </p>
          <Icon name="arrow" />
        </button>
        <div className="checkout-summary__content">
          <p className="account-session-title">
            <TestTypeFamilyTitle
              color={session.get('testTypeFamily').get('color')}
              component="span"
              title={session.get('testTypeFamily').get('name')}
              testTypeProgram={session.get('testType').get('program')}
              testTypeFormat={session.getIn(['testType', 'format'])}
              testTypeFormatTitle={currentFormatTitle}
              withIconSeparateLine={true}
            />
          </p>
          <div className="checkout-summary__session">
            <h3 className="checkout-summary__intro">
              <Trans>session-registration.side-bar.schedule</Trans>
            </h3>
            <p className="checkout-summary__date">
              <DateToString date={session.get('startAt')} timezone={session.get('timeZone')} format="dddd LL" />
            </p>
            <p className="checkout-summary__date checkout-summary__date--small">
              <Trans>session-registration.side-bar.arrival-time</Trans>&nbsp;
              <strong>
                <DateToString date={session.get('startAt')} timezone={session.get('timeZone')} format="LT" />
              </strong>
            </p>
            <p className="checkout-summary__date checkout-summary__date--small">
              <Trans>session-registration.side-bar.session-start-time</Trans>&nbsp;
              <strong>
                <DateToString date={session.get('scheduledAt')} timezone={session.get('timeZone')} format="LT" />
              </strong>
            </p>
            <p className="checkout-summary__date checkout-summary__date--small">
              <Trans>session-registration.side-bar.exit-time</Trans>&nbsp;
              <strong>
                <DateToString date={session.get('endAt')} timezone={session.get('timeZone')} format="LT" />
              </strong>
            </p>
            <p className="checkout-summary__date checkout-summary__date--small">
              <Trans>session-registration.side-bar.session-duration</Trans>&nbsp;
              <strong>{formatTimeToHMM(session.get('duration') * 60000)}</strong>
            </p>
          </div>
          <div className="checkout-summary__session">
            <h3 className="checkout-summary__intro">
              <Trans>session-registration.side-bar.address</Trans>
            </h3>
            {session.get('testingSite') ? (
              <>
                <Address
                  addressName={session.get('testingSite').get('name')}
                  address={session.get('testingSite').get('address')}
                  wheelchairAccess={session.get('testingSite').get('wheelchairAccess')}
                  hasPin={true}
                />
                <GoogleStaticMap
                  address={addressToString(session.get('testingSite').get('address'))}
                  size={{ width: 370, height: 136 }}
                  className={'checkout-summary__map'}
                />
              </>
            ) : (
              <LocationTitle>
                <Trans>common.online</Trans>
              </LocationTitle>
            )}
          </div>

          <div className="checkout-summary__table">
            {cartLines.map(({ name, quantity, price }, index) => (
              <CheckoutRow name={name} quantity={quantity} price={price} key={index} />
            ))}
          </div>

          <div className="checkout-summary__table-footer">
            <Trans>session-registration.side-bar.cart.total-amount</Trans>&nbsp;
            <span className="checkout-summary__table-price">
              <NumberByLocale currency={session.get('price').get('currency')} value={totalAmount} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

SessionRegistrationSideBar.propTypes = {
  session: PropTypes.object.isRequired,
  cartLines: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      quantity: PropTypes.number,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
};

export default SessionRegistrationSideBar;
