import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const Quote = ({ className, children }) => (
  <blockquote className={classNames('quote', className)}>{children}</blockquote>
);

export default Quote;
