import { Button } from '@ets-global/b2c-website-ui';
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';

import { paths } from '../../paths';
import { AuthContext } from '../../store/Auth';

const Newsletter = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <div className="footer__row footer__row--fourth">
      <Button to={isAuthenticated ? paths.ACCOUNT_INFORMATION : paths.NEWSLETTER}>
        <Trans>footer.newsletter.button</Trans>
      </Button>
    </div>
  );
};

export default Newsletter;
