import React from 'react';
import loadable from '@loadable/component';

import useMarketingBanner from '../../hooks/useMarketingBanner';

const MarketingBanner = loadable(() => import('../../components/MarketingBanner'));

export const marketingBannerPages = {
  purchaseFunnel: 'Prep tool funnel',
  faq: 'FAQ',
  blog: 'Blog',
};

const MarketingBannerContainer = ({ page, documentId, small = false }) => {
  const { banner, isVisible, removeBanner } = useMarketingBanner({ page, documentId });

  if (!isVisible || !banner) {
    return null;
  }

  return <MarketingBanner banner={banner} small={small} onRemoveBanner={removeBanner} />;
};

export default React.memo(MarketingBannerContainer);
