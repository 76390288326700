import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';

import BaseModal from '../../Modal/BaseModal';
import SelectAddressModalItem from './SelectAddressModalItem';

import './styles.scss';

const SelectAddressModal = ({
  addressesList,
  selectedAddress = new Map(),
  isBilling = false,
  openAddressModal,
  isModalDisplayed,
  closeModal,
  modalTitle,
  footer,
  onSelect,
}) => (
  <BaseModal
    className={'select-address'}
    onClose={closeModal}
    isOpen={isModalDisplayed}
    title={modalTitle}
    footer={footer}
  >
    {selectedAddress.size > 0 && (
      <Fragment>
        <p className={'select-address__title'}>
          <Trans>common.addresses.selected-address</Trans>
        </p>
        <SelectAddressModalItem address={selectedAddress} displayName={false} />
      </Fragment>
    )}

    <p className={'select-address__title'}>
      <Trans count={addressesList.size}>common.addresses.your-address</Trans>
    </p>
    {addressesList.map((address, key) => {
      return (
        <SelectAddressModalItem
          key={key}
          address={address}
          isBilling={isBilling}
          onEdit={openAddressModal}
          onSelect={onSelect}
        />
      );
    })}
  </BaseModal>
);

export default SelectAddressModal;
