import React from 'react';
import { Route } from 'react-router-dom';

import Error from '../../components/Error';
import { useRouter } from '../../hooks';

const ErrorPage = () => {
  const {
    currentRoute: {
      params: { statusCode = 404 },
    },
  } = useRouter();

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = statusCode;

        return <Error code={statusCode} />;
      }}
    />
  );
};

export default ErrorPage;
