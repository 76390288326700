import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import usePhoneNumber from './usePhoneNumber';

const useYup = () => {
  const { t } = useTranslation();
  const { isValidNumber } = usePhoneNumber();

  Yup.setLocale({
    mixed: {
      default: t('common.form.validation.string'),
      required: t('common.form.validation.required'),
    },
    string: {
      max: ({ max }) => t('common.form.validation.max', { max }),
      email: t('common.form.validation.email'),
    },
    number: {
      max: ({ max }) => t('common.form.validation.max', { max }),
    },
  });

  Yup.addMethod(Yup.string, 'phoneNumber', function (anyArgsYouNeed) {
    let message;
    if (typeof anyArgsYouNeed === 'string') {
      message = anyArgsYouNeed;
    } else if (typeof anyArgsYouNeed === 'object') {
      message = anyArgsYouNeed.message;
    }

    return this.test('phoneNumber', message ? message : t('common.form.validation.phone-format'), (phoneNumber) =>
      typeof phoneNumber === 'undefined' ? true : isValidNumber(phoneNumber),
    );
  });

  return { Yup };
};

export default useYup;
