import React from 'react';

import NotFoundError from '../../helpers/Errors/NotFoundError';
import { isSsr } from '../../helpers/ssr';
import { paths } from '../../paths';
import { LocalizedRedirect } from '../Routing';

const NotFoundHandler = () => {
  if (isSsr) {
    throw new NotFoundError();
  }

  return <LocalizedRedirect to={paths.NOT_FOUND} />;
};

export default NotFoundHandler;
