import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const BuyingProcess = ({ children, className }) => (
  <main className={classNames('main main--no-offset-top', className)}>{children}</main>
);

export default BuyingProcess;
