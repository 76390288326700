import React, { useReducer, useEffect } from 'react';
import classNames from 'classnames';
import { string, bool, func, node } from 'prop-types';

import './styles.scss';

const reducer = (state, action) => {
  switch (action.type) {
    case 'setContent':
      return { ...state, content: action.content };
    case 'toggleCollapse':
      return { ...state, expanded: !state.expanded };
    default:
      throw new Error('Action type is invalid !');
  }
};

const Collapse = ({ title, titleSmall, fetchContent, content = null }) => {
  const [state, dispatch] = useReducer(reducer, {
    expanded: false,
    content,
  });

  const loadContent = () => {
    if (fetchContent) {
      dispatch({ type: 'setContent', content: fetchContent() });
    } else {
      dispatch({ type: 'setContent', content });
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  useEffect(() => {
    loadContent();
  }, [state.expanded]);

  return (
    <div className={classNames('collapse', { 'collapse--expanded': state.expanded })}>
      <button
        type={'button'}
        className={classNames('collapse__trigger', { 'collapse__trigger--small': titleSmall })}
        onClick={() => dispatch({ type: 'toggleCollapse' })}
      >
        <span />
        {title}
      </button>
      <div className={'collapse__content'}>{state.content}</div>
    </div>
  );
};

Collapse.propTypes = {
  title: string.isRequired,
  titleSmall: bool,
  fetchContent: func,
  content: node,
  onMouseOver: func,
};

Collapse.defaultProps = {
  titleSmall: false,
  content: null,
};

export default Collapse;
