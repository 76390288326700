import React from 'react';
import classNames from 'classnames';
import { instanceOf } from 'prop-types';
import { Map } from 'immutable';
import { Trans } from 'react-i18next';

import { useDateToString } from '../../Element/DateToString';
import Address from '../../Base/Address';
import AccountSessionTitle from '../../Account/Session/Title';
import LocationTitle from '../../Base/Title/LocationTitle';

import './styles.scss';

const SessionDetail = ({ session, className }) => {
  const DateToString = useDateToString();

  return (
    <div className={classNames('session-detail', className)}>
      {session.get('testTypeFamily') && (
        <AccountSessionTitle
          color={session.get('testTypeFamily').get('color')}
          title={session.get('testTypeFamily').get('name')}
          desc={session.get('testTypeFamily').get('shortDescription').toJS()}
          testTypeProgram={session.get('testType').get('program')}
          testTypeFormat={session.getIn(['testType', 'format'])}
        />
      )}
      <div className="session-detail__list">
        <div className="session-detail__item">
          <p className="session-detail__label">{<Trans>order.session.date</Trans>}</p>
          <p>
            <DateToString date={session.get('startAt')} timezone={session.get('timeZone')} format="dddd LL" />
          </p>
        </div>
        <div className="session-detail__item">
          <p className="session-detail__label">{<Trans>order.session.time</Trans>}</p>
          <p>
            <DateToString date={session.get('startAt')} timezone={session.get('timeZone')} format="LT" />
          </p>
        </div>
        <div className="session-detail__item">
          <p className="session-detail__label">{<Trans>order.session.place</Trans>}</p>
          {session.get('testingSite') ? (
            <Address
              addressName={session.get('testingSite').get('name')}
              wheelchairAccess={session.get('testingSite').get('wheelchairAccess')}
              address={session.get('testingSite').get('address')}
            />
          ) : (
            <LocationTitle>
              <Trans>common.online</Trans>
            </LocationTitle>
          )}
        </div>
      </div>
    </div>
  );
};

SessionDetail.propTypes = {
  session: instanceOf(Map).isRequired,
};

export default SessionDetail;
