import React from 'react';
import { number } from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

const TimelineBullets = ({ activeIndex, stepsNumber }) => {
  const bullets = Array.from(Array(stepsNumber), (val, index) => index + 1);

  return (
    <ul className={'timeline-bullets'}>
      {bullets.map((index) => (
        <li
          key={index}
          className={classNames('timeline-bullets__item', {
            'timeline-bullets__item--first': index === 1,
            'timeline-bullets__item--last': index === stepsNumber,
            'timeline-bullets__item--active': index <= activeIndex + 1,
          })}
        />
      ))}
    </ul>
  );
};

TimelineBullets.propTypes = {
  activeIndex: number.isRequired,
  stepsNumber: number.isRequired,
};

export default TimelineBullets;
