import React from 'react';
import classNames from 'classnames';
import { node, string, bool } from 'prop-types';

import { Title } from '../../Element';

import './styles.scss';

const AccountBlock = ({ className, center, intro, title, titleLink, subtitle, children }) => {
  return (
    <section className={classNames('account-block', className, { 'account-block--centered': center })}>
      {intro && <div className={'account-block__intro'}>{intro}</div>}
      {(title || titleLink) && (
        <div className="account-block__header">
          {title && (
            <Title lvl={4} className={'account-block__title'}>
              {title}
            </Title>
          )}
          {titleLink}
        </div>
      )}
      {subtitle && (
        <div className="account-block__header">
          <p className={'account-block__subtitle'}>{subtitle}</p>
        </div>
      )}
      {children}
    </section>
  );
};

AccountBlock.propTypes = {
  center: bool,
  intro: node,
  title: string,
  titleLink: node,
};

AccountBlock.defaultProps = {
  center: false,
};

export default AccountBlock;
