import { gql } from '@apollo/client';

export const SEGMENTS_OPTIONS = gql`
  query SEGMENTS_OPTIONS {
    salesforce {
      segmentsOptions {
        option {
          label
          value
        }
        subSegmentsOptions {
          label
          value
        }
      }
    }
  }
`;

export const YEARS_ACTIVITY_OPTIONS = gql`
  query YEARS_ACTIVITY_OPTIONS {
    salesforce {
      yearsActivityOptions {
        label
        value
      }
    }
  }
`;

export const NB_EMPLOYEE_OPTIONS = gql`
  query NB_EMPLOYEE_OPTIONS {
    salesforce {
      nbEmployeeOptions {
        label
        value
      }
    }
  }
`;

export const NB_STUDENT_OPTIONS = gql`
  query NB_STUDENT_OPTIONS {
    salesforce {
      nbStudentOptions {
        label
        value
      }
    }
  }
`;

export const PRODUCT_OPTIONS = gql`
  query PRODUCT_OPTIONS {
    salesforce {
      productOptions {
        label
        value
      }
    }
  }
`;

export const NB_CANDIDATE_OPTIONS = gql`
  query NB_CANDIDATE_OPTIONS {
    salesforce {
      nbCandidateOptions {
        label
        value
      }
    }
  }
`;
