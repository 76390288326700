import React from 'react';
import PropTypes from 'prop-types';

const As = ({ component: C, children, ...props }) => <C {...props}>{children}</C>;

As.propTypes = {
  component: PropTypes.elementType,
  children: PropTypes.node,
};

export default As;
