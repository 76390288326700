import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useIntersectionObserver } from '../../hooks';
import SkeletonItem from '../SkeletonItem';

const _loaded = {};

const ImgLazyLoad = ({ className, url, alt, skeleton = null }) => {
  const elementRef = useRef(null);

  const [inView] = useIntersectionObserver(elementRef);

  const [loaded, setLoaded] = useState(_loaded[url]);

  useEffect(() => {
    if (inView) {
      if (_loaded[url]) {
        setLoaded(true);

        return;
      }

      const img = new Image();
      img.src = url;
      img.onload = () => {
        _loaded[url] = true;
        setLoaded(true);
      };
    }
  }, [inView]);

  return (
    <div ref={elementRef} className={className ? `${className}-wrapper` : null}>
      {!loaded ? (
        skeleton ? (
          skeleton
        ) : (
          <SkeletonItem className={className} />
        )
      ) : (
        <img src={url} className={className} alt={alt} />
      )}
    </div>
  );
};

ImgLazyLoad.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  skeleton: PropTypes.any,
};

export default React.memo(ImgLazyLoad);
