import React from 'react';

import { Anchor, Icon } from '@ets-global/b2c-website-ui';

import { SOCIAL_NETWORKS } from '../../constants';
import { capitalize } from '../../helpers/Formatter/StringFormatter';

const SocialNetwork = () => {
  return (
    <div className={'header__top-links-share'}>
      {SOCIAL_NETWORKS.map((socialNetwork, key) => (
        <Anchor
          key={key}
          to={socialNetwork.href}
          className={'header__top-link'}
          aria-label={capitalize(socialNetwork.name)}
          target={'_blank'}
        >
          <Icon name={socialNetwork.name} />
        </Anchor>
      ))}
    </div>
  );
};

export default SocialNetwork;
