import React from 'react';

import Banner from './';

const SessionSearchBanner = () => (
  <Banner
    titleTranslationKey={'session-search.banner.title'}
    subtitleTranslationKey={'session-search.banner.subtitle'}
    classes={'banner--small banner--no-mobile'}
    localImagesFolderName={'session-search'}
  />
);

export default SessionSearchBanner;
