import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import loadable from '@loadable/component';

import CarouselItems from './CarouselItems';

const SliderNav = loadable(() => import('./SliderNav'));

import './style.scss';

const Carousel = forwardRef(
  (
    {
      children,
      startingPage = 0,
      onLoadMore = null,
      className,
      itemNumber,
      itemsDisplay = 1,
      itemsToScroll = 1,
      infinityLoop = false,
      maxBullets = 10,
      autoWidth = false,
      itemWidth = null,
      displayNav = true,
      defaultAnimationDuration = 400,
      elementMargin = 25,
      onTouchEnd = () => {},
    },
    ref,
  ) => {
    const [carousel, setCarousel] = useState({
      page: startingPage,
      duration: 0,
    });
    const [selectedBullet, setSelectedBullet] = useState(startingPage);
    const [loading, setLoading] = useState(false);
    const [carouselItemWidth, setCarouselItemWidth] = useState(itemWidth);
    const refCarousel = useRef();
    useImperativeHandle(ref, () => {
      return {
        handleNext,
        switchPage,
        resetCarousel,
      };
    });
    itemNumber = itemNumber || children.size;
    if (itemsToScroll === 0) {
      return '';
    }
    const pageNumber = Math.ceil(itemNumber / itemsToScroll);

    useEffect(() => {
      if (itemsDisplay && itemsDisplay > 0) {
        const handleResize = () => setCarouselItemWidth(refCarousel.current.offsetWidth / itemsDisplay - elementMargin);
        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleResize);
        handleResize();

        return () => {
          window.removeEventListener('resize', handleResize);
          window.removeEventListener('orientationchange', handleResize);
        };
      }
    });

    useEffect(() => {
      setCarousel({
        page: startingPage,
        duration: defaultAnimationDuration,
      });
    }, [startingPage]);

    useEffect(() => {
      if (itemNumber <= itemsDisplay) {
        setCarousel({
          page: 0,
          duration: 0,
        });
      }
    }, [itemsDisplay]);

    const modulo = (x, y) => ((x % y) + y) % y;

    const switchPage = (pageIndex) => {
      if (infinityLoop) {
        if (pageIndex > itemNumber - 1) {
          setCarousel({ page: -1, duration: 0 });
          setTimeout(() => {
            setSelectedBullet(0);
            setCarousel({ page: 0, duration: defaultAnimationDuration });
          }, 10);

          return;
        }
        if (pageIndex < 0) {
          setCarousel({ page: itemNumber, duration: 0 });
          setTimeout(() => {
            setSelectedBullet(itemNumber - 1);
            setCarousel({ page: itemNumber - 1, duration: defaultAnimationDuration });
          }, 10);

          return;
        }
      }

      if (pageIndex === itemNumber) {
        setCarousel({
          page: pageIndex,
          duration: defaultAnimationDuration,
        });
        setSelectedBullet(selectedBullet + (pageIndex - carousel.page));

        return;
      }

      if (loading) {
        return;
      }

      const maxPage = Math.ceil(children.size / itemsToScroll);
      setCarousel({
        page: pageIndex >= maxPage - 1 ? maxPage - 1 : pageIndex,
        duration: defaultAnimationDuration,
      });
      setSelectedBullet(selectedBullet + (pageIndex - carousel.page));

      if (onLoadMore && children.size < itemNumber) {
        if (pageIndex >= maxPage - 2) {
          setLoading(true);
          onLoadMore().then(() => {
            setLoading(false);
          });
        }
      }
    };

    const handleNext = () => {
      if (carousel.page === itemNumber && !infinityLoop) {
        return;
      }

      switchPage(carousel.page + 1);
    };

    const resetCarousel = () => {
      switchPage(maxBullets);
    };

    const handlePrevious = () => {
      if (carousel.page === 0 && !infinityLoop) {
        return;
      }

      switchPage(carousel.page - 1);
    };

    const handleTouchEnd = (index) => {
      if (infinityLoop) {
        index -= numberClonedItems;
        onTouchEnd(modulo(index, itemNumber));

        if (index > itemNumber - 1) {
          setTimeout(() => {
            setSelectedBullet(index - itemNumber);
            setCarousel({ page: index - itemNumber, duration: 0 });
          }, defaultAnimationDuration);

          return;
        }
        if (index < 0) {
          setTimeout(() => {
            setSelectedBullet(itemNumber + index);
            setCarousel({ page: itemNumber + index, duration: 0 });
          }, defaultAnimationDuration);

          return;
        }
      }
      onTouchEnd(index);
      switchPage(index);
    };

    const navSelectedPage = infinityLoop ? modulo(carousel.page, itemNumber) : carousel.page;
    const navSelectedBullet = infinityLoop ? modulo(selectedBullet, itemNumber) : selectedBullet;
    const numberClonedItems = itemsDisplay;

    let index = itemsToScroll * carousel.page;

    if (infinityLoop && itemNumber > itemsDisplay) {
      index += numberClonedItems;
      const cloneBefore = children.slice(-numberClonedItems);
      const cloneAfter = children.slice(0, numberClonedItems);
      cloneBefore.reverse().map((child) => {
        children = children.unshift(child);
      });
      cloneAfter.map((child) => {
        children = children.push(child);
      });
    }

    useEffect(() => {
      if (navSelectedBullet > maxBullets - 1) {
        setTimeout(() => setSelectedBullet(maxBullets - 1), 100);
      } else if (navSelectedBullet < 0) {
        setTimeout(() => setSelectedBullet(0), 100);
      }
    }, [selectedBullet]);

    return (
      <div className={classNames('carousel', className)} ref={refCarousel}>
        {itemNumber > 0 && (
          <CarouselItems
            index={index}
            duration={carousel.duration}
            onTouchEnd={handleTouchEnd}
            loading={loading}
            touchActive={itemNumber > itemsDisplay}
            itemWidth={autoWidth ? carouselItemWidth : null}
            itemsToScroll={itemsToScroll}
          >
            {children}
          </CarouselItems>
        )}
        {displayNav && (
          <SliderNav
            onNext={handleNext}
            onPrevious={handlePrevious}
            setIndex={switchPage}
            maxBullets={maxBullets}
            selectedPage={navSelectedPage}
            selectedBullet={navSelectedBullet}
            pageNumber={pageNumber}
            infinityLoop={infinityLoop}
          />
        )}
      </div>
    );
  },
);

Carousel.prototype = {
  children: PropTypes.array.isRequired,
  startingPage: PropTypes.number,
  itemsToScroll: PropTypes.number,
  itemNumber: PropTypes.number,
  onLoadMore: PropTypes.func,
  className: PropTypes.string,
  itemsDisplay: PropTypes.number,
  infinityLoop: PropTypes.bool,
  maxBullets: PropTypes.number,
  autoWidth: PropTypes.bool,
  displayNav: PropTypes.bool,
  defaultAnimationDuration: PropTypes.bool,
  elementMargin: PropTypes.number,
  onTouchEnd: PropTypes.func,
};

export default React.memo(
  Carousel,
  (
    { children: prevChildren, startingPage: prevStartingPage, itemNumber: prevItemNumber },
    { children: nextChildren, startingPage: nextStartingPage, itemNumber: nextItemNumber },
  ) => {
    return (
      prevChildren &&
      fromJS(prevChildren).equals(fromJS(nextChildren)) &&
      prevStartingPage === nextStartingPage &&
      prevItemNumber === nextItemNumber
    );
  },
);
