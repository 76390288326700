import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fromJS } from 'immutable';

import { Loader } from '@ets-global/b2c-website-ui';

const CarouselItems = ({ children, index, duration, onTouchEnd, loading, itemWidth, itemsToScroll, touchActive }) => {
  const clientX = useRef(0);
  const startX = useRef(0);
  const refItems = useRef(null);

  const getX = (index) => {
    const offsetLeft =
      refItems.current && refItems.current.children[index] ? refItems.current.children[index].offsetLeft : 0;

    return offsetLeft === 0 ? index * itemWidth : refItems.current.children[index].offsetLeft;
  };

  const [transition, setTransition] = useState({
    x: getX(index),
    duration: 0,
  });

  useEffect(() => {
    setTransition({ x: getX(index), duration: duration });
  }, [index, duration, itemWidth]);

  const handleTouchStart = (e) => {
    if (loading || !touchActive) {
      return;
    }

    const touch = e.touches[0];
    clientX.current = touch.clientX;
    startX.current = touch.clientX;
  };

  const handleTouchMove = (e) => {
    if (loading || !touchActive) {
      return;
    }

    if (e.changedTouches && e.changedTouches.length) {
      const touch = e.touches[0];
      const diff = clientX.current - touch.clientX;
      clientX.current = touch.clientX;
      setTransition({ x: transition.x + diff, duration: 0 });
    }
  };

  const handleTouchEnd = () => {
    if (loading || !touchActive) {
      return;
    }

    if (startX.current - clientX.current === 0) {
      return;
    }

    let children = [];
    const limit = 50;
    const vector = startX.current - clientX.current;
    for (let index = 0; index < refItems.current.children.length; index++) {
      children.push(refItems.current.children[index].offsetLeft);
    }
    let pageToScroll = index;

    if (!itemWidth) {
      itemWidth = refItems.current.children[0].offsetWidth;
    }

    if (Math.abs(vector) > itemWidth + limit) {
      const closest = children.reduce((prev, curr) => {
        return Math.abs(curr - transition.x) < Math.abs(prev + 20 - transition.x) ? curr : prev;
      });
      pageToScroll = Math.ceil(children.indexOf(closest) / itemsToScroll);
    } else if (Math.abs(vector) > limit && vector > 0) {
      pageToScroll++;
    } else if (Math.abs(vector) > limit && vector < 0) {
      pageToScroll--;
    }
    pageToScroll = pageToScroll >= children.length ? children.length - 1 : pageToScroll;
    pageToScroll = pageToScroll < 0 ? 0 : pageToScroll;
    if (index === pageToScroll) {
      setTransition({ x: getX(index), duration: 400 });
    } else {
      setTransition({ x: getX(pageToScroll), duration: 400 });
      onTouchEnd(pageToScroll);
    }
  };

  let carouselItemStyle = {};
  if (itemWidth) {
    carouselItemStyle = {
      width: `${itemWidth}px`,
    };
  }

  children = children.map((item, key) => (
    <div
      key={key}
      style={carouselItemStyle}
      className={classNames('carousel__item', { 'carousel__item--selected': key === index })}
    >
      {item}
    </div>
  ));

  const carouselItemsStyle = {
    transitionDuration: transition.duration + 'ms',
    transform: 'translate(-' + transition.x + 'px, 0px)',
  };

  return (
    <div
      className="carousel__items"
      ref={refItems}
      style={carouselItemsStyle}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
    >
      {children}
      {loading && <Loader />}
    </div>
  );
};

CarouselItems.prototype = {
  children: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  onTouchEnd: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  itemWidth: PropTypes.number.isRequired,
  touchActive: PropTypes.bool.isRequired,
};

export default React.memo(
  CarouselItems,
  (
    {
      children: prevChildren,
      index: prevIndex,
      loading: prevLoading,
      itemWidth: prevItemWidth,
      touchActive: prevTouchActive,
    },
    {
      children: nextChildren,
      index: nextIndex,
      loading: nextLoading,
      itemWidth: nextItemWidth,
      touchActive: nextTouchActive,
    },
  ) => {
    return (
      prevChildren &&
      fromJS(prevChildren).equals(fromJS(nextChildren)) &&
      prevIndex === nextIndex &&
      prevLoading === nextLoading &&
      prevItemWidth === nextItemWidth &&
      prevTouchActive === nextTouchActive
    );
  },
);
