import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from '@ets-global/b2c-website-ui';

import { SubTitle } from '../Base/Title';
import BorderedBox from './BorderedBox';

import './styles.scss';

const BorderedBoxesList = ({ marketingHook, borderedBoxes, solutions }) => (
  <section className="container">
    <div className="bordered-boxes-list">
      {marketingHook && <SubTitle modifier={'blue'}>{marketingHook}</SubTitle>}

      {borderedBoxes && borderedBoxes.length > 0 && (
        <div
          className={classNames(
            'bordered-boxes-list__wrapper',
            `bordered-boxes-list__wrapper--size--${borderedBoxes.length}`,
          )}
        >
          {borderedBoxes}
        </div>
      )}

      {solutions && solutions.length > 0 && (
        <div className="bordered-boxes-list__solutions">
          {solutions.map((solution, key) => (
            <div key={key} className="bordered-boxes-list__solutions-item">
              <Link {...solution.link} className="bordered-boxes-list__solutions-title">
                {solution.title}
              </Link>
              <SubTitle modifier={'blue'}>
                <Link className={'bordered-boxes-list__link'} {...solution.link}>
                  {solution.subtitle}
                </Link>
              </SubTitle>
            </div>
          ))}
        </div>
      )}
    </div>
  </section>
);

BorderedBoxesList.propType = {
  marketingHook: PropTypes.string,
  borderedBoxes: PropTypes.arrayOf(BorderedBox),
  solutions: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.object,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
  ),
};

export default BorderedBoxesList;
