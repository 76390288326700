import React from 'react';
import { Trans } from 'react-i18next';

import { Button } from '@ets-global/b2c-website-ui';

import BaseModal from '../../Base/Modal/BaseModal';

const DeleteVoucherPrepToolModal = ({ isInVoucher, onClose, onValidate }) => {
  const buttonsLink = [
    {
      label: <Trans>common.cancel</Trans>,
      className: 'button--transparent-light',
      onClick: () => onClose(),
    },
    {
      label: <Trans>account.billing.order.remove-line.delete</Trans>,
      onClick: () => onValidate(),
    },
  ].map((button, key) => {
    const { label } = button;

    return (
      <Button key={key} {...button}>
        {label}
      </Button>
    );
  });

  return (
    <BaseModal
      isOpen
      title={<Trans>account.billing.order.remove-line.title</Trans>}
      onClose={onClose}
      footer={buttonsLink}
    >
      <Trans>
        {isInVoucher
          ? 'account.billing.order.remove-line-with-voucher.question'
          : 'account.billing.order.remove-line.question'}
      </Trans>
    </BaseModal>
  );
};

export default DeleteVoucherPrepToolModal;
