import { useTranslation } from 'react-i18next';

import { formatDate } from '../../helpers/Formatter/DateFormatter';

export const useDateToString = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return ({ date, format, timezone }) => formatDate({ date, format, language, timezone });
};
