import React, { useState } from 'react';
import classNames from 'classnames';
import { Trans } from 'react-i18next';

import { Link } from '@ets-global/b2c-website-ui';

import { useAuth, useRouter } from '../../../hooks';
import links from './links';

import './styles.scss';

const AccountMenu = () => {
  const { logout } = useAuth();
  const { currentRoute } = useRouter();
  const [isLoggingOut, setLoggingOut] = useState(false);

  const doLogout = () => {
    setLoggingOut(true);

    return logout();
  };

  return (
    <nav id={'account-menu'} className={'account-menu'}>
      {links.map((item, key) => {
        return (
          <Link
            key={key}
            to={item.path}
            className={classNames('account-menu__item', {
              'account-menu__item--active': currentRoute.path === item.path,
            })}
          >
            <span>
              <Trans>{item.label}</Trans>
            </span>
          </Link>
        );
      })}
      <Link onClick={doLogout} loading={isLoggingOut} className={'account-menu__item'} data-cypress="main-logout-link">
        <span>
          <Trans>account.logout.title</Trans>
        </span>
      </Link>
    </nav>
  );
};

export default AccountMenu;
