const GraphQLErrorsFormatter = (errors) => {
  const formattedErrors = {
    formErrors: {},
    exceptions: [],
  };

  if (
    errors === undefined ||
    errors[0] === undefined ||
    errors[0].extensions === undefined ||
    errors[0].extensions.response === undefined ||
    errors[0].extensions.response.body === undefined
  ) {
    return formattedErrors;
  }

  const errorResponseBody = errors[0].extensions.response.body;

  // General form error
  if (typeof errorResponseBody === 'string') {
    formattedErrors.formErrors.form = errorResponseBody;

    return formattedErrors;
  }

  if (errorResponseBody[0] && typeof errorResponseBody[0] === 'string') {
    formattedErrors.formErrors.form = errorResponseBody[0];

    return formattedErrors;
  }

  if (typeof errorResponseBody !== 'object' || 400 > errors[0].extensions.response.status) {
    return formattedErrors;
  }

  // Specific field error v1
  if (errorResponseBody.errors && errorResponseBody.errors.children) {
    Object.keys(errorResponseBody.errors.children).forEach((field) => {
      if (errorResponseBody.errors.children[field].errors) {
        formattedErrors.formErrors[field] = errorResponseBody.errors.children[field].errors[0];
      }
    });

    return formattedErrors;
  }

  // Specific field error v2
  if (!errorResponseBody.error) {
    Object.keys(errorResponseBody).forEach((field) => {
      if (typeof errorResponseBody[field] === 'object') {
        formattedErrors.formErrors[field] = [];
        Object.keys(errorResponseBody[field]).forEach((errors) => {
          formattedErrors.formErrors[field].push(errorResponseBody[field][errors]);
        });
      } else {
        formattedErrors.formErrors[field] = errorResponseBody[field];
      }
    });

    return formattedErrors;
  }

  // General Exception
  if (errorResponseBody.error) {
    if (errorResponseBody.error.exception) {
      formattedErrors.exceptions = errorResponseBody.error.exception.map((exception) => {
        return exception.message;
      });

      return formattedErrors;
    }

    if (errorResponseBody.error.message) {
      formattedErrors.exceptions = [errorResponseBody.error.message];

      return formattedErrors;
    }
  }

  return formattedErrors;
};

export default GraphQLErrorsFormatter;
