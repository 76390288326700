import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import { NumberByLocale } from '../../../Element';
import { translateProductNameCallback } from '../../../../helpers/i18n';

const CheckoutRow = ({ name, quantity, price }) => {
  const { i18n, t } = useTranslation();
  const translateProductName = translateProductNameCallback(i18n, t);

  return (
    <div className="checkout-summary__table-row">
      <div className="checkout-summary__table-column checkout-summary__table-column--first">
        {translateProductName(name)}
      </div>
      <div className="checkout-summary__table-column">{quantity}</div>
      <div className="checkout-summary__table-column checkout-summary__table-column--last">
        {Number.isFinite(price.discountedPrice) ? (
          <>
            <NumberByLocale currency={price.currency} value={price.discountedPrice} />
            <span className="checkout-summary__table-column-price checkout-summary__table-column-price--discounted-price">
              {renderPrice(price)}
            </span>
          </>
        ) : (
          <span className="checkout-summary__table-column-price">{renderPrice(price)}</span>
        )}
      </div>
    </div>
  );
};

function renderPrice(price) {
  return typeof price === 'object' ? (
    <NumberByLocale currency={price.currency} value={price.value} />
  ) : (
    <Trans>{price}</Trans>
  );
}

CheckoutRow.propTypes = {
  name: PropTypes.string,
  quantity: PropTypes.number,
  price: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      currency: PropTypes.string,
      value: PropTypes.number,
      discountedPrice: PropTypes.number,
    }),
  ]),
};

export default CheckoutRow;
