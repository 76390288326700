import React from 'react';
import { fromJS } from 'immutable';

import TopicItem from './TopicItem';

const TopicList = ({ layout }) => {
  let topics = [];

  if (layout?.testsBlock) {
    topics.push({
      title: layout.testsBlock.title,
      links: layout.testsBlock.blocks.map((block) => block.link),
    });
  }

  if (layout?.youBlock) {
    topics.push({
      title: layout.youBlock.title,
      links: layout.youBlock.blocks.map((block) => block.link),
    });
  }

  if (layout?.whoBlock) {
    topics.push({
      title: layout.whoBlock.title,
      links: layout.whoBlock.links,
    });
  }

  if (topics.length === 0) {
    return;
  }

  return (
    <div className="footer__column footer__links">
      {fromJS(topics).map((topic, key) => (
        <TopicItem key={key} topic={topic} />
      ))}
    </div>
  );
};

export default TopicList;
