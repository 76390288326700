import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Quote from '../../components/Base/Quote';
import Subtitle from '../Base/Title/Subtitle';
import { Mobile, Desktop, Tablet } from '../Responsive';

import './styles.scss';

const QuoteBanner = ({ type, quote, author, images, borderColor, withBorder = false, className }) => {
  const content = (
    <Fragment>
      <div className="quote-banner__overlay" style={{ backgroundColor: borderColor }} />
      <div className="quote-banner__inner">
        <div className="container container--padding">
          <Subtitle className="quote-banner__type">{type}</Subtitle>
          <Quote className="quote-banner__quote">{quote}</Quote>
          <p className="quote-banner__author">{author}</p>
        </div>
      </div>
    </Fragment>
  );

  return images.map((image, key) => {
    let MediaQuery = Mobile;
    if (image.source === 'DESKTOP') {
      MediaQuery = Desktop;
    } else if (image.source === 'TABLET') {
      MediaQuery = Tablet;
    }

    return (
      <MediaQuery key={key}>
        <section
          className={classNames(
            'quote-banner',
            {
              'quote-banner--bordered': withBorder,
            },
            className,
          )}
          style={{
            backgroundImage: `url(${image.url})`,
            borderColor: borderColor,
          }}
        >
          {content}
        </section>
      </MediaQuery>
    );
  });
};

QuoteBanner.propType = {
  type: PropTypes.string,
  quote: PropTypes.string,
  author: PropTypes.string,
  images: PropTypes.array.isRequired,
  borderColor: PropTypes.string,
  withBorder: PropTypes.bool,
  className: PropTypes.string,
};

export default QuoteBanner;
