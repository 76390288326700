import React from 'react';

import BaseModal from '../../Base/Modal/BaseModal';
import PrepTool from '../index';

const PrepToolDetailModal = ({ onClose, item, purchaseLine, onSubmit, isEditable, isDeletable, isInVoucher }) => (
  <BaseModal isOpen onClose={onClose} className={'modal__container--large'}>
    <PrepTool
      className={'product--inline product--with-detail'}
      item={item}
      crossSelling={true}
      withDetail={true}
      purchaseLine={purchaseLine}
      onSubmit={onSubmit}
      isEditable={isEditable}
      isDeletable={isDeletable}
      isInVoucher={isInVoucher}
    />
  </BaseModal>
);

export default PrepToolDetailModal;
