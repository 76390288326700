import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Picture from '../../Element/Picture';
import InjectHtml from '../../InjectHtml';
import PrismicRichText from '../PrismicRichText';

import './styles.scss';

const Banner = ({
  title,
  subtitle,
  titleTranslationKey,
  subtitleTranslationKey,
  baseline,
  images = [{ source: 'MOBILE' }, { source: 'DESKTOP' }, { source: 'TABLET' }],
  borderColor,
  localImagesFolderName,
  classes,
  gradient = false,
  isTestLandingPage = false,
}) => {
  const { t } = useTranslation();

  let backgrounds = [];
  if (localImagesFolderName) {
    backgrounds = images.map((image) => {
      return {
        source: image.source,
        url: require(`../../../assets/images/banner/${localImagesFolderName}/${image.source.toLowerCase()}.jpg`),
        alt: localImagesFolderName,
      };
    });
  } else {
    backgrounds = images;
  }

  return (
    <section
      className={classNames('banner', classes, {
        'banner--with-border': borderColor,
        'banner--from-local': localImagesFolderName,
        'banner--test-landing-page': isTestLandingPage,
      })}
      style={{ borderColor }}
    >
      {isTestLandingPage && (
        <img className="banner__logo" src={require('../../../assets/images/ETSToeic_logo.svg')} alt={'ETS TOEIC'} />
      )}
      <div className="container container--padding">
        <div className="banner__inner">
          {(subtitle || subtitleTranslationKey) && (
            // InjectHtml and Subtitle or HTMLTitle generate bug
            <InjectHtml
              component={'p'}
              className={'banner__subhead'}
              html={subtitleTranslationKey ? t(subtitleTranslationKey) : subtitle}
            />
          )}
          {(title || titleTranslationKey) && (
            <InjectHtml
              component={'h1'}
              className={'title banner__title'}
              html={titleTranslationKey ? t(titleTranslationKey) : title}
            />
          )}
          {baseline &&
            (typeof baseline === 'string' ? (
              <p className={'banner__subtitle'}>{baseline}</p>
            ) : (
              <PrismicRichText className="banner__subtitle" richText={baseline} />
            ))}
        </div>
      </div>
      <div className={'banner__background'}>
        {gradient && <div className={'banner__background-gradient'} />}
        <Picture images={backgrounds} className={'banner__background-image'} />
      </div>
    </section>
  );
};

Banner.propType = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  titleTranslationKey: PropTypes.string,
  subtitleTranslationKey: PropTypes.string,
  baseline: PropTypes.string,
  titleLevel: PropTypes.number.isRequired,
  borderColor: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape({ source: PropTypes.oneOf(['DESKTOP', 'TABLET', 'MOBILE']) })),
  localImagesFolderName: PropTypes.string,
  classes: PropTypes.string,
  italicTitle: PropTypes.bool,
  gradient: PropTypes.bool,
  isTestLandingPage: PropTypes.bool,
};

export default Banner;
