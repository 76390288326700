import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useDateToString } from '../Element/DateToString';
import { addressToString } from '../../helpers/Formatter/StringFormatter';
import { paths } from '../../paths';
import { useRouter } from '../../hooks';
import JsonLD from './index';
import { Map } from 'immutable';
import { checkIsEtsGlobal } from '../../helpers/checkIsEtsGlobal';

const SessionJsonLD = ({ session }) => {
  const DateToString = useDateToString();
  const { generateUrl } = useRouter();
  const { t } = useTranslation();
  const today = new Date();
  let jsonLD = {
    '@type': 'Event',
    name: session.get('testTypeFamily') ? session.get('testTypeFamily').get('name') : '',
    startDate: DateToString({ date: session.get('scheduledAt'), timezone: session.get('timeZone') }),
    endDate: DateToString({ date: session.get('endAt'), timezone: session.get('timeZone') }),
    image: [require('../../assets/images/banner/session-search/mobile.jpg')],
    offers: {
      '@type': 'Offer',
      url: generateUrl(paths.SESSION_REGISTER, { sessionId: session.get('id') }),
      price: session.get('price').get('value'),
      priceCurrency: session.get('price').get('currency'),
      availability: 'https://schema.org/InStock',
      validFrom: new Date(today.getFullYear() + 1, today.getMonth(), today.getDate()),
    },
    organizer: {
      '@type': 'Organization',
      name: session.get('businessName'),
      url: checkIsEtsGlobal(session.get('businessName')) ? generateUrl(paths.HOME) : '',
    },
  };

  if (session.get('testingSite')) {
    jsonLD = {
      ...jsonLD,
      eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
      eventStatus: 'https://schema.org/EventScheduled',
      location: {
        '@type': 'Place',
        name: session.get('testingSite').get('name'),
        address: addressToString(session.get('testingSite').get('address')),
      },
      description: t('json-ld.description.session-search-item', {
        testTypeFamilyName: session.get('testTypeFamily') ? session.get('testTypeFamily').get('name') : '',
        city: session.get('testingSite').get('address').get('city'),
        date: DateToString({ date: session.get('scheduledAt'), format: 'dddd LL', timezone: session.get('timeZone') }),
      }),
    };
  } else {
    jsonLD = {
      ...jsonLD,
      eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
      eventStatus: 'https://schema.org/EventMovedOnline',
      description: t('json-ld.description.session-search-item-online', {
        testTypeFamilyName: session.get('testTypeFamily') ? session.get('testTypeFamily').get('name') : '',
        date: DateToString({ date: session.get('scheduledAt'), format: 'dddd LL', timezone: session.get('timeZone') }),
      }),
    };
  }

  return <JsonLD json={jsonLD} />;
};

SessionJsonLD.propTypes = {
  session: PropTypes.instanceOf(Map).isRequired,
};

export default SessionJsonLD;
