import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

import { SmallTitle } from '../../Base/Title';
import { TinyText } from '../../Base/Text';

import './styles.scss';

const FormLayout = ({ title, subtitle, children, className }) => (
  <main className={classnames('main', className)}>
    <div className={'form-layout'}>
      <div className={'container container--full'}>
        <div className={'container container--small container--padding'}>
          <SmallTitle lvl={1}>{title}</SmallTitle>
          <TinyText>{subtitle}</TinyText>
        </div>
      </div>
      <div className={'container container--full container--blue'}>
        <div className={'container container--small container--padding'}>{children}</div>
      </div>
    </div>
  </main>
);

FormLayout.propTypes = {
  title: string,
  subtitle: string,
};

export default FormLayout;
