import React from 'react';
import { Trans } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { fromJS } from 'immutable';
import classNames from 'classnames';

import { Anchor } from '@ets-global/b2c-website-ui';

import { paths } from '../../paths';
import { List } from '../Element';
import TopicList from './TopicList';
import Newsletter from './Newsletter';
import SessionSearchBlock from './SessionSearchBlock';
import SocialNetwork from './SocialNetwork';
import LocaleSetter from '../Header/SelectLanguage/LocaleSetter';
import Logo from '../Base/Logo/Logo';
import MeetUs from './MeetUs';
import JsonLD from '../JsonLD';
import { isBrowser } from '../../helpers/ssr';
import { useRouter } from '../../hooks';
import useGlobalDocuments from '../../hooks/useGlobalDocuments';

import './styles.scss';

const Footer = ({ layout }) => {
  const { generateUrl, currentRoute } = useRouter();
  const { globalDocuments } = useGlobalDocuments();
  const [cookies] = useCookies(['CookieConsent']);
  const isTestLandingPage = [paths.LANDING_PAGE_TOEIC_IN_AUTONOMY, paths.LANDING_PAGE_REMOTE_PROCTORED_TEST].includes(
    currentRoute.path,
  );

  let links = [
    {
      label: 'footer.bottom.nav.legal_notice',
      to: paths.LEGAL_INFORMATION,
    },
  ];

  if (!isTestLandingPage) {
    links.unshift(
      {
        label: 'footer.bottom.nav.blog',
        to: paths.BLOG,
      },
      {
        label: 'footer.bottom.nav.help_center',
        to: paths.HELP_CENTER,
      },
    );
  }

  if (globalDocuments && globalDocuments.termsAndConditions) {
    links.push({
      label: 'footer.bottom.nav.terms_and_conditions',
      to: globalDocuments.termsAndConditions,
    });
  }

  if (isBrowser && cookies.CookieConsent) {
    links.push({
      isCookieConsent: true,
      label: 'footer.bottom.nav.edit_cookies_settings',
      className: 'manage-consent',
    });
  }

  links = fromJS(links).map((link, key) => {
    const { label, ...linkProps } = link.toJS();

    if (linkProps.isCookieConsent) {
      return (
        <button className={classNames('footer__menu-item-link', linkProps.className)}>
          <Trans>{label}</Trans>
        </button>
      );
    }

    return (
      <Anchor key={key} className={'footer__menu-item-link'} {...linkProps}>
        <Trans>{label}</Trans>
      </Anchor>
    );
  });

  return (
    <footer id={'footer'} className={classNames('footer', { 'footer--test-landing-page': isTestLandingPage })}>
      {layout?.meetUsBlock?.phone && (
        <JsonLD
          json={{
            '@type': 'Organization',
            url: generateUrl(paths.HOME),
            logo: require('../../assets/images/ETSGlobal_logo.png'),
            contactPoint: {
              '@type': 'ContactPoint',
              telephone: layout.meetUsBlock.phone,
              contactType: 'customer service',
            },
            sameAs: [
              'https://x.com/ETS_EMEA',
              'https://www.linkedin.com/company/ets-global/',
              'https://www.facebook.com/ETSEMEA',
            ],
          }}
        />
      )}
      <div className={'container'}>
        {layout && (
          <div className={'footer__column-wrapper'}>
            <TopicList layout={layout} />
            <div className={'footer__column'}>
              <SessionSearchBlock />
              <Newsletter />
              <SocialNetwork />
              {layout?.meetUsBlock && <MeetUs meetUsData={fromJS(layout.meetUsBlock)} />}
            </div>
          </div>
        )}
        {isTestLandingPage && (
          <React.Fragment>
            <Logo className="header__logo logo--white" />
            <LocaleSetter className="select-language--test-landing-page" />
          </React.Fragment>
        )}

        <nav>
          <List
            items={links}
            listClassNames={classNames('footer__menu', { 'footer__menu--test-landing-page': isTestLandingPage })}
            itemClassNames={'footer__menu-item'}
          />
          <p className={'footer__copyright'}>
            <Trans>footer.bottom.copyright</Trans>
          </p>
        </nav>
      </div>
    </footer>
  );
};

export default React.memo(Footer, ({ layout: prevLayout }, { layout: nextLayout }) =>
  prevLayout && nextLayout ? fromJS(prevLayout).equals(fromJS(nextLayout)) : true,
);
