import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from '@ets-global/b2c-website-ui';
import InjectHtml from '../../InjectHtml';

import './styles.scss';

const BorderedBox = ({ small = false, animated = false, title, color, linkTo, children }) => {
  return (
    <div
      className={classNames('bordered-box', small && 'bordered-box--small', animated && 'bordered-box--animated')}
      style={{
        borderColor: color,
      }}
    >
      {linkTo ? (
        <Fragment>
          <Link to={linkTo}>
            <InjectHtml className="bordered-box__title" style={{ color }} component={'h2'} html={title} />
          </Link>
          <Link className="bordered-box__content" to={linkTo}>
            {children}
          </Link>
        </Fragment>
      ) : (
        <Fragment>
          <InjectHtml component={'h2'} className="bordered-box__title" style={{ color }} html={title} />
          <div className="bordered-box__content">{children}</div>
        </Fragment>
      )}
    </div>
  );
};

BorderedBox.propType = {
  small: PropTypes.bool,
  animated: PropTypes.bool,
  title: PropTypes.string,
  color: PropTypes.string,
  linkTo: PropTypes.string,
  children: PropTypes.any,
};

export default BorderedBox;
