import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';

import { Fields, Button } from '@ets-global/b2c-website-ui';

import { APPLY_CODE } from '../../../graphql/mutations/applyCode';
import errorsHandler from '../../../helpers/errorsHandler';
import { useNotification } from '../../../hooks';
import { useDateToString } from '../../../components/Element/DateToString';

const ApplyCode = ({ registrationId, purchaseRefetch }) => {
  const { t } = useTranslation();
  const { newNotification } = useNotification();
  const DateToString = useDateToString();
  const [applyCode] = useMutation(APPLY_CODE);

  const onSubmit = async (data, actions) => {
    try {
      const response = await applyCode({
        variables: {
          registrationId: registrationId,
          discount: data.discount,
        },
      });

      const error = { ...response.data.applyCode };
      if (error.__typename === 'DiscountCanNotBeApplied') {
        if (error.parameters.purchasedAt !== undefined) {
          error.parameters.purchasedAt = DateToString({ date: error.parameters.purchasedAt, format: 'PPPp' });
        }
        if (error.parameters.voucherEndDate !== undefined) {
          error.parameters.voucherEndDate = DateToString({ date: error.parameters.voucherEndDate, format: 'PPPp' });
        }

        errorsHandler({ error, actions, newNotification, t });
      } else {
        purchaseRefetch();
        newNotification({
          content: t('session-summary.promo-form.success-message'),
          type: 'success',
        });
      }
    } catch (error) {
      errorsHandler({ error, actions, newNotification, t });
    }

    actions.setSubmitting(false);
  };

  return (
    <div className="order-table__promo-form">
      <p>
        <Trans>session-summary.paragraph.code.if-you-have-a</Trans>
        <strong>
          <Trans>session-summary.paragraph.code.promo-code</Trans>
        </strong>
        <Trans>session-summary.paragraph.code.or-one</Trans>
        <strong>
          <Trans>session-summary.paragraph.code.voucher-code</Trans>
        </strong>
        <Trans>session-summary.paragraph.code.enter-the-code-here</Trans> :
      </p>

      <Formik
        initialValues={{ discount: '' }}
        schema={Yup.object().shape({ discount: Yup.string() })}
        onSubmit={onSubmit}
        render={({ isSubmitting }) => {
          return (
            <Form>
              <Fields.FormikFastField
                id="discount"
                name="discount"
                component={Fields.TextField}
                label={t('session-summary.input.code.label')}
              />
              <Button type={'submit'} className={'button--full-width'} disabled={isSubmitting}>
                <Trans>session-summary.code.button.apply</Trans>
              </Button>
            </Form>
          );
        }}
      />
    </div>
  );
};

ApplyCode.propTypes = {
  registrationId: PropTypes.string.isRequired,
  purchaseRefetch: PropTypes.func.isRequired,
};

export default ApplyCode;
