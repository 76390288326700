import React from 'react';
import classNames from 'classnames';
import { Trans } from 'react-i18next';

import { Link } from '@ets-global/b2c-website-ui';

import ImgLazyLoad from '../../ImgLazyLoad';
import Subtitle from '../../Base/Title/Subtitle';
import { paths } from '../../../paths';

import './caseStudy.scss';

const CaseStudyBlock = ({ slug, title, image, className }) => (
  <div className={classNames('case-study', className)}>
    {image && <ImgLazyLoad className="case-study__image" url={image.url} />}

    <div className="case-study__content">
      <Subtitle className="case-study__type" modifier={'blue'}>
        <Trans>blog.editorial-content.category.case_study</Trans>
      </Subtitle>
      <p className="case-study__title">
        <Link to={paths.BLOG_ARTICLE} params={{ editorialContentType: 'case-study', slug }}>
          {title}
        </Link>
      </p>
    </div>
  </div>
);

export default CaseStudyBlock;
