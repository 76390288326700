import React, { Fragment, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Link, Price } from '@ets-global/b2c-website-ui';

import ModalContext from '../../store/Modal/ModalContext';
import Tooltip from '../../components/Base/Tooltip/Tooltip';
import { PrismicRichText, Tag } from '../Base';
import BuyingModal from './BuyingModal';
import PrepToolDetailModal from './PrepToolDetailModal';
import JsonLD from '../JsonLD';
import DeleteVoucherPrepToolModal from './DeleteVoucherPrepToolModal';
import InjectHtml from '../InjectHtml';
import { paths } from '../../paths';
import { useDataLayer, useRouter } from '../../hooks';

import './styles.scss';

const PrepTool = ({
  className,
  purchaseLine = null,
  crossSelling,
  item,
  isEditable = true,
  isDeletable = true,
  withDetail = false,
  isInVoucher = false,
  onSubmit = () => {},
}) => {
  const { t } = useTranslation();
  const { showModal, hideModal } = useContext(ModalContext);
  const { generatePath, generateUrl } = useRouter();
  const { pushEventShowInfoPrepTools } = useDataLayer();
  let image = null;
  const [nbItemInBasket, setNbItemsInBasket] = useState(0);

  useEffect(() => {
    setNbItemsInBasket(purchaseLine ? purchaseLine.quantity : 0);
  }, [purchaseLine]);

  if (item.relatedFamily.type === 'Book' && item.image.book && item.image.book.url) {
    image = item.image.book;
  } else if (item.relatedFamily.type === 'Online preparation tool' && item.image && item.image.url) {
    image = item.image;
  }

  const levelsData = {
    'High beginner': {
      class: 'tag--light-blue',
      value: 'prep-tool.level.high-beginner',
      abbr: 'prep-tool.level.high-beginner_abbr',
    },
    Intermediaire: {
      class: 'tag--blue',
      value: 'prep-tool.level.intermediate',
      abbr: 'prep-tool.level.intermediate_abbr',
    },
    Advanced: {
      class: 'tag--dark-blue',
      value: 'prep-tool.level.advanced',
      abbr: 'prep-tool.level.advanced_abbr',
    },
  };

  let tag = '';
  if (item.levels.length > 0) {
    tag = item.levels.map((level, key) => (
      <Tag className={levelsData[level].class} key={key}>
        <Tooltip position={'top-right'} width={'300px'} label={t(levelsData[level].abbr)}>
          <Trans>{levelsData[level].value}</Trans>
        </Tooltip>
      </Tag>
    ));
  }

  const showDetailModal = (event) => {
    event.preventDefault();
    showModal(PrepToolDetailModal, {
      onClose: hideModal,
      item,
      purchaseLine,
      onSubmit,
      isEditable,
      isDeletable,
      isInVoucher,
    });
    pushEventShowInfoPrepTools({
      productId: item.articleStockId,
      productName: item.name,
    });
  };

  const today = new Date();

  let json = {
    '@type': 'Product',
    description: item.meta.description,
    brand: {
      '@type': 'Thing',
      name: item.author,
    },
    sku: item.articleStockId,
  };

  if (item.relatedFamily.type === 'Book') {
    json = {
      '@type': 'Book',
      bookFormat: 'http://schema.org/Hardcover',
      author: {
        '@type': 'Person',
        name: item.author,
      },
      isbn: item.isbn,
    };
  }

  json = {
    ...json,
    name: item.meta.title,
    image: item.meta.image ? item.meta.image.url : '',
    offers: {
      '@type': 'Offer',
      priceCurrency: item.price.currency,
      price: item.price.value,
      priceValidUntil: new Date(today.getFullYear() + 1, today.getMonth(), today.getDate()),
      availability: 'https://schema.org/InStock',
      seller: {
        '@type': 'Organization',
        name: 'ETS Global',
      },
    },
  };

  let link = `#${item.articleStockId}`;
  if (item.slug) {
    link = generatePath(paths.PREP_TOOL, {
      slug: item.slug,
    });
    json.offers.url = generateUrl(paths.PREP_TOOL, {
      slug: item.slug,
    });
  }

  const imageDisplay = (
    <>
      <div className="product__image-element-wrapper">
        <img src={image.url} alt={image.alt} className="product__image-element" />
      </div>
      {nbItemInBasket > 0 && (
        <div className="product__quantity">
          <span className="product__quantity-number">
            <Trans
              count={nbItemInBasket}
              values={{
                number: nbItemInBasket,
              }}
            >
              {item.relatedFamily.type === 'Book'
                ? 'prep-tool.quantity-number.book'
                : 'prep-tool.quantity-number.online'}
            </Trans>
          </span>
          <span className="product__quantity-order">
            <Trans>prep-tool.quantity-order</Trans>
          </span>
        </div>
      )}
    </>
  );

  return (
    <Fragment>
      <JsonLD json={json} />
      <div
        className={classNames('product', className, {
          'product--in-basket': nbItemInBasket > 0,
          'product--large': item.relatedFamily.type !== 'Book',
        })}
      >
        <div className={classNames('product__inner', { 'product__inner--left': item.relatedFamily.type === 'Book' })}>
          {!withDetail ? (
            <Link
              onClick={showDetailModal}
              to={link}
              className={classNames('product__image', { 'product__image--small': item.relatedFamily.type === 'Book' })}
            >
              {imageDisplay}
            </Link>
          ) : (
            <div
              className={classNames('product__image', { 'product__image--small': item.relatedFamily.type === 'Book' })}
            >
              {imageDisplay}
            </div>
          )}

          <div className="product__content">
            {!withDetail ? (
              <Link to={link} onClick={showDetailModal}>
                <InjectHtml component="h3" className="product__title" html={item.name} />
              </Link>
            ) : (
              <InjectHtml component="h3" className="product__title" html={item.name} />
            )}
            <div className="product__infos">
              {!tag && item.levelAlternative && <p className="product__edition">{item.levelAlternative}</p>}
              {crossSelling && tag && <div className="product__tags">{tag}</div>}
            </div>
            {!withDetail && (
              <Fragment>
                <PrismicRichText className="product__desc" richText={item.shortDescription} />
                <div className="product__know-more">
                  <Link icon={'arrow'} to={link} onClick={showDetailModal}>
                    <Trans>prep-tool.more-details</Trans>
                  </Link>
                </div>
              </Fragment>
            )}
            <div className="product__bottom">
              <Price
                price={item.price.value}
                currency={item.price.currency}
                discountedPrice={item.price.discountedPrice}
                className={classNames({ 'product__price--spaced': crossSelling })}
              />
              {isEditable && (
                <Button
                  className={'button--full'}
                  onClick={() =>
                    showModal(BuyingModal, {
                      image,
                      item,
                      quantity: nbItemInBasket,
                      isDeletable,
                      onClose: hideModal,
                      onSubmit: (data) => {
                        if (!isInVoucher) {
                          onSubmit(data, hideModal);

                          return;
                        }

                        showModal(DeleteVoucherPrepToolModal, {
                          onClose: hideModal,
                          isInVoucher,
                          onValidate: () => onSubmit(data, hideModal),
                        });
                      },
                    })
                  }
                  data-cypress="buying-process.cross-selling.add-to-card"
                >
                  <Trans>{nbItemInBasket > 0 ? 'prep-tool.update-cart' : 'prep-tool.add-to-cart'}</Trans>
                </Button>
              )}
              {nbItemInBasket > 0 && isEditable && isDeletable && (
                <Link
                  className={'link--full product__delete'}
                  onClick={() => {
                    if (!isInVoucher) {
                      onSubmit({ quantity: 0 }).then(() => {
                        setNbItemsInBasket(0);
                      });

                      return;
                    }

                    showModal(DeleteVoucherPrepToolModal, {
                      onClose: hideModal,
                      isInVoucher,
                      onValidate: () =>
                        onSubmit({ quantity: 0 }, hideModal).then(() => {
                          setNbItemsInBasket(0);
                        }),
                    });
                  }}
                  data-cypress="buying-process.cross-selling.remove-link"
                >
                  <Trans>prep-tool.remove-from-cart</Trans>
                </Link>
              )}
            </div>
          </div>
        </div>
        {withDetail && <PrismicRichText className="product__detail" richText={item.longDescription} />}
      </div>
    </Fragment>
  );
};

PrepTool.propType = {
  className: PropTypes.string,
  purchaseLine: PropTypes.shape({ quantity: PropTypes.number }),
  crossSelling: PropTypes.bool,
  item: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.shape({
      value: PropTypes.number,
      discountedPrice: PropTypes.number,
      currency: PropTypes.string,
    }),
    levels: PropTypes.array,
    levelAlternative: PropTypes.string,
    meta: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    image: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
      book: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
    }),
    relatedFamily: PropTypes.shape({
      type: PropTypes.string,
    }),
    shortDescription: PropTypes.array,
  }),
  isEditable: PropTypes.bool,
  isDeletable: PropTypes.bool,
  withDetail: PropTypes.bool,
  isInVoucher: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default PrepTool;
