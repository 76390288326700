import React, { Fragment, useState } from 'react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';

import UploadCandidatePictureBase from './Base';
import {
  UploadCandidatePictureUploadInput,
  UploadCandidatePictureUploadInstructions,
  UploadCandidatePictureUploadLoading,
} from './Upload';

const ACCEPTED_FILE = ['image/jpg', 'image/jpeg', 'image/png'];
const MAX_SIZE_FILE = 8 * 1000 * 1000;

const UploadCandidatePictureDropzone = ({ onDrop }) => {
  const { t } = useTranslation();
  const [candidatePicture, setCandidatePicture] = useState(null);
  const [errorFile, setErrorFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const onDropPicture = (acceptedFiles, rejectedFiles) => {
    const rejectedFile = rejectedFiles[0];
    const acceptedFile = acceptedFiles[0];

    if (rejectedFile) {
      setErrorFile(rejectedFile.file);
    }

    if (acceptedFile) {
      setCandidatePicture(acceptedFile);
      setLoading(true);
      onDrop(acceptedFile);
      setLoading(false);
    }
  };

  return (
    <UploadCandidatePictureBase title={t('upload-candidate-picture.dropzone.title')}>
      <div className={'photo-upload__howto'}>
        <div className={'photo-upload__dropzone-wrapper'}>
          <Dropzone onDrop={onDropPicture.bind(this)} accept={ACCEPTED_FILE.toString()} maxSize={MAX_SIZE_FILE}>
            {({ getRootProps, getInputProps }) => (
              <div className={'photo-upload__dropzone'} {...getRootProps()}>
                <input {...getInputProps()} />
                {!loading ? (
                  <UploadCandidatePictureUploadInput
                    errorType={errorFile && !ACCEPTED_FILE.includes(errorFile.type)}
                    errorSize={errorFile && errorFile.size > MAX_SIZE_FILE}
                  />
                ) : (
                  <Fragment>
                    <UploadCandidatePictureUploadLoading pictureName={candidatePicture.name} />
                  </Fragment>
                )}
              </div>
            )}
          </Dropzone>
          <UploadCandidatePictureUploadInstructions />
        </div>
      </div>
    </UploadCandidatePictureBase>
  );
};

UploadCandidatePictureDropzone.propTypes = {
  onDrop: func.isRequired,
};

export default UploadCandidatePictureDropzone;
