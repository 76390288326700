import React from 'react';
import { Trans } from 'react-i18next';

const UploadCandidatePictureUploadInstructions = () => (
  <div className={'photo-upload__dropzone-instructions'}>
    <p>
      <Trans>upload-candidate-picture.upload.instructions.message-1</Trans>
    </p>
    <p>
      <Trans>upload-candidate-picture.upload.instructions.message-2</Trans>
    </p>
  </div>
);

export default UploadCandidatePictureUploadInstructions;
