import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import PrepTool from '../PrepTool';
import Carousel from '../Carousel';
import { useBasketCookie } from '../../hooks';

const PrepToolsCarousel = ({
  prepTools,
  handleSubmit,
  purchase,
  itemsDisplay = 3,
  className = '',
  isPrepToolInVoucher,
}) => {
  const carouselRef = useRef();
  const { getPromoCodeOrVoucher } = useBasketCookie();
  const promoCodeOrVoucher = getPromoCodeOrVoucher();

  const toProps = (prepTool) => {
    let purchaseLine = null;
    let nbItemInBasket = 0;

    if (purchase && purchase.purchaseLines) {
      let purchaseLines = purchase.purchaseLines.filter(
        (purchaseLine) =>
          purchaseLine.articleStock &&
          parseInt(prepTool.get('articleStockId')) === parseInt(purchaseLine.articleStock.id),
      );
      if (purchaseLines.length > 0) {
        purchaseLine = purchaseLines[0];
        nbItemInBasket = parseInt(purchaseLine.quantity);
      }
    }

    return {
      prepTool,
      purchaseLine,
      nbItemInBasket,
      purchase,
    };
  };

  const toRender = (prepToolProps, key, className = '') => {
    const prepTool = prepToolProps.prepTool.toJS();

    return (
      <PrepTool
        crossSelling={true}
        item={prepTool}
        purchaseLine={prepToolProps.purchaseLine}
        onSubmit={(...data) => {
          return handleSubmit(
            prepToolProps.nbItemInBasket,
            prepToolProps.purchaseLine ? parseInt(prepToolProps.purchaseLine.id) : null,
          )(...data).then(async (data) => {
            if (prepTools.size > itemsDisplay && carouselRef && carouselRef.current) {
              carouselRef.current.resetCarousel();
            }

            return data;
          });
        }}
        isEditable={
          !prepToolProps.purchase ||
          (prepToolProps.purchase && (!prepToolProps.purchase.voucher || !prepToolProps.purchase.voucher.isCpf))
        }
        isDeletable={
          !promoCodeOrVoucher?.isVoucher &&
          !promoCodeOrVoucher?.prepToolIds?.includes(parseInt(prepTool.articleStockId))
        }
        className={className}
        key={key}
        isInVoucher={prepToolProps.purchaseLine ? isPrepToolInVoucher(prepToolProps.purchaseLine) : false}
      />
    );
  };

  const isSingle = prepTools.size === 1;

  if (isSingle) {
    return prepTools.map((prepTool, key) => toRender(toProps(prepTool), key, 'product--inline product--single'));
  }

  return (
    <Carousel
      ref={carouselRef}
      className={className}
      infinityLoop={true}
      itemsToScroll={1}
      itemsDisplay={itemsDisplay}
      displayNav={prepTools.size > itemsDisplay}
      autoWidth={true}
    >
      {prepTools
        .map((prepTool) => toProps(prepTool))
        .sort((a, b) => {
          if (a.nbItemInBasket > 0 && b.nbItemInBasket === 0) {
            return -1;
          }

          if (b.nbItemInBasket > 0 && a.nbItemInBasket === 0) {
            return 1;
          }

          return 0;
        })
        .map((prepToolProps, key) => toRender(prepToolProps, key))}
    </Carousel>
  );
};

PrepToolsCarousel.propTypes = {
  prepTools: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  purchase: PropTypes.object,
  itemsDisplay: PropTypes.number,
  className: PropTypes.string,
};

export default PrepToolsCarousel;
