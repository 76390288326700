import React, { Fragment } from 'react';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';

import { EDITORIAL_CONTENT_LIST } from '../../graphql/queries/editorialContent';
import { EditorialContentList } from '../../components/EditorialContent';
import { useRouter, useQuery } from '../../hooks';

const LastEditorialContent = ({ className, testTypeFamilyIds = [], title = null }) => {
  const { lang, countryEmoIso2 } = useRouter();

  const { data } = useQuery(EDITORIAL_CONTENT_LIST, {
    variables: { limit: 3, lang, country: countryEmoIso2, testTypeFamilyIds },
  });

  if (!data.itemsWithCursor) {
    return null;
  }

  const {
    itemsWithCursor: { items },
  } = data;

  return (
    <Fragment>
      {title}
      <EditorialContentList className={className} editorialContentList={fromJS(items)} />
    </Fragment>
  );
};

LastEditorialContent.propTypes = {
  className: PropTypes.string,
  testTypeFamilyIds: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default LastEditorialContent;
