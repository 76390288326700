import React from 'react';
import { string, node } from 'prop-types';

import './styles.scss';

const UploadCandidatePictureBase = ({ title, children, actions }) => {
  return (
    <section className={'upload-candidate-picture'}>
      <header className={'upload-candidate-picture__header'}>
        <p className={'upload-candidate-picture__title'}>{title}</p>
      </header>
      <main className={'upload-candidate-picture__main'}>{children}</main>
      <footer className={'upload-candidate-picture__footer'}>{actions}</footer>
    </section>
  );
};

UploadCandidatePictureBase.propTypes = {
  title: string.isRequired,
  children: node.isRequired,
  actions: node,
};

export default UploadCandidatePictureBase;
