import React from 'react';

import BaseModal from './BaseModal';
import { Button } from '@ets-global/b2c-website-ui';

const ConfirmModal = ({ buttons, ...props }) => {
  const buttonsLink = buttons.map((button, key) => {
    const { label } = button;

    return (
      <Button key={key} {...button}>
        {label}
      </Button>
    );
  });

  return (
    <BaseModal
      footer={buttonsLink}
      title={props.title}
      inlineMode={props.inlineMode}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      {props.children}
    </BaseModal>
  );
};

export default ConfirmModal;
