import React from 'react';
import PropTypes from 'prop-types';

import SkeletonItem from '../SkeletonItem';

import '../PrepTool/styles.scss';

const PrepToolsCarouselSkeleton = ({ nbItems = 2 }) => {
  return (
    <div className={'carousel'}>
      <div className={'carousel__items'} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {[...Array(nbItems)].map((session, key) => (
          <div className={'carousel__item'} key={key} style={{ width: `${95 / nbItems}%` }}>
            <div className={'product'} style={{ textAlign: 'left' }}>
              <div className="product__inner">
                <SkeletonItem style={{ height: '200px', width: '100%' }} />
                <div className="product__content">
                  <SkeletonItem style={{ height: '13px', width: '45%' }} />
                  <SkeletonItem style={{ height: '112px', width: '90%', marginTop: '50px' }} />
                  <div className="product__bottom">
                    <div className={'product__price'}>
                      <SkeletonItem style={{ height: '30px', width: '30%' }} />
                    </div>
                    <SkeletonItem style={{ height: '35px' }} className={'button button--full skeleton-item'} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

PrepToolsCarouselSkeleton.prototype = {
  nbItems: PropTypes.number,
};

export default PrepToolsCarouselSkeleton;
