import React, { useState, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { Map } from 'immutable';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';

import { useNotification } from '../../../../hooks';
import { DELETE_ADDRESS } from '../../../../graphql/mutations';
import { ADDRESSES_LIST } from '../../../../graphql/queries';
import ConfirmModal from '../../Modal/ConfirmModal';
import { Icon, Button } from '@ets-global/b2c-website-ui';

import './styles.scss';

const AddressBlock = ({
  address,
  onEditAddress,
  cartName,
  editable,
  isRequired = true,
  onAddAddress,
  addressLabel,
}) => {
  const { newNotification } = useNotification();
  const { t } = useTranslation();
  const [isModalOpened, setModalOpened] = useState(false);

  const [opsAddressDelete] = useMutation(DELETE_ADDRESS, { variables: { id: address.get('id') } });

  const onDeleteAddress = () => {
    setModalOpened(true);
  };

  const onConfirmDeleteAddress = async (opsAddressDelete, id) => {
    try {
      await opsAddressDelete({
        optimisticResponse: {
          __typename: 'Mutation',
          opsShippingAddress: { address: true, __typename: 'OpsShippingAddress' },
        },
        update: (proxy) => {
          const { addresses, ...data } = proxy.readQuery({ query: ADDRESSES_LIST });
          proxy.writeQuery({
            query: ADDRESSES_LIST,
            data: { ...data, addresses: addresses.filter((address) => address.id !== id) },
          });
        },
      });
      setModalOpened(false);
      newNotification({
        content: t('common.addresses.remove.message.success'),
        type: 'success',
      });
    } catch (error) {
      newNotification({
        content: t('common.error-server-message'),
        type: 'error',
      });
    }
  };

  if (!isRequired && address.size === 0) {
    return <></>;
  }

  if (isRequired && address.size === 0) {
    return (
      <Fragment>
        <div className="basket__addresses-empty">
          <p>
            <Trans>{addressLabel}</Trans>
          </p>
          <Button onClick={onAddAddress} className={'button--dotted'} data-cypress="add-address-button">
            <Trans>session-summary.addresses.add</Trans>
          </Button>
        </div>
      </Fragment>
    );
  }

  return (
    <div
      className={classNames('address-block', {
        'address-block--cart': cartName,
      })}
    >
      {(!editable || cartName) && (
        <p className="address-block__type">
          {cartName}
          {cartName && (
            <button
              onClick={() => onEditAddress(address)}
              className="address-block__actions-button"
              data-modal-open="edit-address"
              aria-label={t('common.addresses.edit')}
            >
              <Icon name={'edit'} />
            </button>
          )}
        </p>
      )}
      <h4 className={'address-block__name'}>{address.get('name')}</h4>
      <p className={'address-block__address'}>
        {address.get('civility') && address.get('civility').get('name')} {address.get('firstname')}{' '}
        {address.get('lastname')}
        <br />
        {address.get('company') && (
          <Fragment>
            {address.get('company')} <br />
          </Fragment>
        )}
        {address.get('streetLine1')}
        <br />
        {address.get('streetLine2') && (
          <Fragment>
            {address.get('streetLine2')} <br />
          </Fragment>
        )}
        {address.get('postalCode')} {address.get('city')}
        <br />
        {address.get('state') && (
          <Fragment>
            {address.get('state')} <br />
          </Fragment>
        )}
        {address.get('country') && address.get('country').get('name')}
        <br />
        {address.get('email') && (
          <Fragment>
            {address.get('email')}
            <br />
          </Fragment>
        )}
        {address.get('phoneNumber') && <Fragment>{address.get('phoneNumber')}</Fragment>}
        {address.get('socialSecurityNumber') && (
            <Fragment>
              <br />
              {t('base.addresses.ssn_prefix', {
                socialSecurityNumber: address.get('socialSecurityNumber')
              })}
            </Fragment>
        )}
      </p>
      {editable && (
        <div className={'address-block__actions'}>
          <button
            onClick={onEditAddress}
            className={'address-block__actions-button'}
            aria-label={t('base.addresses.edit')}
          >
            <Icon name={'edit'} />
          </button>
          <button
            onClick={onDeleteAddress}
            className={'address-block__actions-button'}
            aria-label={t('common.addresses.remove')}
          >
            <Icon name={'trash'} />
          </button>
          <ConfirmModal
            isOpen={isModalOpened}
            title={t('common.addresses.remove-modal.title')}
            onClose={() => setModalOpened(false)}
            buttons={[
              {
                label: t('common.cancel'),
                className: 'button--transparent-light',
                onClick: () => setModalOpened(false),
              },
              {
                label: t('common.addresses.remove-modal.delete'),
                onClick: () => onConfirmDeleteAddress(opsAddressDelete, address.get('id')),
              },
            ]}
          >
            <Trans>common.addresses.remove-modal.description</Trans>
          </ConfirmModal>
        </div>
      )}
    </div>
  );
};

AddressBlock.propTypes = {
  address: PropTypes.instanceOf(Map).isRequired,
  editable: PropTypes.bool,
  onEditAddress: PropTypes.func,
  cartName: PropTypes.string,
  isRequired: PropTypes.bool,
  onAddAddress: PropTypes.func,
  addressLabel: PropTypes.string,
};

AddressBlock.defaultProps = {
  editable: false,
  cartName: '',
};

export default AddressBlock;
