import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { matchPath, useHistory, useLocation } from 'react-router';

import { ADD_PURCHASE_LINE, DELETE_PURCHASE_LINE, UPDATE_PURCHASE_LINE } from '../../graphql/mutations';
import { PURCHASE } from '../../graphql/queries';
import { useBasketCookie, useNotification, useRouter } from '../../hooks';
import { paths } from '../../paths';

const PrepToolsInCart = ({ registrationId, purchaseRefetch, purchase, children }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { generatePath, countryEmoIso2 } = useRouter();
  const { setRegistrationId, getPromoCodeOrVoucher } = useBasketCookie();
  const { newNotification } = useNotification();
  const [addPurchaseLine] = useMutation(ADD_PURCHASE_LINE);
  const [updatePurchaseLine] = useMutation(UPDATE_PURCHASE_LINE);
  const [deletePurchaseLine] = useMutation(DELETE_PURCHASE_LINE);

  const finalizeUpdate = (action, newRegistrationId) => {
    purchaseRefetch({ registrationId: newRegistrationId }).then(() => {
      const isPagePrepTools = matchPath(location.pathname, { path: paths.PREP_TOOLS_LAYOUT, isExact: true });

      if (action === 'goToCart') {
        history.push(generatePath(paths.PURCHASE_SUMMARY, { registrationId: newRegistrationId }));
      } else if (isPagePrepTools && !registrationId) {
        history.push(generatePath(paths.PREP_TOOLS_BY_REGISTRATION_ID, { registrationId: newRegistrationId }));
      } else if (!registrationId) {
        setRegistrationId(newRegistrationId);
      }
    });
  };

  const handleAddPrepTool = (purchaseLineData, callbackSubmit) => {
    const promoCodeOrVoucher = getPromoCodeOrVoucher();

    let mutationOptions = {
      variables: {
        registrationId,
        purchaseLine: {
          id: purchaseLineData.id,
          quantity: parseInt(purchaseLineData.quantity),
        },
        country: countryEmoIso2,
        referrer: promoCodeOrVoucher?.referrer,
      },
    };

    if (registrationId) {
      mutationOptions.refetchQueries = [{ query: PURCHASE, variables: { registrationId } }];
      mutationOptions.awaitRefetchQueries = true;
    }

    return addPurchaseLine(mutationOptions)
      .then(
        ({
          data: {
            addPurchaseLine: { purchaseLineAdded },
          },
        }) => {
          if (purchaseLineAdded.__typename === 'PurchaseCannotBeCreated') {
            newNotification({
              content: t(`purchase-summary.error.${purchaseLineAdded.reason}`),
              type: 'error',
            });

            return;
          }

          finalizeUpdate(purchaseLineData.submit, purchaseLineAdded.registrationId);
        },
      )
      .catch(() => {
        newNotification({
          content: t('common.error-server-message'),
          type: 'error',
        });
      })
      .finally(() => callbackSubmit());
  };

  const handleUpdatePrepTool = (purchaseLineId, purchaseLineData, callbackSubmit) => {
    return updatePurchaseLine({
      variables: {
        registrationId,
        purchaseLineId,
        quantity: parseInt(purchaseLineData.quantity),
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updatePurchaseLine: { purchaseLine: true, __typename: 'OpsPurchaseline' },
      },
      update: (cache) => {
        const quantity = parseInt(purchaseLineData.quantity);
        cache.modify({
          id: cache.identify({ __typename: 'PurchaseLine', id: purchaseLineId }),
          fields: {
            quantity: () => quantity,
          },
        });
      },
    })
      .then(() => {
        finalizeUpdate(purchaseLineData.submit, registrationId);
      })
      .catch(() => {
        newNotification({
          content: t('common.error-server-message'),
          type: 'error',
        });
      })
      .finally(() => callbackSubmit());
  };

  const handleDeletePrepTool = (purchaseLineId, purchaseLineData, callbackSubmit) => {
    return deletePurchaseLine({
      variables: {
        registrationId,
        purchaseLineId,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        deletePurchaseLine: { purchaseLine: true, __typename: 'OpsPurchaseline' },
      },
      update: (cache) => {
        cache.modify({
          id: cache.identify({ __typename: 'Purchase', registrationId }),
          fields: {
            purchaseLines: (existingPurchaseLinesRefs, { readField }) => [
              ...existingPurchaseLinesRefs.filter(
                (purchaseLineRef) => purchaseLineId !== readField('id', purchaseLineRef),
              ),
            ],
          },
        });
        cache.evict({ id: cache.identify({ __typename: 'PurchaseLine', id: purchaseLineId }) });
      },
    })
      .then(() => {
        newNotification({
          content: t('preptool.remove.success-message'),
          type: 'success',
        });
        finalizeUpdate(purchaseLineData.submit, registrationId);
      })
      .catch(() => {
        newNotification({
          content: t('common.error-server-message'),
          type: 'error',
        });
      })
      .finally(() => callbackSubmit());
  };

  const handleSubmit =
    (nbItemInBasket, purchaseLineId) =>
    (purchaseLineData, callbackSubmit = () => {}) => {
      if (nbItemInBasket === purchaseLineData.quantity) {
        return new Promise((resolve) => {
          finalizeUpdate(purchaseLineData.submit, registrationId);
          callbackSubmit();
          resolve(true);
        });
      }

      if (parseInt(purchaseLineData.quantity) > 0) {
        if (nbItemInBasket === 0) {
          return handleAddPrepTool(purchaseLineData, callbackSubmit);
        }

        return handleUpdatePrepTool(purchaseLineId, purchaseLineData, callbackSubmit);
      }

      if (nbItemInBasket !== 0) {
        return handleDeletePrepTool(purchaseLineId, purchaseLineData, callbackSubmit);
      }
    };

  const isPrepToolInVoucher = (purchaseLine) => {
    if (!purchase || !purchase.voucher) {
      return false;
    }

    return purchase.voucher.prepToolIds.includes(purchaseLine.articleStock.id);
  };

  return children({ handleSubmit, isPrepToolInVoucher });
};

PrepToolsInCart.propTypes = {
  registrationId: PropTypes.string,
  purchaseRefetch: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

export default PrepToolsInCart;
