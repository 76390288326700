import React from 'react';
import { useTranslation } from 'react-i18next';
import Proptypes from 'prop-types';

const HowUploadCandidatePictureItem = ({ descriptionTranslationKey, image }) => {
  const { t } = useTranslation();

  return (
    <div className={'photo-upload__howto-item'}>
      <div className={'photo-upload__howto-item-img'}>
        <img src={require(`../../assets/images/upload-candidate-picture/${image}.svg`)} alt={image} />
      </div>
      <p
        className={'photo-upload__howto-item-paragraph'}
        dangerouslySetInnerHTML={{ __html: t(descriptionTranslationKey) }}
      />
    </div>
  );
};

HowUploadCandidatePictureItem.propTypes = {
  descriptionTranslationKey: Proptypes.string.isRequired,
  image: Proptypes.string.isRequired,
};

export default HowUploadCandidatePictureItem;
