import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List } from 'immutable';
import { Trans } from 'react-i18next';

import Button from '@ets-global/b2c-website-ui/Button';

import EditorialContentItem from '../EditorialContentItem';
import MarketingBannerContainer, { marketingBannerPages } from '../../../containers/MarketingBanner';

import './styles.scss';

const EditorialContentList = ({
  className,
  editorialContentList = new List(),
  showLoadMore = false,
  loadMoreProps,
  blog = false,
}) => {
  return (
    <article className={classNames('editorial-content-list', className)}>
      {editorialContentList.size === 0 ? (
        <p className="editorial-content-list__empty">
          <Trans>blog-empty-list-message</Trans>
        </p>
      ) : (
        <Fragment>
          <section className="editorial-content-list__list">
            {blog && (
              <div key={'banner'} className={'editorial-content-list__list-banner'}>
                <MarketingBannerContainer page={marketingBannerPages.blog} />
              </div>
            )}
            {editorialContentList.map((editorialContent, key) => {
              const mobileImage = editorialContent
                .get('images')
                .filter((image) => image.get('source') === 'MOBILE')
                .get(0);

              return (
                <EditorialContentItem
                  displayHn={true}
                  key={key}
                  slug={editorialContent.get('slug')}
                  title={editorialContent.get('title')}
                  image={
                    editorialContent.get('listImage') ? editorialContent.get('listImage').toJS() : mobileImage.toJS()
                  }
                  date={editorialContent.get('date')}
                  category={editorialContent.get('editorialContentType')}
                />
              );
            })}
          </section>
          {showLoadMore && (
            <Button {...loadMoreProps} className={'button--transparent-light'}>
              <Trans>blog.load-more.button</Trans>
            </Button>
          )}
        </Fragment>
      )}
    </article>
  );
};

EditorialContentList.propTypes = {
  className: PropTypes.string,
  editorialContentList: PropTypes.instanceOf(List),
  showLoadMore: PropTypes.bool,
  loadMoreProps: PropTypes.object,
};

export default EditorialContentList;
