import React from 'react';
import { Trans } from 'react-i18next';
import { List as ImmutableList } from 'immutable';
import { instanceOf } from 'prop-types';

import { Anchor, Icon } from '@ets-global/b2c-website-ui';

import { SOCIAL_NETWORKS } from '../../constants';

import { List } from '../Element';
import { capitalize } from '../../helpers/Formatter/StringFormatter';

const SocialNetwork = () => {
  const items = SOCIAL_NETWORKS.map((socialNetwork, key) => (
    <Anchor
      key={key}
      to={socialNetwork.href}
      aria-label={capitalize(socialNetwork.name)}
      target={'_blank'}
      className={'footer__social-item-link'}
    >
      <Icon name={socialNetwork.name} />
    </Anchor>
  ));

  return (
    <div className="footer__row footer__row--first">
      <p className={'footer__title'}>
        <Trans>footer.follow_us.title</Trans>
      </p>
      <List items={items} listClassNames={'footer__social'} itemClassNames={'footer__social-item'} />
    </div>
  );
};

SocialNetwork.propsTypes = {
  socialNetworks: instanceOf(ImmutableList).isRequired,
};

export default SocialNetwork;
