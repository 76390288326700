import React from 'react';
import { string } from 'prop-types';

import ImgLazyLoad from '../../ImgLazyLoad';

import './flag.scss';

const Flag = ({ country }) => (
  <ImgLazyLoad className="flag" url={require(`../../../assets/images/flags/${country}.svg`)} alt={country} />
);

Flag.propTypes = {
  country: string.isRequired,
};

export default Flag;
