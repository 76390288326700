import React from 'react';
import { Trans } from 'react-i18next';

import { Button } from '@ets-global/b2c-website-ui';

import { paths } from '../../paths';
import { useLocation } from 'react-router';
import useRouter from '../../hooks/useRouter';

const SessionSearchBlock = () => {
  const location = useLocation();
  const { currentRoute } = useRouter();

  return (
    <div className={'footer__row footer__row--third'}>
      <p className={'footer__title'}>
        <Trans>footer.session_search.title</Trans>
      </p>
      <Button
        to={currentRoute.path === paths.SESSION_SEARCH ? location.pathname : paths.SESSION_SEARCH}
        search={currentRoute.path === paths.SESSION_SEARCH ? location.search : null}
      >
        <Trans>footer.session_search.button</Trans>
      </Button>
    </div>
  );
};

export default SessionSearchBlock;
