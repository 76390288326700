import { capitalize } from './StringFormatter';

export const CIVILITIES = {
  10: 'common.user.civility.mr',
  20: 'common.user.civility.mrs',
  30: 'common.user.civility.ms',
  40: 'common.user.civility.dr',
  50: 'common.user.civility.prof',
};

export const formatFullName = (user, translate) => {
  let fullName = [];
  if (user.civility) {
    fullName.push(`${capitalize(getTranslatedUserCivility(user.civility, translate))}`);
  }

  if (user.firstname) {
    fullName.push(`${capitalize(user.firstname)}`);
  }

  if (user.lastname) {
    fullName.push(`${capitalize(user.lastname)}`);
  }

  return fullName.join(' ');
};

export const getTranslatedUserCivility = (civilityId, translate) => {
  if (!Object.keys(CIVILITIES).includes(civilityId)) {
    // eslint-disable-next-line no-console
    console.log(`This civility id : ${civilityId} is invalid`);

    return '';
  }

  return `${translate(CIVILITIES[civilityId])}`;
};

export const getTranslatedUserCivilities = (translate) => {
  return {
    10: `${getTranslatedUserCivility('10', translate)}`,
    20: `${getTranslatedUserCivility('20', translate)}`,
    30: `${getTranslatedUserCivility('30', translate)}`,
    40: `${getTranslatedUserCivility('40', translate)}`,
    50: `${getTranslatedUserCivility('50', translate)}`,
  };
};
