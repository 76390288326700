import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@ets-global/b2c-website-ui';
import { paths } from '../../paths';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="contact-form__footer">
      <section className="container container--padding">
        <div className="contact-form__help">
          <h2 className="contact-form__help-title">
            <Trans>contact.footer.title</Trans>
          </h2>
          <div className="contact-form__help-text" dangerouslySetInnerHTML={{ __html: t('contact.footer.content') }} />
          <Button to={paths.HELP_CENTER} className="button--white">
            <Trans>contact.footer.actions.help-center</Trans>
          </Button>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
