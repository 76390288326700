import React, { useEffect, useRef, useState, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

const DropDown = ({
  className,
  buttonLabel,
  buttonClassName,
  contentClassName,
  children,
  selectRef,
  disabled = false,
}) => {
  const [dropdownActive, setDropdownActive] = useState();
  const DropDownRef = useRef();

  useImperativeHandle(selectRef, () => ({
    close: () => {
      setDropdownActive(false);
    },
  }));

  useEffect(() => {
    const handleClick = (event) => {
      if (DropDownRef && DropDownRef.current && !DropDownRef.current.contains(event.target) && dropdownActive) {
        setDropdownActive(false);
      }
    };
    document.addEventListener('click', handleClick, false);

    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  });

  const handleClick = () => {
    if (disabled) {
      return;
    }

    setDropdownActive(!dropdownActive);
  };

  return (
    <div className={classNames('dropdown', className, { 'dropdown--active': dropdownActive })} ref={DropDownRef}>
      <button className={classNames('dropdown__button', buttonClassName)} onClick={handleClick}>
        {buttonLabel}
      </button>
      <div className={classNames('dropdown__content', contentClassName)}>{children}</div>
    </div>
  );
};

DropDown.propTypes = {
  className: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  selectRef: PropTypes.object,
  disabled: PropTypes.bool,
};

export default DropDown;
