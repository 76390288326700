import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useApolloClient } from '@apollo/client';

import { NumberByLocale } from '../../Element';
import SessionDetail from '../../Session/Detail';
import OrderDetailRow from '../Row';
import purchaseLinesGrouper from '../../../helpers/purchaseLinesGrouper';
import ApplyCode from '../../../pages/BuyingProcess/Summary/ApplyCode';
import { DELETE_PURCHASE_LINE, UPDATE_PURCHASE_LINE } from '../../../graphql/mutations';
import { useNotification, useRouter } from '../../../hooks';
import errorsHandler from '../../../helpers/errorsHandler';
import { ARTICLE_FAMILIES } from '../../../constants';
import { translateProductNameCallback } from '../../../helpers/i18n';

import './styles.scss';

const OrderDetail = ({
  purchase,
  session,
  includedProductsAndServices = [],
  className,
  purchaseRefetch,
  isEditable,
  isWide = false,
}) => {
  const apolloClient = useApolloClient();
  const { lang } = useRouter();
  const { newNotification } = useNotification();

  const { i18n, t } = useTranslation();
  const translateProductName = translateProductNameCallback(i18n, t);

  const purchaseLines = purchase.get('purchaseLines');
  const currency = purchaseLines.get(0).get('price').get('currency');

  const { sessionArticle, sessionOptions, otherArticles, fees, discounts } = purchaseLinesGrouper(purchaseLines);

  const canAddMoreDiscounts =
    purchase.get('total') > 0 &&
    discounts.length - sessionOptions.length - otherArticles.length - (sessionArticle ? 1 : 0) !== 0;

  const isPrepToolInVoucher = (purchaseLine) => {
    if (
      !purchase ||
      !purchase.get('voucher') ||
      ![ARTICLE_FAMILIES.BOOK, ARTICLE_FAMILIES.ONLINE].includes(purchaseLine.get('articleFamily'))
    ) {
      return false;
    }

    return purchase.get('voucher').get('prepToolIds').includes(purchaseLine.get('articleStock').get('id'));
  };

  const onPurchaseUpdate = async ({ id, quantity }) => {
    try {
      const mutation = quantity > 0 ? UPDATE_PURCHASE_LINE : DELETE_PURCHASE_LINE;
      let variables = {
        registrationId: purchase.get('registrationId'),
        purchaseLineId: parseInt(id),
      };

      if (quantity > 0) {
        variables.quantity = parseInt(quantity);
      }

      await apolloClient.mutate({
        mutation,
        variables,
      });

      return purchaseRefetch().then(({ data: { purchase } }) => {
        if (purchase.purchaseLines.length === 0) {
          return;
        }

        newNotification({
          content: t('account.billing.order.update-lines.successful'),
          type: 'success',
        });
      });
    } catch (error) {
      return new Promise((resolve, reject) => {
        errorsHandler({ error, newNotification, t });

        return reject(true);
      });
    }
  };

  return (
    <div className={classNames('order-table', className, { isWide: 'order-table--wide' })}>
      <OrderDetailRow locale={lang} />

      {sessionArticle && sessionArticle.size > 0 && (
        <div>
          <OrderDetailRow
            name={
              session && session.size > 0 ? (
                <SessionDetail session={session} className={classNames({ 'session-detail--inline': isWide })} />
              ) : (
                translateProductName(sessionArticle.get('articleName'))
              )
            }
            unitPrice={sessionArticle.get('price').get('valueTaxFree')}
            quantity={sessionArticle.get('quantity')}
            amount={sessionArticle.get('price').get('valueTaxFree') * sessionArticle.get('quantity')}
            currency={sessionArticle.get('price').get('currency')}
            locale={lang}
            listClassNames={'order-table-row order-table-row--session-detail'}
          />

          {purchase.get('candidates') &&
            purchase.get('candidates').size > 0 &&
            includedProductsAndServices.map((includedProductsAndService, key) => (
              <OrderDetailRow
                key={key}
                name={translateProductName(includedProductsAndService.translationKey)}
                isIncluded={true}
                quantity={purchase.get('candidates').size}
                locale={lang}
                listClassNames={'order-table-row order-table-row--line-top'}
              />
            ))}

          {sessionOptions.map((article, key) => (
            <OrderDetailRow
              key={key}
              name={translateProductName(article.get('articleName'))}
              unitPrice={article.get('price').get('valueTaxFree')}
              quantity={article.get('quantity')}
              amount={article.get('price').get('valueTaxFree') * article.get('quantity')}
              currency={article.get('price').get('currency')}
              locale={lang}
              listClassNames={'order-table-row order-table-row--line-top'}
            />
          ))}
        </div>
      )}

      {otherArticles.map((article, key) => (
        <Fragment key={key}>
          <OrderDetailRow
            articleId={article.get('id')}
            name={translateProductName(article.get('articleName'))}
            unitPrice={article.get('price').get('valueTaxFree')}
            quantity={article.get('quantity')}
            amount={article.get('price').get('valueTaxFree') * article.get('quantity')}
            currency={article.get('price').get('currency')}
            locale={lang}
            listClassNames={'order-table-row'}
            isEditable={isEditable && (!purchase.get('voucher') || !purchase.get('voucher').get('isCpf'))}
            onPurchaseUpdate={onPurchaseUpdate}
            isInVoucher={isPrepToolInVoucher(article)}
          />
        </Fragment>
      ))}

      {discounts.map((article, key) => (
        <Fragment key={key}>
          <OrderDetailRow
            name={
              article.get('isDiscount') ? (
                <Fragment>
                  {article.get('discountCode')} - {article.get('discountName')}
                </Fragment>
              ) : (
                <Fragment>
                  {article.get('voucherCode')} - {translateProductName(article.get('articleName'))}
                </Fragment>
              )
            }
            unitPrice={article.get('price').get('valueTaxFree')}
            quantity={article.get('quantity')}
            amount={article.get('price').get('valueTaxFree') * article.get('quantity')}
            currency={article.get('price').get('currency')}
            locale={lang}
            listClassNames={'order-table-row order-table-row--promo'}
            isDiscount={true}
          />
        </Fragment>
      ))}

      {isEditable && canAddMoreDiscounts && (
        <ApplyCode registrationId={purchase.get('registrationId')} purchaseRefetch={purchaseRefetch} />
      )}

      <div className="order-table__total">
        {fees.map((article, key) => (
          <Fragment key={key}>
            <div className="order-table__total__row">
              <p>
                <Trans>{article.get('articleName')}</Trans>
              </p>
              <p>
                <NumberByLocale
                  currency={article.get('price').get('currency')}
                  value={article.get('price').get('valueTaxFree')}
                />
              </p>
            </div>
          </Fragment>
        ))}
        <div className="order-table__total__row">
          <p>
            <Trans>account.billing.order.total_without_taxes</Trans>
          </p>
          <p>
            <NumberByLocale currency={currency} value={purchase.get('total')} />
          </p>
        </div>
        {purchase
          .get('purchaseTaxes')
          .map((tax, key) => (
            <Fragment key={key}>
              <div className="order-table__total__row">
                <p>
                  <Trans>account.billing.order.tax_amount</Trans>{' '}
                  <NumberByLocale style="percent" value={tax.get('taxRate')} />
                </p>
                <p>
                  <NumberByLocale currency={currency} value={tax.get('amount')} />
                </p>
              </div>
            </Fragment>
          ))
          .toArray()}
        <div className="order-table__total__row">
          <p>
            <Trans>account.billing.order.total_with_taxes</Trans>
          </p>
          <p>
            <NumberByLocale currency={currency} value={purchase.get('totalWithTaxes')} />
          </p>
        </div>
      </div>
    </div>
  );
};

OrderDetail.propTypes = {
  purchase: PropTypes.instanceOf(Map).isRequired,
  session: PropTypes.instanceOf(Map),
  includedProductsAndServices: PropTypes.array,
  className: PropTypes.string,
  purchaseRefetch: PropTypes.func,
  isEditable: PropTypes.bool.isRequired,
};

OrderDetail.defaultProps = {
  isEditable: false,
  purchaseRefetch: () => {},
};

export default OrderDetail;
