import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import NewsletterBlockBase from '../../components/Content/NewsletterBlock';
import { SUBSCRIBE_NEWSLETTER } from '../../graphql/mutations';
import { useDataLayer, useNotification, useRouter } from '../../hooks';
import { AuthContext } from '../../store/Auth';

const NewsletterBlock = (props) => {
  const { lang, countryIso2 } = useRouter();
  const { pushEventConfirmSubscriptionNewsletter } = useDataLayer();
  const { user } = useContext(AuthContext);
  const [subscribeNewsletter] = useMutation(SUBSCRIBE_NEWSLETTER);
  const { newNotification } = useNotification();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = ({ email, identity }) => {
    subscribeNewsletter({
      variables: {
        input: {
          email,
          country: countryIso2,
          language: lang,
          contactType: identity,
        },
      },
    })
      .then(() => {
        setIsSubmitting(true);
        pushEventConfirmSubscriptionNewsletter(identity === 'ANONYMOUS_B2B_CONTACT');
      })
      .catch(() =>
        newNotification({
          content: t('newsletter.block.error'),
          type: 'error',
        })
      );
  };

  return !user || !user.email ? (
    <NewsletterBlockBase onSubmit={onSubmit} {...props} isSubmitting={isSubmitting} />
  ) : null;
};

export default NewsletterBlock;
