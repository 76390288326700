import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { List } from 'immutable';

import { Button, Link } from '@ets-global/b2c-website-ui';

import TimelineBullets from '../Base/TimelineBullets';
import { SmallTitle } from '../Base/Title';
import HowUploadCandidatePictureItem from './Item';

import './styles.scss';

const HowUploadCandidatePictureBase = ({
  titleTranslationKey,
  items,
  stateWorkflow = { currentStep: 0, stepsNumber: 0 },
  onNextStep = () => {},
  onFinishWorkflow = () => {},
}) => (
  <section className={'how-update-picture-candidate'}>
    <header className={'how-update-picture-candidate__header'}>
      <p className={'how-update-picture-candidate__title'}>
        <Trans>how-upload-candidate-picture.title</Trans>
      </p>
    </header>
    <main className={'how-update-picture-candidate__main'}>
      <TimelineBullets activeIndex={stateWorkflow.currentStep} stepsNumber={stateWorkflow.stepsNumber} />
      <SmallTitle>
        <Trans>{titleTranslationKey}</Trans>
      </SmallTitle>
      <div
        className={classNames('photo-upload__howto', {
          'photo-upload__howto--double': items.size === 2,
          'photo-upload__howto--quad': items.size === 4,
        })}
      >
        {items.map((item, key) => (
          <HowUploadCandidatePictureItem
            key={key}
            descriptionTranslationKey={item.get('descriptionTranslationKey')}
            image={item.get('image')}
          />
        ))}
      </div>
    </main>
    <footer className={'how-update-picture-candidate__footer'}>
      <Button onClick={onNextStep}>
        <Trans>how-upload-candidate-picture.next</Trans>
      </Button>
      <Link icon={'arrow'} className={'how-update-picture-candidate__link'} onClick={onFinishWorkflow}>
        <Trans>how-upload-candidate-picture.skip</Trans>
      </Link>
    </footer>
  </section>
);

HowUploadCandidatePictureBase.propTypes = {
  titleTranslationKey: PropTypes.string.isRequired,
  items: PropTypes.instanceOf(List).isRequired,
  stateWorkflow: PropTypes.object,
  onNextStep: PropTypes.func,
  onFinishWorkflow: PropTypes.func,
};

export default HowUploadCandidatePictureBase;
