import React from 'react';
import PropTypes from 'prop-types';

import PrepToolBanner from '../../../Base/Banner/PrepToolBanner';
import BuyingProcess from '../index';

const PrepToolsLayout = ({ children }) => (
  <BuyingProcess classname={'prep-tools-list'}>
    <PrepToolBanner />
    {children}
  </BuyingProcess>
);

PrepToolsLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrepToolsLayout;
