import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import classNames from 'classnames';

import Button from '@ets-global/b2c-website-ui/Button';
import FormikFastField from '@ets-global/b2c-website-ui/Fields/FormikField/FormikFastField';
import { SelectField } from '@ets-global/b2c-website-ui/Fields/Select';
import { TextField } from '@ets-global/b2c-website-ui/Fields/Text';
import CheckboxField from '@ets-global/b2c-website-ui/Fields/Checkbox';

import useYup from '../../../hooks/useYup';
import { SmallTitle } from '../../Base/Title';

import './styles.scss';

const NewsletterBlock = ({ onSubmit = () => {}, isSubmitting = false, newsletterPage = false, email = '' }) => {
  const { t } = useTranslation();
  const { Yup } = useYup();

  const identities = [
    {
      value: 'ANONYMOUS_B2C_CONTACT',
      label: t('newsletter-block.you-are.individual.label'),
    },
    {
      value: 'ANONYMOUS_B2B_CONTACT',
      label: t('newsletter-block.you-are.professional.label'),
    },
  ];

  let validations = {
    identity: Yup.string().required(),
    email: Yup.string().required().email(),
    newsletter: Yup.boolean()
      .required()
      .test('isChecked', t('newsletter-block.form.validation.agreement'), (value) => value === true),
  };

  const NewsLetterSchema = Yup.object().shape(validations);

  const NewsletterForm = () => (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ identity: 'ANONYMOUS_B2C_CONTACT', email: '', newsletter: false }}
      validationSchema={NewsLetterSchema}
    >
      <Form>
        <div
          className={classNames('newsletter-block__field-row', {
            'newsletter-block__field-row--line': !newsletterPage,
          })}
        >
          <div className="newsletter-block__field-column">
            <FormikFastField
              component={(props) => <SelectField className="select--full" {...props} />}
              id="identity"
              name="identity"
              options={identities}
              label={<Trans>newsletter-block.you-are.label</Trans>}
              className={'newsletter-block__field-wrapper'}
            />
          </div>
          <div className="newsletter-block__field-column">
            <FormikFastField
              id="email"
              name="email"
              component={TextField}
              label={<Trans>newsletter-block.email.label</Trans>}
              required={true}
              className={'newsletter-block__field-wrapper newsletter-block__field-wrapper--has-textfield'}
            />
          </div>
        </div>
        <FormikFastField
          id="newsletter"
          name="newsletter"
          component={CheckboxField}
          label={<Trans>newsletter-block.checkbox-label</Trans>}
          required={true}
          className={'newsletter-block__field-wrapper'}
        />
        <Button type="submit" className="button--full">
          <Trans>newsletter-block.button</Trans>
        </Button>
        <p className="newsletter-block__mentions">
          <Trans>newsletter-block.mentions</Trans>
        </p>
      </Form>
    </Formik>
  );

  const NewsletterSuccess = () => (
    <Fragment>
      <h4 className="newsletter-block__confirm-title">
        <Trans>newsletter-block.confirm-title</Trans>
      </h4>
      <p className="newsletter-block__confirm-text">
        <Trans values={{ email }}>newsletter-block.confirm-text</Trans>
      </p>
    </Fragment>
  );

  const Content = () => (
    <Fragment>
      {!isSubmitting && (
        <Fragment>
          {!newsletterPage && (
            <p className="newsletter-block__text">
              <Trans>newsletter-block.text</Trans>
            </p>
          )}
          <NewsletterForm />
        </Fragment>
      )}
      {isSubmitting && <NewsletterSuccess />}
    </Fragment>
  );

  if (newsletterPage) {
    return <Content />;
  }

  return (
    <div className="newsletter-block">
      <div className="newsletter-block__inner">
        <SmallTitle className="newsletter-block__title">
          <Trans>newsletter-block.title</Trans>
        </SmallTitle>
        <Content />
      </div>
    </div>
  );
};

NewsletterBlock.propsTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  newsletterPage: PropTypes.bool,
  email: PropTypes.string,
};

export default NewsletterBlock;
