import React from 'react';
import classNames from 'classnames';
import ImgLazyLoad from '../../ImgLazyLoad';

import './style.scss';

const TestimonialBlock = ({ title, author, image, className, backgroundColor }) => (
  <div className={classNames('testimony', className)}>
    <blockquote
      className={classNames('testimony__blockquote', {
        'testimony__blockquote--blue': backgroundColor && backgroundColor === 'blue',
      })}
    >
      <p className={'quote'}>{title}</p>
      <footer className={'testimony__author'}>{author}</footer>
    </blockquote>

    {image && <ImgLazyLoad className={'testimony__picture'} url={image.url} alt={image.alt} />}
  </div>
);

export default TestimonialBlock;
