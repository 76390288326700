import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';

import './tinytext.scss';

const TinyText = ({ modifier, children, className }) => (
  <p
    className={classNames(
      {
        'tiny-text': true,
        'tiny-text--bold': modifier === 'bold',
      },
      className,
    )}
  >
    {children}
  </p>
);

TinyText.propTypes = {
  modifier: string,
  className: string,
};

TinyText.defaultProps = {
  modifier: '',
  className: '',
};

export default TinyText;
