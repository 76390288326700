import React, { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import classNames from 'classnames';

import { Fields, Loader, Button } from '@ets-global/b2c-website-ui';

import BaseModal from '../../Base/Modal/BaseModal';

import './styles.scss';

const BuyingModal = ({ onClose, image, item, quantity, onSubmit, isDeletable = true }) => {
  const formikRef = useRef(null);
  const { t } = useTranslation();

  const handleClose = (e) => {
    if (!formikRef.current.state.isSubmitting) {
      onClose(e);
    }
  };

  return (
    <BaseModal
      title={t('session-registration.cross-selling.buying-modal.title')}
      isOpen
      onClose={handleClose}
      className={'modal__container--large modal-product-added'}
    >
      <div
        className={classNames('modal-product-added__picture', {
          'modal-product-added__picture--large': item.relatedFamily.type !== 'Book',
          'modal-product-added__picture--small': item.relatedFamily.type === 'Book',
        })}
      >
        <img src={image.url} alt={image.alt} className="modal-product-added__picture-image" />
      </div>
      <Formik
        ref={formikRef}
        initialValues={{
          quantity: quantity || 1,
          id: item.articleStockId,
          ...item,
        }}
        onSubmit={onSubmit}
        render={({ isSubmitting, setFieldValue }) => {
          return (
            <Form className="modal-product-added__content">
              <div>
                <p className="modal__title modal-product-added__title-desktop">
                  <Trans>session-registration.cross-selling.buying-modal.title</Trans>
                </p>
                <p>
                  <Trans>{item.name}</Trans>
                </p>
              </div>
              <div className="modal-product-added__quantity">
                <p>
                  <Trans>session-registration.cross-selling.buying-modal.quantity</Trans>
                </p>
                <Fields.FormikFastField
                  id="quantity"
                  name="quantity"
                  required={true}
                  component={Fields.SelectOrNumberField}
                  isMandatory={!isDeletable}
                  allowZero={quantity > 0}
                  className={'modal-product-added__quantity-input'}
                  setFieldValue={(value) => setFieldValue('quantity', value)}
                />
                <p>
                  <Trans>session-registration.cross-selling.buying-modal.description</Trans>
                </p>
              </div>
              <div className="modal-product-added__buttons">
                <Button
                  data-cypress="keep-buying-button"
                  className={'button--transparent-light'}
                  type="submit"
                  onClick={() => {
                    setFieldValue('submit', 'continuePurchase');
                  }}
                  disabled={isSubmitting}
                >
                  {!isSubmitting ? (
                    <span>
                        <Trans>session-registration.cross-selling.buying-modal.continue-purchase</Trans>
                    </span>
                  ) : (
                    <Loader />
                  )}
                </Button>

                <Button
                  data-cypress="go-to-cart-button"
                  type="submit"
                  onClick={() => {
                    setFieldValue('submit', 'goToCart');
                  }}
                  disabled={isSubmitting}
                >
                  {!isSubmitting ? (
                    <span>
                        <Trans>session-registration.cross-selling.buying-modal.go-to-cart</Trans>
                    </span>
                  ) : (
                    <Loader />
                  )}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    </BaseModal>
  );
};

export default BuyingModal;
