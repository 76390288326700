import React, { useState, useEffect, Fragment } from 'react';
import { Map } from 'immutable';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@ets-global/b2c-website-ui';

import SelectAddressModal from '../Base/Address/SelectAddressModal';
import ModalAddress from '../Base/Address/ModalAddress';

const AddressSelector = ({
  isModalDisplayed,
  addressesList,
  isLoggedIn,
  isBilling,
  selectedAddress,
  countries,
  civilities,
  onCloseModal,
  onSaveAddress,
  addAddressIsEnabled = true,
}) => {
  const [addressesState, setAddressesState] = useState({
    address: new Map(),
    isBilling: false,
    isSelectAddressModalDisplayed: false,
    isAddressModalDisplayed: false,
    modalTitle: '',
    submitLabel: '',
    onSubmit: () => {},
  });

  useEffect(() => {
    if (isModalDisplayed) {
      if (isLoggedIn && addressesList.size > 0) {
        setAddressesState({
          ...addressesState,
          isBilling,
          address: selectedAddress,
          isSelectAddressModalDisplayed: true,
        });
      } else {
        openAddressModal({ address: selectedAddress, isBilling });
      }
    } else {
      // Close modals
      setAddressesState({
        ...addressesState,
        address: new Map(),
        isSelectAddressModalDisplayed: false,
        isAddressModalDisplayed: false,
      });
    }
  }, [isModalDisplayed, selectedAddress, isBilling]);

  const { t } = useTranslation();

  const handleCloseModal = () => {
    onCloseModal();
  };

  const handleCloseModalAddress = () => {
    if (isLoggedIn && addressesList.size > 0) {
      setAddressesState({
        ...addressesState,
        isSelectAddressModalDisplayed: true,
        isAddressModalDisplayed: false,
      });

      return;
    }

    handleCloseModal();
  };

  const getAddressFromData = (data) => {
    return {
      isDefaultShippingAddress: data.isDefaultShippingAddress === '' ? false : data.isDefaultShippingAddress,
      name: data.addressName || data.name,
      streetLine1: data.streetLine1,
      streetLine2: data.streetLine2,
      postalCode: data.postalCode,
      city: data.city,
      state: data.state,
      country: data.country,
      civility: data.civilityId,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phoneNumber: data.phoneNumber,
      socialSecurityNumber: data.socialSecurityNumber ?? null,
    };
  };

  const saveAddress =
    (
      { id = null, isBilling = false, skipSaveAddressBook = false } = {
        id: null,
        isBilling: false,
        skipSaveAddressBook: false,
      },
    ) =>
    (address) => {
      address = getAddressFromData(address);
      onSaveAddress({ address, id, isBilling, isLoggedIn, skipSaveAddressBook });
    };

  const onSubmit =
    (
      { id = null, isBilling = false } = {
        id: null,
        isBilling: false,
      },
    ) =>
    (address, actions) => {
      saveAddress({ id, isBilling })(address);
      actions.setSubmitting(false);
    };

  const openAddressModal = ({ address = new Map(), isBilling = false } = { address: new Map(), isBilling: false }) => {
    setAddressesState({
      ...addressesState,
      address,
      isBilling,
      isSelectAddressModalDisplayed: false,
      isAddressModalDisplayed: true,
      onSubmit: onSubmit({ id: address.get('id'), isBilling }),
      modalTitle: address.size === 0 ? t('common.addresses.add-address') : t('common.addresses.edit-address'),
      submitLabel:
        address.size === 0 ? t('common.addresses.add-address-button') : t('common.addresses.edit-address-button'),
    });
  };

  const AddAddressButton = () => {
    return (
      <Button onClick={() => openAddressModal({ isBilling: addressesState.isBilling })} className={'button--dotted'}>
        <Trans>session-summary.addresses.add</Trans>
      </Button>
    );
  };

  return (
    <Fragment>
      <ModalAddress
        modalTitle={addressesState.modalTitle}
        submitLabel={addressesState.submitLabel}
        isModalDisplayed={addressesState.isAddressModalDisplayed}
        onSubmit={addressesState.onSubmit}
        address={addressesState.address}
        hasFieldDefaultShippingAddress={isLoggedIn && !addressesState.isBilling}
        closeModal={handleCloseModalAddress}
        countries={countries}
        civilities={civilities}
      />
      {isLoggedIn && (
        <SelectAddressModal
          addressesList={addressesList}
          selectedAddress={selectedAddress}
          isBilling={addressesState.isBilling}
          isModalDisplayed={addressesState.isSelectAddressModalDisplayed}
          closeModal={handleCloseModal}
          modalTitle={t('session-summary.addresses.select-modal.title')}
          footer={addAddressIsEnabled ? <div className="modal__footer-reverse">{AddAddressButton()}</div> : null}
          openAddressModal={openAddressModal}
          onSelect={(address) => {
            let { id, civility, country, ...data } = address.toJS();

            return saveAddress({
              id,
              isBilling: addressesState.isBilling,
              skipSaveAddressBook: true,
            })({ ...data, civilityId: civility.id, country: country.iso2 });
          }}
        />
      )}
    </Fragment>
  );
};

export default AddressSelector;
