import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@ets-global/b2c-website-ui';

const SelectAddressModalItem = ({ address, isBilling, displayName = true, onSelect, onEdit }) => {
  const { t } = useTranslation();
  const name = address.get('name');
  const username =
    (address.get('civility') ? address.get('civility').get('name') : '') +
    ' ' +
    address.get('firstname') +
    ' ' +
    address.get('lastname');
  const streetLine1 = address.get('streetLine1');
  const streetLine2 = address.get('streetLine2');
  const postalCodeAndCity = address.get('postalCode') + ' ' + address.get('city');

  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit({ address, isBilling });
  };

  const render = (
    <Fragment>
      <div className="select-address__details select-address__details--no-click">
        {name && displayName && <p className="select-address__name">{name}</p>}
        {username && <p>{username}</p>}
        {streetLine1 && <p>{streetLine1}</p>}
        {streetLine2 && <p>{streetLine2}</p>}
        {postalCodeAndCity && <p>{postalCodeAndCity}</p>}
      </div>
      {onEdit && (
        <button
          onClick={handleEdit}
          className="select-address__actions-button"
          data-modal-open="edit-address"
          aria-label={t('common.addresses.edit')}
        >
          <Icon name={'edit'} />
        </button>
      )}
    </Fragment>
  );

  return onSelect ? (
    <div role="presentation" onClick={() => onSelect(address)} className={'select-address__item'}>
      {render}
    </div>
  ) : (
    <div className={'select-address__item select-address__item--no-click'}>{render}</div>
  );
};

export default SelectAddressModalItem;
