import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button, Link } from '@ets-global/b2c-website-ui';

import { paths } from '../../../paths';
import classNames from 'classnames';

const ErrorDisplay = ({ errorTitle, errors, hasSession = true, link }) => (
  <div className={classNames('checkout-summary-layout')}>
    {link && (
      <div className="checkout-summary-layout__navigation">
        <Link to={link} className="link--reversed" icon={'arrow'}>
          <Trans>session-registration.candidates.back</Trans>
        </Link>
      </div>
    )}
    <div className="checkout-summary-layout__inner">
      <main className="checkout-summary-layout__main checkout-summary-layout__main--centered">
        <h3>{errorTitle}</h3>
        {errors && errors.map((error, key) => <h3 key={key}>{error}</h3>)}
        {hasSession && (
          <Button to={paths.SESSION_SEARCH} data-cypress="buying-process.find-another-session">
            <Trans>session-registration.find-another-session</Trans>
          </Button>
        )}
      </main>
    </div>
  </div>
);

ErrorDisplay.propType = {
  errorTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  errors: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
};

export default ErrorDisplay;
