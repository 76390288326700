import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { string } from 'prop-types';

import AccountHeader from '../../Account/Header';
import AccountMenu from '../../Account/Menu';
import { Title } from '../../Element';

import './styles.scss';

const AccountLayout = ({ title, children }) => (
  <Fragment>
    <Helmet title={title} />
    <main className={'main'}>
      <AccountHeader />
      <div className={'container container--full container--blue'}>
        <div className={'account-layout'}>
          <Title lvl={3} className={'account-layout__title'}>
            {title}
          </Title>
          <AccountMenu />
          <div className={'account-layout__main'}>{children}</div>
        </div>
      </div>
    </main>
  </Fragment>
);

AccountLayout.propTypes = {
  title: string,
};

export default AccountLayout;
