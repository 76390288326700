import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import UploadCandidatePictureDropzone from './Dropzone';
import UploadCandidatePictureConfirmation from './Confirmation';
import UploadCandidatePictureSuccess from './Success';
import { useNotification } from '../../hooks';

const UploadCandidatePicture = ({ onConfirm, hasError, errors = null, isUploaded = false, withActions = false }) => {
  const [candidatePicture, setCandidatePicture] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const { newNotification } = useNotification();
  const { t } = useTranslation();

  const onDrop = (file) => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      setImagePreviewUrl(reader.result);
      setCandidatePicture(file);
    };

    reader.readAsDataURL(file);
  };

  const onConfirmUploadCandidatePicture = (croppedImageUrl) => {
    onConfirm(croppedImageUrl);
  };

  const onCancelUploadCandidatePicture = () => {
    setImagePreviewUrl(null);
    setCandidatePicture(null);
  };

  if (hasError) {
    newNotification({
      content: errors && errors[0] ? t(errors[0].reason, errors[0].parameters) : t('common.error-server-message'),
      type: 'error',
    });
  }

  return (
    <Fragment>
      {!isUploaded && (
        <Fragment>
          {!candidatePicture && <UploadCandidatePictureDropzone onDrop={onDrop} />}
          {imagePreviewUrl && (
            <UploadCandidatePictureConfirmation
              image={imagePreviewUrl}
              onCancelUploadCandidatePicture={onCancelUploadCandidatePicture}
              onConfirmUploadCandidatePicture={onConfirmUploadCandidatePicture}
            />
          )}
        </Fragment>
      )}
      {isUploaded && <UploadCandidatePictureSuccess withActions={withActions} />}
    </Fragment>
  );
};

UploadCandidatePicture.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      reason: PropTypes.string,
      parameters: PropTypes.string,
    }),
  ),
  isUploaded: PropTypes.bool,
  withActions: PropTypes.bool,
};

export default UploadCandidatePicture;
