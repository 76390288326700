import React from 'react';

import AddressForm from '../../Account/Address/AddressForm';
import BaseModal from '../Modal/BaseModal';

const ModalAddress = ({
  closeModal,
  isModalDisplayed,
  onSubmit = () => {},
  modalTitle,
  submitLabel,
  address,
  hasFieldDefaultShippingAddress = true,
  isDefault,
  countries,
  civilities,
}) => {
  let initialValues = {
    firstname: address && address.get('firstname') ? address.get('firstname') : '',
    lastname: address && address.get('lastname') ? address.get('lastname') : '',
    email: address && address.get('email') ? address.get('email') : '',
    phoneNumber: address && address.get('phoneNumber') ? address.get('phoneNumber') : '',
    addressName: address && address.get('name') ? address.get('name') : '',
    streetLine1: address && address.get('streetLine1') ? address.get('streetLine1') : '',
    streetLine2: address && address.get('streetLine2') ? address.get('streetLine2') : '',
    postalCode: address && address.get('postalCode') ? address.get('postalCode') : '',
    city: address && address.get('city') ? address.get('city') : '',
    state: address && address.get('state') ? address.get('state') : '',
    country: address && address.get('country') ? address.get('country').get('iso2') : '',
    civilityId: address && address.get('civility') ? address.get('civility').get('id') : '',
    socialSecurityNumber: address && address.get('socialSecurityNumber') ? address.get('socialSecurityNumber') : '',
  };

  if (hasFieldDefaultShippingAddress) {
    initialValues.isDefaultShippingAddress =
      isDefault ||
      (address && address.get('isDefaultShippingAddress') ? address.get('isDefaultShippingAddress') : false);
  }

  return (
    <BaseModal onClose={closeModal} isOpen={isModalDisplayed} title={modalTitle}>
      <AddressForm
        hasFieldDefaultShippingAddress={hasFieldDefaultShippingAddress}
        initialValues={initialValues}
        countries={countries}
        civilities={civilities}
        onSubmit={onSubmit}
        onCancel={closeModal}
        submitLabel={submitLabel}
      />
    </BaseModal>
  );
};

export default ModalAddress;
