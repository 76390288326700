const SSN_COUNTRY_ISO2 = {
  ITALY: 'it',
  SAN_MARINO: 'sm',
  VATICAN: 'va',
};

export const isSocialSecurityNumberRequired = (siteCountryIso2, addressCountryIso2): boolean => {
  return (
    Object.values(SSN_COUNTRY_ISO2).includes(siteCountryIso2) &&
    addressCountryIso2.toLowerCase() === SSN_COUNTRY_ISO2.ITALY
  );
};
