export const downloadFromUrl = async (url: string, filename: string): Promise<void> => {
  const response = await fetch(url);
  const blob = await response.blob();

  downloadFromContent(blob, filename);
};

export const downloadFromContent = async (content: Blob, filename: string): Promise<void> => {
  const link = document.createElement('a');

  link.href = URL.createObjectURL(content);

  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
