import React from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { Link } from '@ets-global/b2c-website-ui';

import SessionsRegistrationSideBarContainer from '../../../../containers/SessionsRegistrationSideBar';

const CheckoutSummaryLayout = ({
  session,
  productsAndServices,
  className,
  children,
  prevLinkProps,
  purchase = null,
  loading,
}) => (
  <div className={classNames('checkout-summary-layout', className)}>
    <div className="checkout-summary-layout__navigation">
      {!loading && (
        <Link
          {...prevLinkProps}
          className="link--reversed"
          icon={'arrow'}
          data-cypress={'buying-process.back-to-session-list'}
        >
          <Trans>session-registration.candidates.back</Trans>
        </Link>
      )}
    </div>
    <div className="checkout-summary-layout__inner">
      <aside className="checkout-summary-layout__sidebar">
        <SessionsRegistrationSideBarContainer
          loading={session.size === 0}
          session={session}
          purchase={purchase}
          productsAndServices={productsAndServices}
        />
      </aside>
      <main className="checkout-summary-layout__main">{children}</main>
    </div>
  </div>
);

CheckoutSummaryLayout.propTypes = {
  session: PropTypes.instanceOf(Map).isRequired,
  purchase: PropTypes.shape({
    purchaseLines: PropTypes.arrayOf(
      PropTypes.shape({
        isDiscount: PropTypes.bool,
        isVoucher: PropTypes.bool,
        unitPrice: PropTypes.number,
        quantity: PropTypes.number,
        currency: PropTypes.string,
        articleName: PropTypes.string,
        articleFamily: PropTypes.string,
        price: PropTypes.shape({
          value: PropTypes.number,
          currency: PropTypes.string,
          tax: PropTypes.number,
          valueTaxFree: PropTypes.number,
        }),
      }),
    ),
  }),
  className: PropTypes.string,
  prevLinkProps: PropTypes.object,
};

export default CheckoutSummaryLayout;
