import React from 'react';
import { func } from 'prop-types';
import { List, Map } from 'immutable';

import Workflow from '../Base/Workflow';
import HowUploadCandidatePictureBase from './Base';

const HowUploadCandidatePicture = ({ onTutorialIsTerminate }) => {
  const itemsStep1 = new List([
    new Map({
      descriptionTranslationKey: 'how-upload-candidate-picture.step-1.description-picture-1',
      image: 'example01-01',
    }),
    new Map({
      descriptionTranslationKey: 'how-upload-candidate-picture.step-1.description-picture-2',
      image: 'example01-02',
    }),
  ]);
  const itemsStep2 = new List([
    new Map({
      descriptionTranslationKey: 'how-upload-candidate-picture.step-2.description-picture-1',
      image: 'example02-01',
    }),
    new Map({
      descriptionTranslationKey: 'how-upload-candidate-picture.step-2.description-picture-2',
      image: 'example02-02',
    }),
    new Map({
      descriptionTranslationKey: 'how-upload-candidate-picture.step-2.description-picture-3',
      image: 'example02-03',
    }),
    new Map({
      descriptionTranslationKey: 'how-upload-candidate-picture.step-2.description-picture-4',
      image: 'example02-04',
    }),
  ]);
  const itemsStep3 = new List([
    new Map({
      descriptionTranslationKey: 'how-upload-candidate-picture.step-3.description-picture-1',
      image: 'example03-01',
    }),
    new Map({
      descriptionTranslationKey: 'how-upload-candidate-picture.step-3.description-picture-2',
      image: 'example03-02',
    }),
    new Map({
      descriptionTranslationKey: 'how-upload-candidate-picture.step-3.description-picture-3',
      image: 'example03-03',
    }),
  ]);
  const itemsStep4 = new List([
    new Map({
      descriptionTranslationKey: 'how-upload-candidate-picture.step-4.description-picture-1',
      image: 'example04-01',
    }),
    new Map({
      descriptionTranslationKey: 'how-upload-candidate-picture.step-4.description-picture-2',
      image: 'example04-02',
    }),
    new Map({
      descriptionTranslationKey: 'how-upload-candidate-picture.step-4.description-picture-3',
      image: 'example04-03',
    }),
  ]);
  const itemsStep5 = new List([
    new Map({
      descriptionTranslationKey: 'how-upload-candidate-picture.step-5.description-picture-1',
      image: 'example05-01',
    }),
    new Map({
      descriptionTranslationKey: 'how-upload-candidate-picture.step-5.description-picture-2',
      image: 'example05-02',
    }),
    new Map({
      descriptionTranslationKey: 'how-upload-candidate-picture.step-5.description-picture-3',
      image: 'example05-03',
    }),
  ]);

  return (
    <Workflow
      steps={
        new List([
          <HowUploadCandidatePictureBase
            titleTranslationKey={'how-upload-candidate-picture.step-1.title'}
            items={itemsStep1}
            key={1}
          />,
          <HowUploadCandidatePictureBase
            titleTranslationKey={'how-upload-candidate-picture.step-2.title'}
            items={itemsStep2}
            key={2}
          />,
          <HowUploadCandidatePictureBase
            titleTranslationKey={'how-upload-candidate-picture.step-3.title'}
            items={itemsStep3}
            key={3}
          />,
          <HowUploadCandidatePictureBase
            titleTranslationKey={'how-upload-candidate-picture.step-4.title'}
            items={itemsStep4}
            key={4}
          />,
          <HowUploadCandidatePictureBase
            titleTranslationKey={'how-upload-candidate-picture.step-5.title'}
            items={itemsStep5}
            key={5}
          />,
        ])
      }
      isTerminate={onTutorialIsTerminate}
    />
  );
};

HowUploadCandidatePicture.propTypes = {
  onTutorialIsTerminate: func.isRequired,
};

export default HowUploadCandidatePicture;
