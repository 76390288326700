import React, { useRef, useContext } from 'react';
import snakeCase from 'lodash/snakeCase';
import classNames from 'classnames';

import { Loader, Anchor } from '@ets-global/b2c-website-ui';

import { DOCUMENT_TYPE } from '../../../constants';
import { paths } from '../../../paths';
import { ALTERNATE_LANGUAGES } from '../../../graphql/queries';
import DropDown from '../../DropDown';
import useQuery from '../../../hooks/useQuery';
import useRouter from '../../../hooks/useRouter';
import { AppContext } from '../../../store/App';

import './styles.scss';

const LocaleSetter = ({ className }) => {
  const {
    currentRoute: { path, params, search },
    lang,
  } = useRouter();
  const {
    country: { locales },
  } = useContext(AppContext);
  const selectLanguageRef = useRef(null);
  const locale = locales.find((locale) => locale.lang === lang);

  let loading = false;
  const queryVariables = {
    lang,
    params: [],
  };
  let alternateLanguages = [];

  switch (path) {
    case paths.BLOG_ARTICLE:
      if (params.slug) {
        queryVariables.params.push({
          name: 'slug',
          value: params.slug,
          documentType: snakeCase(params.editorialContentType).toUpperCase(),
        });
      }
      break;
    case paths.BASIC_CONTENT:
      if (params.slug) {
        queryVariables.params.push({
          name: 'slug',
          value: params.slug,
          documentType: DOCUMENT_TYPE.BASIC_CONTENT,
        });
      }
      break;
    case paths.LANDING_PAGE:
      if (params.slug) {
        queryVariables.params.push({
          name: 'slug',
          value: params.slug,
          documentType: DOCUMENT_TYPE.LANDING_PAGE,
        });
      }
      break;
    case paths.HELP_CENTER:
      if (params.slug) {
        queryVariables.params.push({
          name: 'slug',
          value: params.slug,
          documentType: DOCUMENT_TYPE.FAQ_CATEGORY,
        });
      }
      if (params.question) {
        queryVariables.params.push({
          name: 'question',
          value: params.question,
          documentType: DOCUMENT_TYPE.FAQ_CONTENT,
        });
      }
      break;
    case paths.LEGAL_INFORMATION:
      if (params.slug) {
        queryVariables.params.push({
          name: 'slug',
          value: params.slug,
          documentType: DOCUMENT_TYPE.LEGAL_INFORMATION,
        });
      }
      break;
    case paths.PROFILE:
      if (params.slug) {
        queryVariables.params.push({
          name: 'slug',
          value: params.slug,
          documentType: DOCUMENT_TYPE.PROFILE,
        });
      }
      break;
    case paths.PROGRAMME:
      if (params.slug) {
        queryVariables.params.push({
          name: 'slug',
          value: params.slug,
          documentType: DOCUMENT_TYPE.PROGRAMME,
        });
      }
      break;
    case paths.TEST_TYPE_FAMILY:
      if (params.slug) {
        queryVariables.params.push({
          name: 'slug',
          value: params.slug,
          documentType: DOCUMENT_TYPE.TEST_TYPE_FAMILY,
        });
      }
      break;
    case paths.PREP_TOOL:
      if (params.slug) {
        queryVariables.params.push({
          name: 'slug',
          value: params.slug,
          documentType: DOCUMENT_TYPE.PREPARATION_TOOL_VERSION,
        });
      }
      break;
  }

  const queryResult = useQuery(ALTERNATE_LANGUAGES, {
    variables: queryVariables,
    skip: Object.keys(queryVariables.params).length === 0,
  });

  if (Object.keys(queryVariables.params).length > 0) {
    loading = queryResult.loading;
    alternateLanguages = queryResult.data.alternateLanguages;
  }

  const getOptions = () =>
    locales.map((currentLocale) => {
      let to = path;
      let currentParams = { ...params, locale: currentLocale.lang };

      if (currentLocale.lang !== lang) {
        let urlParams = {};

        if (Object.keys(queryVariables.params).length > 0) {
          const alternateLanguage = alternateLanguages.find(
            (alternateLanguage) => alternateLanguage.lang === currentLocale.lang,
          );

          if (alternateLanguage) {
            for (const param of alternateLanguage.params) {
              urlParams[param.name] = param.value;
            }
          }

          if (Object.keys(urlParams).length > 0) {
            currentParams = { ...currentParams, ...urlParams };
          } else {
            to = paths.NOT_FOUND;
          }
        }
      }

      return { name: currentLocale.name, to, params: currentParams };
    });

  return (
    <DropDown
      className={classNames('select-language', className)}
      buttonClassName={'select-language__trigger'}
      buttonLabel={locale.name}
      contentClassName={'select-language__list'}
      selectRef={selectLanguageRef}
    >
      {loading ? (
        <Loader />
      ) : (
        getOptions().map(({ name, to, params }, key) => (
          <Anchor
            to={to}
            params={params}
            search={search}
            className={'select-language__list-item'}
            key={key}
            onClick={() => selectLanguageRef.current.close()}
          >
            {name}
          </Anchor>
        ))
      )}
    </DropDown>
  );
};

export default React.memo(LocaleSetter, () => true);
