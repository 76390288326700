import React from 'react';
import PropTypes from 'prop-types';

import { replaceExponentSymbol } from '../../helpers/Formatter/StringFormatter';
import As from '../Element/As';

import './styles.scss';

const InjectHtml = ({ html, component: C = 'span', hasExponentSymbol = true, ...props }) => {
  if (hasExponentSymbol) {
    html = replaceExponentSymbol(html);
  }

  return <As component={C} dangerouslySetInnerHTML={{ __html: html }} {...props} />;
};

InjectHtml.propTypes = {
  component: PropTypes.elementType,
  html: PropTypes.string,
  hasExponentSymbol: PropTypes.bool,
};

export default InjectHtml;
