import './styles.scss';

import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { SmallText, TinyText } from '../../components/Base/Text';
import { SmallTitle } from '../../components/Base/Title';
import PrepToolsCarouselSkeleton from '../../components/PrepToolsCarousel/PrepToolsCarouselSkeleton';
import SkeletonItem from '../../components/SkeletonItem';
import { ARTICLE_FAMILIES } from '../../constants';
import MarketingBannerContainer, { marketingBannerPages } from '../../containers/MarketingBanner';
import PrepToolsList from '../../containers/PrepToolsInCart/PrepToolsList';
import { slugifyTestTypeFamilyName } from '../../helpers/buyingProcess';

const PrepTools = ({
  loading = true,
  preparationToolVersions = new List(),
  purchase = new Map(),
  filters = new Map(),
  purchaseRefetch = () => {},
  displayOnlinePrepTool = true,
  displayBookPrepTool = true,
}) => {
  const [itemsDisplay, setItemsDisplay] = useState(3);

  const isPrepToolAvailable = (preparationTool) => {
    if (!purchase || !purchase.get('voucher') || !purchase.get('voucher').get('isCpf')) {
      return true;
    }

    const purchaseLine = purchase
      .get('purchaseLines')
      .toArray()
      .filter(
        (purchaseLine) =>
          purchaseLine.get('articleStock').get('id') === parseInt(preparationTool.get('articleStockId'), 10)
      )[0];

    if (!purchaseLine) {
      return false;
    }

    return purchase.get('voucher').get('prepToolIds').includes(purchaseLine.get('articleStock').get('id'));
  };

  preparationToolVersions = preparationToolVersions.filter((preparationTool) => isPrepToolAvailable(preparationTool));

  const hasCpfVoucher = purchase && purchase.get('voucher') && purchase.get('voucher').get('isCpf');

  if (filters.get('testTypeFamilyName') && filters.get('testTypeFamilyName') !== 'all') {
    preparationToolVersions = preparationToolVersions.filter((prepTool) =>
      [
        prepTool.get('relatedFamily').get('testTypeFamilyName'),
        slugifyTestTypeFamilyName(prepTool.get('relatedFamily').get('testTypeFamilyName')),
      ].includes(filters.get('testTypeFamilyName'))
    );
  }
  const onlinePreparationTools = preparationToolVersions.filter(
    (preparationTool) => preparationTool.getIn(['relatedFamily', 'type']) === 'Online preparation tool'
  );
  const bookPreparationTools = preparationToolVersions.filter(
    (preparationTool) => preparationTool.getIn(['relatedFamily', 'type']) === 'Book'
  );

  const haveOnlinePrepTool = onlinePreparationTools.size > 0 && filters.get(ARTICLE_FAMILIES.ONLINE);
  const haveBookPrepTool = bookPreparationTools.size > 0 && filters.get(ARTICLE_FAMILIES.BOOK);

  const noProductsDisplayed = !haveOnlinePrepTool && !haveBookPrepTool;

  useEffect(() => {
    const handleResize = () => {
      let itemsToDisplay = 3;
      if (window.innerWidth < 740) {
        itemsToDisplay = 1;
      } else if (window.innerWidth < 980) {
        itemsToDisplay = 2;
      }
      setItemsDisplay(itemsToDisplay);
    };
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  });

  return (
    <Fragment>
      {loading && (
        <section className="prep-tools-carousel">
          <SkeletonItem className="cross-selling__block-title" style={{ height: '22px', width: '45%' }} />
          <SkeletonItem className="cross-selling__block-subtitle" style={{ height: '20px', width: '70%' }} />
          <div>
            <PrepToolsCarouselSkeleton nbItems={3} />
          </div>
        </section>
      )}
      {!loading && (
        <Fragment>
          {noProductsDisplayed && (
            <>
              <SmallText>
                <Trans>
                  {hasCpfVoucher ? 'prep-tool.voucher-cpf.prep-tool-not-included' : 'prep-tool.no-product-to-display'}
                </Trans>
              </SmallText>
              <MarketingBannerContainer page={marketingBannerPages.purchaseFunnel} />
            </>
          )}
          {displayOnlinePrepTool && haveOnlinePrepTool && (
            <>
              <section className="prep-tools-carousel">
                <SmallTitle lvl={2}>
                  <Trans>prep-tool.online-tools.title</Trans>
                </SmallTitle>
                <TinyText>
                  <Trans>prep-tool.online-tools.subtitle</Trans>
                </TinyText>
                <PrepToolsList
                  prepTools={onlinePreparationTools}
                  registrationId={purchase.get('registrationId') || null}
                  purchase={purchase.size > 0 ? purchase.toJS() : null}
                  itemsDisplay={itemsDisplay}
                  purchaseRefetch={purchaseRefetch}
                />
              </section>
              <MarketingBannerContainer page={marketingBannerPages.purchaseFunnel} />
            </>
          )}

          {displayBookPrepTool && haveBookPrepTool && (
            <>
              <section className="prep-tools-carousel">
                <SmallTitle lvl={2}>
                  <Trans>prep-tool.books.title</Trans>
                </SmallTitle>
                <TinyText>
                  <Trans>prep-tool.books.subtitle</Trans>
                </TinyText>
                <PrepToolsList
                  prepTools={bookPreparationTools}
                  registrationId={purchase.get('registrationId') || null}
                  purchase={purchase.size > 0 ? purchase.toJS() : null}
                  itemsDisplay={itemsDisplay}
                  purchaseRefetch={purchaseRefetch}
                />
              </section>
              {!haveOnlinePrepTool && <MarketingBannerContainer page={marketingBannerPages.purchaseFunnel} />}
            </>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

PrepTools.propTypes = {
  loading: PropTypes.bool.isRequired,
  preparationToolVersions: PropTypes.instanceOf(List).isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  purchase: PropTypes.instanceOf(Map).isRequired,
  purchaseRefetch: PropTypes.func,
  displayOnlinePrepTool: PropTypes.bool,
  displayBookPrepTool: PropTypes.bool,
};

export default PrepTools;
