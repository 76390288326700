import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List } from 'immutable';
import { useTranslation } from 'react-i18next';

import { Link } from '@ets-global/b2c-website-ui';

import { PrismicRichText } from '../Base';
import InjectHtml from '../InjectHtml';
import { hexToRGB } from '../../helpers/color';
import { useDataLayer } from '../../hooks';

import './styles.scss';

const TestBlock = ({ title, titleTranslationKey, description, richText, docs, color, className, children }) => {
  const { t } = useTranslation();
  const { pushEventPdfDownloaded } = useDataLayer();

  const renderDocs = () =>
    docs.map(({ label, to }, key) => (
      <Link key={key} icon="download" to={to} className={'test-block__doc'} onClick={pushEventPdfDownloaded}>
        {label}
      </Link>
    ));

  return (
    <div
      className={classNames('test-block', className)}
      style={{ backgroundColor: color ? `rgb(${hexToRGB(color).join()}, 0.12)` : '#f3f9ff' }}
    >
      <InjectHtml
        component="h3"
        className="test-block__title"
        style={{ color: color ? color : '#1e7cc8' }}
        html={titleTranslationKey ? t(titleTranslationKey) : title}
      />
      {description && <PrismicRichText className="test-block__description" richText={description.toJS()} />}
      <PrismicRichText className="test-block__info" richText={richText.toJS()} />
      {docs && <div className="test-block__info">{renderDocs()}</div>}
      {children}
    </div>
  );
};

TestBlock.propTypes = {
  title: PropTypes.node,
  titleTranslationKey: PropTypes.string,
  description: PropTypes.instanceOf(List),
  richText: PropTypes.instanceOf(List),
  docs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  button: PropTypes.objectOf(PropTypes.string),
  color: PropTypes.string,
  className: PropTypes.string,
};

export default TestBlock;
