import React from 'react';
import { instanceOf } from 'prop-types';
import { Map } from 'immutable';
import classNames from 'classnames';

import { Anchor } from '@ets-global/b2c-website-ui';

import { List } from '../Element';

const Item = ({ topic }) => {
  const items = topic.get('links').map((link, key) => {
    const { label, italic, ...linkProps } = link.toJS();

    return (
      <Anchor
        key={key}
        className={classNames('footer__topic-list-item-link', {
          'footer__topic-list-item-link--italic': italic,
        })}
        {...linkProps}
      >
        {link.get('label')}
      </Anchor>
    );
  });

  return (
    <div className={'footer__topic'}>
      <p className={'footer__title'}>{topic.get('title')}</p>
      <List items={items} listClassNames={'footer__topic-list'} itemClassNames={'footer__topic-list-item'} />
    </div>
  );
};

Item.propTypes = {
  topic: instanceOf(Map).isRequired,
};

export default Item;
