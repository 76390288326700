import React from 'react';
import { string, shape, number, bool } from 'prop-types';

import { Link } from '@ets-global/b2c-website-ui';

import ImgLazyLoad from '../ImgLazyLoad';

const GoogleStaticMap = ({ address, coordinates, size, zoom = 15, maptype = 'roadmap', className, link = false }) => {
  const MARKER_ICON = require('../../assets/images/marker.png');

  const encodedAddress = address ? encodeURIComponent(address) : encodeURIComponent(coordinates);
  const params =
    `center=${encodedAddress}&` +
    `zoom=${zoom}&` +
    `size=${size.width}x${size.height}&` +
    `maptype=${maptype}&` +
    `markers=${encodeURIComponent(`anchor:center|icon:${MARKER_ICON}|${address ? address : coordinates}`)}`;

  const img = <ImgLazyLoad className={className} url={`/static-map?${params}`} alt={address} />;
  if (link) {
    return (
      <Link to={'https://www.google.com/maps/search/?api=1&query=' + encodedAddress} target={'_blank'}>
        {img}
      </Link>
    );
  }

  return img;
};

GoogleStaticMap.propTypes = {
  address: string,
  coordinates: string,
  size: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  zoom: number,
  maptype: string,
  className: string,
  link: bool,
};

export default GoogleStaticMap;
