import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { fromJS } from 'immutable';
import truncate from 'lodash/truncate';

import { useRouter } from '../../hooks';
import { AppContext } from '../../store/App';

const HeaderMeta = ({
  meta: { title, description, socialMediaTitle, socialMediaDescription, image, video, canonical },
  transParam,
  page,
  type = 'website',
}) => {
  const { t } = useTranslation();

  const { lang, countryIso2 } = useRouter();
  const {
    country: { name: countryName },
  } = useContext(AppContext);
  const { generateUrl, currentRoute } = useRouter();

  const shareUrl = generateUrl(currentRoute.path, currentRoute.params, currentRoute.search);

  // search engine title and description from Prismic OR default translation
  title = title ? title : t(`meta.title.${page}`, { country: countryName, ...transParam });
  description = description ? description : t(`meta.description.${page}`, { country: countryName, ...transParam });

  // social share title and description from Prismic OR previous title and description as default
  let metaTitle = socialMediaTitle ? socialMediaTitle : title;
  metaTitle = truncate(metaTitle, {
    length: 70,
    separator: ' ...',
  });

  let metaDescription = socialMediaDescription ? socialMediaDescription : description;
  metaDescription = truncate(metaDescription, {
    length: 200,
    separator: ' ...',
  });

  const metaLocale = lang + '_' + countryIso2.toUpperCase();

  if (!image) {
    image = {
      url: require('../../assets/images/social_shared_image.jpg'),
      dimensions: {
        width: 350,
        height: 350,
      },
    };
  }

  return (
    <Helmet
      title={title}
      meta={[
        { name: 'description', content: description },
        { property: 'fb:app_id', content: '2289258807979515' },
        { property: 'og:title', content: metaTitle },
        { property: 'og:description', content: metaDescription },
        { property: 'og:type', content: type },
        { property: 'og:url', content: shareUrl },
        { property: 'og:locale', content: metaLocale },
        { property: 'og:image', content: image.url },
        { property: 'og:image:width', content: image.dimensions.width },
        { property: 'og:image:height', content: image.dimensions.height },
        { property: 'og:video', content: video && video.url ? video.url : '' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@ETSGlobal' },
        { name: 'twitter:creator', content: '@ETSGlobal' },
      ]}
    >
      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
};

HeaderMeta.propTypes = {
  meta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    socialMediaTitle: PropTypes.string,
    socialMediaDescription: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      dimensions: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }),
    }),
    video: PropTypes.shape({
      url: PropTypes.string,
    }),
    canonical: PropTypes.string,
  }),
  transParam: PropTypes.object,
  page: PropTypes.string,
  type: PropTypes.string,
};

HeaderMeta.defaultProps = {
  meta: {},
};

export default React.memo(
  HeaderMeta,
  (
    { meta: prevMeta, transParam: prevTransParam, type: prevType },
    { meta: nextMeta, transParam: nextTransParam, type: nextType },
  ) => {
    return (
      fromJS(prevMeta).equals(fromJS(nextMeta)) &&
      prevTransParam &&
      fromJS(prevTransParam).equals(fromJS(nextTransParam)) &&
      prevType === nextType
    );
  },
);
