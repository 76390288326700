import { Map } from 'immutable';

export const getAddressValidationSchema = (Yup) => {
  return Yup.object().shape({
    addressName: Yup.string().nullable().max(80),
    streetLine1: Yup.string().required().max(35),
    streetLine2: Yup.string().max(35).nullable(),
    postalCode: Yup.string().required().max(10),
    city: Yup.string().required().max(25),
    state: Yup.string().max(20).nullable(),
    country: Yup.string().required(),
    civilityId: Yup.string().required(),
    firstname: Yup.string().required().max(255),
    lastname: Yup.string().required().max(255),
    email: Yup.string().email().required().max(255),
    phoneNumber: Yup.string().phoneNumber().required().max(16),
  });
};

export const validate = (address, Yup) => {
  if (!address) {
    return new Promise((resolve) => resolve(false));
  }

  if (address instanceof Map) {
    address = address.toJS();
  }

  if (!address.civilityId && address.civility) {
    address.civilityId = address.civility.name;
  }

  if (!address.addressName) {
    address.addressName = address.name;
  }

  return getAddressValidationSchema(Yup).validate(address);
};
