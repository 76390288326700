import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TestTypeFamilyTitle from '../../../Title/TestTypeFamilyTitle';

import './styles.scss';
import { TestTypeFormat, TestTypeProgram } from '../../../../constants';

const SessionTitle = ({ title, color, component = 'p', testTypeProgram, testTypeFormat, className }) => {
  return (
    <div className={'account-session-title'}>
      <TestTypeFamilyTitle
        className={classNames('account-session-title__title', className)}
        color={color}
        component={component}
        title={title}
        testTypeProgram={testTypeProgram}
        testTypeFormat={testTypeFormat}
      />
    </div>
  );
};

SessionTitle._propTypes = {
  title: PropTypes.string,
  elementType: PropTypes.string,
  color: PropTypes.string,
  testTypeProgram: PropTypes.oneOf([TestTypeProgram.PP, TestTypeProgram.IP]),
  testTypeFormat: PropTypes.oneOf([TestTypeFormat.PBT, TestTypeFormat.CBT]),
  className: PropTypes.string,
};

export default SessionTitle;
