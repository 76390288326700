import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

import { SelectOrNumberField, Loader } from '@ets-global/b2c-website-ui';

import { NumberByLocale } from '../../Element';
import ConfirmModal from '../../Base/Modal/ConfirmModal';

import './styles.scss';

const OrderDetailRow = ({
  articleId,
  name,
  unitPrice,
  quantity,
  amount,
  currency,
  listClassNames,
  isDiscount = false,
  isIncluded = false,
  isEditable = false,
  onPurchaseUpdate = undefined,
  isInVoucher = false,
}) => {
  const [loading, setLoading] = useState(false);

  if (isIncluded) {
    return (
      <div className={listClassNames}>
        <div className="order-table-row__col">{name}</div>
        <div className="order-table-row__col" />
        <div className="order-table-row__col">
          <span className="order-table-row__col__quantity-label">
            <Trans>account.billing.order.table.quantity</Trans>{' '}
          </span>
          {quantity}
        </div>
        <div className="order-table-row__col">
          <Trans>session-registration.products-and-services.certificateInclude.free</Trans>
        </div>
      </div>
    );
  }

  if (isDiscount) {
    return (
      <div className={listClassNames}>
        <div className="order-table-row__col">{name}</div>
        <div className="order-table-row__col" />
        <div className="order-table-row__col" />
        <div className="order-table-row__col">
          <NumberByLocale currency={currency} value={unitPrice} />
        </div>
      </div>
    );
  }

  if (name) {
    const [isModalOpen, setModalOpen] = useState(false);

    return (
      <div className={listClassNames}>
        <div className="order-table-row__col">{name}</div>
        <div className="order-table-row__col">
          <NumberByLocale currency={currency} value={unitPrice} />
        </div>
        <div className={classNames('order-table-row__col', { 'order-table-row__col--select': isEditable })}>
          <span className="order-table-row__col__quantity-label">
            <Trans>account.billing.order.table.quantity</Trans>{' '}
          </span>
          {isEditable ? (
            loading ? (
              <Loader />
            ) : (
              <Fragment>
                <SelectOrNumberField
                  id="quantity"
                  name="quantity"
                  className={'order-table-row__quantity-input'}
                  value={quantity}
                  setFieldValue={(value) => {
                    if (parseInt(value) === 0) {
                      return setModalOpen(true);
                    }
                    setLoading(true);
                    onPurchaseUpdate({ quantity: value, id: articleId }).then(() => {
                      setLoading(false);
                    });
                  }}
                />
                <ConfirmModal
                  isOpen={isModalOpen}
                  title={<Trans>account.billing.order.remove-line.title</Trans>}
                  onClose={() => setModalOpen(false)}
                  buttons={[
                    {
                      label: <Trans>common.cancel</Trans>,
                      className: 'button--transparent-light',
                      onClick: () => {
                        setModalOpen(false);
                        setLoading(false);
                      },
                    },
                    {
                      label: <Trans>account.billing.order.remove-line.delete</Trans>,
                      onClick: () => {
                        setModalOpen(false);
                        setLoading(true);
                        onPurchaseUpdate({ quantity: 0, id: articleId }).then(() => {
                          setLoading(false);
                        });
                      },
                    },
                  ]}
                >
                  <Trans>
                    {isInVoucher
                      ? 'account.billing.order.remove-line-with-voucher.question'
                      : 'account.billing.order.remove-line.question'}
                  </Trans>
                </ConfirmModal>
              </Fragment>
            )
          ) : (
            quantity
          )}
        </div>
        <div className="order-table-row__col">
          <NumberByLocale currency={currency} value={amount} />
        </div>
      </div>
    );
  }

  return (
    <div className="order-table-row order-table-row--head">
      <div className="order-table-row__col">
        <Trans>account.billing.order.table.name</Trans>
      </div>
      <div className="order-table-row__col">
        <Trans>account.billing.order.table.unit_price</Trans>
      </div>
      <div className="order-table-row__col">
        <Trans>account.billing.order.table.quantity</Trans>
      </div>
      <div className="order-table-row__col">
        <Trans>account.billing.order.table.total_price</Trans>
      </div>
    </div>
  );
};

OrderDetailRow.propTypes = {
  articleId: PropTypes.string,
  name: PropTypes.node,
  unitPrice: PropTypes.number,
  quantity: PropTypes.number,
  currency: PropTypes.string,
  amount: PropTypes.number,
  listClassNames: PropTypes.string,
  onPurchaseUpdate: PropTypes.func,
  isDiscount: PropTypes.bool,
  isIncluded: PropTypes.bool,
  isEditable: PropTypes.bool,
  isInVoucher: PropTypes.bool,
};

export default OrderDetailRow;
