import React from 'react';
import PropTypes from 'prop-types';

import { Mobile, Tablet, Desktop } from '../Responsive';
import ImgLazyLoad from '../ImgLazyLoad';

const Picture = ({ images = [], className, skeleton, forcePreloadImage }) => {
  if (images.length === 0) {
    return '';
  }

  if (forcePreloadImage) {
    let image = images[0];
    const pictures = images.map((img) => {
      if (img.source === 'MOBILE') {
        image = img;

        return null;
      }

      return {
        srcSet: img.url,
        media: img.source === 'TABLET' ? '(min-width: 740px) and (max-width: 979px)' : '(min-width: 980px)',
      };
    });

    return (
      <picture className={className ? `${className}-wrapper` : null}>
        {pictures.map((picture, key) => picture && <source key={key} {...picture} />)}
        <img src={image.url} alt={image.alt} className={className} />
      </picture>
    );
  }

  return images.map((image, key) => {
    let MediaQuery = Mobile;
    if (image.source === 'DESKTOP') {
      MediaQuery = Desktop;
    } else if (image.source === 'TABLET') {
      MediaQuery = Tablet;
    }

    return (
      <MediaQuery key={key}>
        <ImgLazyLoad alt={image.alt} url={image.url} className={className} skeleton={skeleton} />
      </MediaQuery>
    );
  });
};

Picture.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
  skeleton: PropTypes.any,
  forcePreloadImage: PropTypes.bool,
};

export default Picture;
