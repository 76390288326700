import React from 'react';
import { Trans } from 'react-i18next';

import { Title } from '../../Element';

import './styles.scss';

const AccountHeader = () => {
  return (
    <div className={'account-header account-header--no-mobile'}>
      <div className={'account-header__row'}>
        <Title lvl={2} className={'account-header__title'}>
          <Trans>account.header.title</Trans>
        </Title>
      </div>
    </div>
  );
};

export default AccountHeader;
