import { ARTICLE_FAMILIES } from '../constants';

const group = (purchaseLines) => {
  let sessionArticle = null;
  let sessionOptions = [];
  let otherArticles = [];
  let fees = [];
  let discounts = [];

  purchaseLines.forEach((purchaseLine) => {
    if (purchaseLine.get('isDiscount') || purchaseLine.get('isVoucher')) {
      discounts.push(purchaseLine);

      return;
    }

    switch (purchaseLine.get('articleFamily')) {
      case ARTICLE_FAMILIES.TEST_TYPE:
        sessionArticle = purchaseLine;
        break;
      case ARTICLE_FAMILIES.PS:
        sessionOptions.push(purchaseLine);
        break;
      case ARTICLE_FAMILIES.TRANSACTION_FEES:
      case ARTICLE_FAMILIES.SHIPPING_FEES:
        fees.push(purchaseLine);
        break;
      default:
        otherArticles.push(purchaseLine);
    }
  });

  return {
    sessionArticle,
    sessionOptions,
    otherArticles,
    fees,
    discounts,
  };
};

export default group;
