import React from 'react';
import uniqueId from 'lodash/uniqueId';
import { useMutation } from '@apollo/client';

import { Loader } from '@ets-global/b2c-website-ui';

import { ADDRESS_FORM, ADDRESSES_LIST, PURCHASE_ADDRESS } from '../../graphql/queries';
import { CREATE_ADDRESS, UPDATE_ADDRESS } from '../../graphql/mutations';
import AddressSelector from '../../components/AddressSelector';
import errorsHandler from '../../helpers/errorsHandler';
import { useTranslation } from 'react-i18next';
import { useQuery, useNotification } from '../../hooks';
import { useCookies } from 'react-cookie';

const AddressSelectorContainer = ({
  isModalDisplayed,
  addressesList,
  selectedAddress,
  isLoggedIn,
  isBilling,
  onCloseModal,
  onSaveAddress,
  addAddressIsEnabled = true,
}) => {
  const { t } = useTranslation();
  const { newNotification } = useNotification();
  const {
    data: { countries, civilities },
    loading,
  } = useQuery(ADDRESS_FORM);
  const [cookies] = useCookies();

  const [createAddress] = useMutation(CREATE_ADDRESS);
  const [updateAddress] = useMutation(UPDATE_ADDRESS);

  const getAddress = (address, countries, civilities, id) => {
    return {
      id: id ? id : uniqueId(),
      isDefaultShippingAddress: !address.isDefaultShippingAddress ? false : address.isDefaultShippingAddress,
      name: address.name || '',
      company: '',
      streetLine1: address.streetLine1,
      streetLine2: address.streetLine2,
      postalCode: address.postalCode,
      city: address.city,
      state: address.state,
      civility: civilities.filter(({ id }) => id === address.civility)[0],
      country: countries.filter(({ emoIso2 }) => emoIso2 === address.country)[0],
      firstname: address.firstname,
      lastname: address.lastname,
      email: address.email,
      phoneNumber: address.phoneNumber,
      socialSecurityNumber: address.socialSecurityNumber,
      __typename: 'Address',
    };
  };

  const handleSaveAddress = ({ address, id, isBilling, skipSaveAddressBook }) => {
    const promises = onSaveAddress({
      address,
      isBilling,
      optimisticAddress: getAddress(address, countries, civilities),
    });
    if (isLoggedIn && !skipSaveAddressBook) {
      const refetchQueries = [{ query: ADDRESSES_LIST }];
      if (isBilling && cookies.registrationId) {
        refetchQueries.push({
          query: PURCHASE_ADDRESS,
          variables: {
            registrationId: cookies.registrationId,
          },
        });
      }
      if (id) {
        promises.push(
          updateAddress({
            variables: { id, input: address },
            refetchQueries,
            optimisticResponse: {
              __typename: 'Mutation',
              opsShippingAddress: {
                address: true,
                __typename: 'OpsShippingAddress',
              },
            },
            update: (proxy) => {
              const { addresses, ...data } = proxy.readQuery({
                query: ADDRESSES_LIST,
              });
              proxy.writeQuery({
                query: ADDRESSES_LIST,
                data: {
                  ...data,
                  addresses: addresses.map((_address) =>
                    _address.id === id ? getAddress(address, countries, civilities, id) : _address,
                  ),
                },
              });
            },
          }),
        );
      } else {
        promises.push(
          createAddress({
            variables: { input: address },
            refetchQueries,
            optimisticResponse: {
              __typename: 'Mutation',
              opsShippingAddress: {
                address: true,
                __typename: 'OpsShippingAddress',
              },
            },
            update: (proxy) => {
              const data = proxy.readQuery({
                query: ADDRESSES_LIST,
              });
              const newAddress = getAddress(address, countries, civilities);
              data.addresses.push(newAddress);
              proxy.writeQuery({
                query: ADDRESSES_LIST,
                data,
              });
            },
          }),
        );
      }
    }

    newNotification({
      content: t('account.addresses.add-address-success-message'),
      type: 'success',
    });

    return Promise.all(promises).catch((error) => errorsHandler({ error, newNotification, t }));
  };

  return loading ? (
    <Loader />
  ) : (
    <AddressSelector
      isModalDisplayed={isModalDisplayed}
      addressesList={addressesList}
      selectedAddress={selectedAddress}
      isLoggedIn={isLoggedIn}
      isBilling={isBilling}
      countries={countries}
      civilities={civilities}
      onCloseModal={onCloseModal}
      onSaveAddress={handleSaveAddress}
      addAddressIsEnabled={addAddressIsEnabled}
    />
  );
};

export default AddressSelectorContainer;
