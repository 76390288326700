import React from 'react';
import { Trans } from 'react-i18next';
import { Map } from 'immutable';
import { instanceOf } from 'prop-types';

import { Button } from '@ets-global/b2c-website-ui';

import { GoogleStaticMap } from '../Google';
import { paths } from '../../paths';

const MeetUs = ({ meetUsData }) => {
  return (
    <div className={'footer__row footer__row--second'}>
      <p className={'footer__title'}>{meetUsData.get('title')}</p>
      <GoogleStaticMap
        address={meetUsData.get('address')}
        size={{ width: 234, height: 152 }}
        className="footer__googlemapstatic"
      />
      <p className={'footer__text'}>
        <span className={'footer__text--bigger'}>{meetUsData.get('addressTitle')}</span>
        <br />
        {meetUsData.get('address')}
        <br />
        <Trans values={{ phone: meetUsData.get('phone') }}>footer.meet_us.phone</Trans>
      </p>
      {meetUsData.has('contactLinkIsDisplayed') && meetUsData.get('contactLinkIsDisplayed') && (
        <Button to={paths.LANDING_CONTACT}>
          <Trans>footer.meet_us.contact_link</Trans>
        </Button>
      )}
    </div>
  );
};

MeetUs.propTypes = {
  meetUsData: instanceOf(Map).isRequired,
};

export default MeetUs;
