import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import { useTranslation } from 'react-i18next';

import Anchor from '@ets-global/b2c-website-ui/Anchor';

import SkeletonItem from '../../SkeletonItem';
import { useDateToString } from '../../Element/DateToString';
import InjectHtml from '../../InjectHtml';
import As from '../../Element/As';
import ImgLazyLoad from '../../ImgLazyLoad';
import { paths } from '../../../paths';

import './style.scss';

const EditorialContentItem = ({ slug, title, image, date, category, displayHn = false }) => {
  const { t } = useTranslation();
  const DateToString = useDateToString();

  return (
    <div className={'editorial-content-item'}>
      {image && (
        <Anchor
          to={paths.BLOG_ARTICLE}
          params={{ editorialContentType: kebabCase(category), slug }}
          className={'editorial-content-item__picture'}
        >
          <ImgLazyLoad
            className={'editorial-content-item__picture-inner'}
            alt={image.alt}
            url={image.url}
            skeleton={
              <SkeletonItem
                className={'editorial-content-item__picture-inner skeleton-item--blue'}
                style={{ width: '100%' }}
              />
            }
          />
        </Anchor>
      )}
      {category && (
        <InjectHtml
          component={displayHn ? 'h4' : 'p'}
          className="editorial-content-item__category"
          html={t(`blog.editorial-content.category.${category.toLowerCase()}`)}
        />
      )}
      {title && (
        <As component={displayHn ? 'h3' : 'p'} className="editorial-content-item__title">
          <Anchor to={paths.BLOG_ARTICLE} params={{ editorialContentType: kebabCase(category), slug }}>
            {title}
          </Anchor>
        </As>
      )}
      {date && (
        <time className={'editorial-content-item__date'}>
          <DateToString date={date} format="DD MMM" />
        </time>
      )}
    </div>
  );
};

EditorialContentItem.propTypes = {
  slug: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  title: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string,
  }),
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  displayHn: PropTypes.bool,
};

export default EditorialContentItem;
