import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';

import { Button } from '@ets-global/b2c-website-ui';

import UploadCandidatePictureBase from './Base';

const MIN_WIDTH = 384;
const MIN_HEIGHT = 480;

const UploadCandidatePictureConfirmation = ({
  image: initialImage,
  onCancelUploadCandidatePicture,
  onConfirmUploadCandidatePicture,
}) => {
  const [imageState, setImageState] = useState({
    image: initialImage,
    crop: {
      x: 0,
      y: 0,
    },
    zoom: 1,
    maxZoom: 3,
    aspect: 0.8,
    croppedImageUrl: null,
    error: null,
  });

  const { t } = useTranslation();

  const onCropChange = (crop) => setImageState({ ...imageState, crop });

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    if (croppedAreaPixels.width < MIN_WIDTH || croppedAreaPixels.height < MIN_HEIGHT) {
      const width = croppedAreaPixels.width * imageState.zoom;
      const height = croppedAreaPixels.height * imageState.zoom;
      const maxZoomWidth = width / MIN_WIDTH;
      const maxZoomHeight = height / MIN_HEIGHT;
      const maxZoom = maxZoomWidth < maxZoomHeight ? maxZoomWidth : maxZoomHeight;

      setImageState({
        ...imageState,
        maxZoom: maxZoom > 1 ? maxZoom : 1,
        zoom: maxZoom > 1 ? maxZoom : 1,
        error: { message: 'upload-candidate-picture.confirmation.error-size' },
      });
    } else if (initialImage && croppedArea.width && croppedArea.height) {
      const croppedImageUrl = await getCroppedImg(croppedAreaPixels);
      setImageState({ ...imageState, error: null, croppedImageUrl });
    }
  };

  const onZoomChange = (zoom) => setImageState({ ...imageState, zoom });

  const getCroppedImg = (pixelCrop) => {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
    const imageRef = new Image();
    imageRef.src = imageState.image;

    ctx.drawImage(
      imageRef,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    return canvas.toDataURL('image/jpeg', 0.75);
  };

  const onHandleConfirmUploadPicture = () => {
    onConfirmUploadCandidatePicture(imageState.croppedImageUrl);
  };

  const actions = (
    <div className={'upload-candidate-picture__footer-reverse'}>
      <Button onClick={onCancelUploadCandidatePicture} className={'button--transparent-light'}>
        <Trans>upload-candidate-picture.confirmation.action.cancel</Trans>
      </Button>
      <Button onClick={onHandleConfirmUploadPicture} disabled={!!imageState.error}>
        <Trans>upload-candidate-picture.confirmation.action.confirm</Trans>
      </Button>
    </div>
  );

  return (
    <UploadCandidatePictureBase title={t('upload-candidate-picture.confirmation.title')} actions={actions}>
      <div className={'photo-upload__howto photo-upload__howto--double'}>
        <div className={'photo-upload__howto-item photo-upload__howto-item--solo'}>
          <div className={'photo-upload__crop-wrapper'}>
            <Cropper
              image={imageState.image}
              crop={imageState.crop}
              zoom={imageState.zoom}
              maxZoom={imageState.maxZoom}
              aspect={imageState.aspect}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              onZoomChange={onZoomChange}
            />
          </div>
          {imageState.error && (
            <p
              className={
                'photo-upload__howto-item-paragraph photo-upload__howto-item-paragraph--bigger photo-upload__howto-item-paragraph-error'
              }
            >
              <Trans>{imageState.error.message}</Trans>
            </p>
          )}
          <p className={'photo-upload__howto-item-paragraph photo-upload__howto-item-paragraph--bigger'}>
            <Trans>upload-candidate-picture.confirmation.message</Trans>
          </p>
        </div>
      </div>
    </UploadCandidatePictureBase>
  );
};

UploadCandidatePictureConfirmation.propTypes = {
  image: PropTypes.string.isRequired,
  onCancelUploadCandidatePicture: PropTypes.func.isRequired,
  onConfirmUploadCandidatePicture: PropTypes.func.isRequired,
};

export default UploadCandidatePictureConfirmation;
