import React from 'react';
import classNames from 'classnames';

import { Anchor } from '@ets-global/b2c-website-ui';

import { paths } from '../../paths';
import { useRouter } from '../../hooks';
import SocialNetwork from './SocialNetwork';
import SelectLanguage from './SelectLanguage';

import './styles.scss';

const HeaderLight = ({ needHelp }) => {
  const { currentRoute } = useRouter();
  const isTestLandingPage = [paths.LANDING_PAGE_TOEIC_IN_AUTONOMY, paths.LANDING_PAGE_REMOTE_PROCTORED_TEST].includes(
    currentRoute.path,
  );

  return (
    <header className={classNames('header__top', 'header__top--light', { 'header__top--fixed': isTestLandingPage })}>
      <div
        className={classNames('header__top-language', { 'header__top-language--test-landing-page': isTestLandingPage })}
      >
        <SelectLanguage country={false} />
      </div>
      {isTestLandingPage && (
        <img
          className="header__top-logo-test-landing-page"
          src={require('../../assets/images/ETSToeic_logo.svg')}
          alt={'ETS TOEIC'}
        />
      )}
      <div className={'header__top-links'}>
        {!isTestLandingPage && <SocialNetwork />}
        {isTestLandingPage && !needHelp?.tawkId && needHelp?.link && needHelp?.title && needHelp?.icon?.url && (
          <Anchor className="header__top-need-help" to={needHelp?.link?.to}>
            <img src={needHelp.icon.url} alt={needHelp.icon?.alt} style={{ height: '16px' }} />
            <span className="header__top-need-help-title">{needHelp?.title}</span>
          </Anchor>
        )}
      </div>
    </header>
  );
};

export default HeaderLight;
