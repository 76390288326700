import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

const Workflow = ({ initialStep = 0, steps, isTerminate }) => {
  const stepsNumber = steps.size ? steps.size : 0;
  const [currentStep, setCurrentStep] = useState(initialStep);

  const onNextStep = () => {
    setCurrentStep(currentStep + 1);
    isTerminate(currentStep + 1 === stepsNumber);
  };

  const onPrevStep = () => setCurrentStep(currentStep - 1);

  const onResetStep = () => setCurrentStep(initialStep);

  const onFinishWorkflow = () => {
    setCurrentStep(stepsNumber);
    isTerminate(true);
  };

  const renderSteps = () => {
    return React.Children.map(steps, (step) => {
      return React.cloneElement(step, {
        onPrevStep: onPrevStep,
        onNextStep: onNextStep,
        onResetStep: onResetStep,
        onFinishWorkflow: onFinishWorkflow,
        stateWorkflow: { currentStep, stepsNumber },
      });
    });
  };

  return renderSteps().map((step, key) => <Fragment key={key}>{key === currentStep && step}</Fragment>);
};

Workflow.propTypes = {
  initialStep: PropTypes.number,
  steps: PropTypes.instanceOf(List).isRequired,
  isTerminate: PropTypes.func,
};

export default Workflow;
