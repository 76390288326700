import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import { string } from 'prop-types';

const UploadCandidatePictureUploadLoading = ({ pictureName }) => {
  return (
    <Fragment>
      <p>
        <Trans>upload-candidate-picture.upload.loading.message</Trans>
      </p>
      <p className={'photo-upload__dropzone-format'}>{pictureName}</p>
      <div className={'photo-upload__dropzone-upload'}>
        <img src={require('../../../assets/images/spinner.png')} alt={'spinner'} />
      </div>
    </Fragment>
  );
};

UploadCandidatePictureUploadLoading.propTypes = {
  pictureName: string.isRequired,
};

export default UploadCandidatePictureUploadLoading;
