import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Link } from '@ets-global/b2c-website-ui';

import { PrismicRichText } from '../Base';

import './styles.scss';

const Advice = ({ id, title, answer, className, toggleQuestion, isOpen, linkProps }) => {
  const ref = useRef();

  useEffect(() => {
    if (isOpen) {
      ref.current.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
        inline: 'start',
      });
    }
  }, []);

  return (
    <div id={id} ref={ref} className={classNames('advice__question', { 'advice__question--open': isOpen }, className)}>
      <div className="advice__question-toggler">
        <Link
          {...linkProps}
          className="advice__question-button"
          onClick={(e) => {
            e.preventDefault();
            toggleQuestion();
          }}
        >
          <h2 className="advice__question-title">{title}</h2>
        </Link>
      </div>
      <div className="advice__question-answer">
        <PrismicRichText className="advice__question-answer-content" richText={answer} />
      </div>
    </div>
  );
};

Advice.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string.isRequired,
  answer: PropTypes.array.isRequired,
  className: PropTypes.string,
  toggleQuestion: PropTypes.func,
  isOpen: PropTypes.bool,
  linkProps: PropTypes.object,
};

export default Advice;
