import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, FastField as BaseFormikFastField } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import useYup from '../../../hooks/useYup';

import { Fields, Button } from '@ets-global/b2c-website-ui';

import { getTranslatedUserCivility } from '../../../helpers/Formatter/UserFormatter';
import { getAddressValidationSchema } from '../../../helpers/Validators/addressValidator';

import './styles.scss';
import { Tooltip } from '../../Base';
import { useCookies } from 'react-cookie';
import { isSocialSecurityNumberRequired } from '../../../helpers/address.ts';

const AddressForm = ({
  hasFieldDefaultShippingAddress = true,
  submitLabel,
  countries,
  civilities,
  initialValues = {},
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const { t } = useTranslation();
  const { Yup } = useYup();
  const [cookies] = useCookies();
  const [country, setCountry] = useState(initialValues.country);

  let validationSchema = getAddressValidationSchema(Yup);

  if (isSocialSecurityNumberRequired(cookies.country, country)) {
    validationSchema = validationSchema.concat(Yup.object().shape({
      socialSecurityNumber: Yup.string().required().max(16)
        .matches(
          /^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST][0-9]{2}([a-zA-Z][0-9]{3})[a-zA-Z]$/,
          t('account.addresses.form.error.social_security_number.invalid_pattern'),
        ),
    }));
  } else {
    initialValues.socialSecurityNumber = undefined;
  }

  if (hasFieldDefaultShippingAddress) {
    validationSchema = validationSchema.concat(Yup.object().shape({
      isDefaultShippingAddress: Yup.boolean(),
    }));
  }

  civilities = civilities.map(({ id }) => ({ label: getTranslatedUserCivility(id, t), value: id }));
  civilities.unshift({ value: '', label: '' });

  countries = countries
    .map(({ emoIso2: value, name: label }) => ({ label, value }))
    .sort((a, b) => a.label.localeCompare(b.label));
  countries.unshift({ value: '', label: '' });

  return (
    <Formik
      initialValues={{ civilityId: '', country: '', ...initialValues }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form className="form" data-cypress="address-form">
          {hasFieldDefaultShippingAddress && (
            <Fields.FormikFastField
              id="isDefaultShippingAddress"
              name="isDefaultShippingAddress"
              component={Fields.CheckboxField}
              label={t('account.addresses.form.label.isMainAddress')}
              className={'address--is-default'}
            />
          )}
          <div className="form__row">
            <Fields.FormikFastField
              id="addressName"
              name="addressName"
              component={Fields.TextField}
              label={t('account.addresses.form.label.name')}
              autoComplete={'address-name'}
            />
            <Fragment>
              <Fields.FormikFastField
                id="civilityId"
                name="civilityId"
                component={Fields.SelectField}
                label={t('account.addresses.form.label.civility')}
                autoComplete={'shipping honorific-prefix'}
                required={true}
                options={civilities}
              />

              <Fields.FormikFastField
                id="firstname"
                name="firstname"
                component={Fields.TextField}
                label={t('account.addresses.form.label.firstname')}
                autoComplete={'shipping given-name'}
                required={true}
              />
              <Fields.FormikFastField
                id="lastname"
                name="lastname"
                component={Fields.TextField}
                label={t('account.addresses.form.label.lastname')}
                autoComplete={'shipping family-name'}
                required={true}
              />
              <Fields.FormikFastField
                id="email"
                name="email"
                component={Fields.TextField}
                label={t('account.addresses.form.label.email')}
                type={'email'}
                autoComplete={'shipping email'}
                required={true}
              />
            </Fragment>
            <Fields.FormikFastField
              id="streetLine1"
              name="streetLine1"
              component={Fields.TextField}
              label={t('account.addresses.form.label.streetLine1')}
              autoComplete={'shipping address-line1'}
              required={true}
            />
            <Fields.FormikFastField
              id="streetLine2"
              name="streetLine2"
              component={Fields.TextField}
              label={t('account.addresses.form.label.streetLine2')}
              autoComplete={'shipping address-line2'}
            />
            <Fields.FormikFastField
              id="postalCode"
              name="postalCode"
              component={Fields.TextField}
              label={t('account.addresses.form.label.postCode')}
              autoComplete={'shipping postal-code'}
              required={true}
            />
            <Fields.FormikFastField
              id="city"
              name="city"
              component={Fields.TextField}
              label={t('account.addresses.form.label.city')}
              autoComplete={'shipping address-level2'}
              required={true}
            />
            <Fields.FormikFastField
              id="state"
              name="state"
              component={Fields.TextField}
              label={t('account.addresses.form.label.state')}
              autoComplete={'shipping address-level1'}
            />
            <Fields.FormikFastField
              id="country"
              name="country"
              component={Fields.SelectField}
              label={t('account.addresses.form.label.country')}
              autoComplete={'shipping country'}
              options={countries}
              onChange={(event) => {
                setCountry(event.target.value);
                if (!isSocialSecurityNumberRequired(cookies.country, event.target.value)) {
                  setFieldValue('socialSecurityNumber', undefined);
                }
              }}
              required={true}
            />
            <BaseFormikFastField
              component={Fields.PhoneNumberField}
              id="phoneNumber"
              name="phoneNumber"
              className={'field--dotted'}
              labels={[t('account.addresses.form.label.phoneCode'), t('account.addresses.form.label.phoneNumber')]}
              required={true}
            />
            {isSocialSecurityNumberRequired(cookies.country, country) && (
              <div className={'address__tooltip-field-wrapper'}>
                <Fields.FormikFastField
                  id="socialSecurityNumber"
                  name="socialSecurityNumber"
                  component={Fields.TextField}
                  label={t('account.addresses.form.label.social_security_number')}
                  required={false}
                  helperText={t('account.addresses.form.help.social_security_number')}
                />

                <Tooltip position={'top-right'} icon={'info'} width={'300px'}>
                  <Trans>account.addresses.form.help.social_security_number</Trans>
                </Tooltip>
              </div>
            )}
          </div>
          <div className={'modal__footer'}>
            <div className={'modal__footer-reverse'}>
              <Button type="button" className="button--transparent-light" onClick={onCancel}>
                <Trans>account.addresses.form.label.cancel</Trans>
              </Button>
              <Button className="button--full-width" disabled={isSubmitting} type="submit">
                <Trans>{submitLabel}</Trans>
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddressForm.propTypes = {
  hasFieldDefaultShippingAddress: PropTypes.bool,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      emoIso2: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  civilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  initialValues: PropTypes.shape({
    isDefaultShippingAddress: PropTypes.bool,
    addressName: PropTypes.string,
    civilityId: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    streetLine1: PropTypes.string,
    streetLine2: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    phoneNumber: PropTypes.string,
    socialSecurityNumber: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default AddressForm;
