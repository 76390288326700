import { useEffect, useState } from 'react';

const FormikObserver = ({ onChange, values }) => {
  const [previousValues, setPreviousValues] = useState(values);

  useEffect(() => {
    if (previousValues !== values) {
      onChange(values);
      setPreviousValues(values);
    }
  }, [values]);

  return null;
};

FormikObserver.defaultProps = {
  onChange: () => null,
};

export default FormikObserver;
